import React, { Component } from 'react'
import { ExcelRenderer } from 'react-excel-renderer';
import axios from 'axios'
import { Modal, Spinner } from 'react-bootstrap'
import Swal from 'sweetalert2'
import FileUploadIcon from '@mui/icons-material/FileUpload';
import { StringFormatter } from './StringFormatter';

export default class ImportFile extends Component {
    constructor(props) {
        super(props)
        this.state = {
            isModalLoading: false
        }
        this.ImportFile = this.ImportFile.bind(this)
    }

    ImportFile(e) {
        var newPromise = new Promise((resolve, reject) => {
            const ExcelDateToJSDate = (date) => {
                let converted_date = new Date(Math.round((date - 25569) * 864e5));
                // console.log(converted_date)
                converted_date = String(converted_date).slice(4, 15)
                date = converted_date.split(" ")
                let day = date[1];
                let month = date[0];
                month = "JanFebMarAprMayJunJulAugSepOctNovDec".indexOf(month) / 3 + 1
                if (month.toString().length <= 1)
                    month = '0' + month
                let year = date[2];
                return String(year + '-' + month + '-' + day)
            }

            let fileObj = e.target.files[0];

            //just pass the fileObj as parameter
            ExcelRenderer(fileObj, (err, resp) => {
                if (err) {
                    console.log(err);
                    reject(err)
                }
                else {
                    console.log(resp.rows)
                    if (resp.rows.length > 0) {
                        let arr = []
                        let headers = resp.rows[0]
                        for (let i = 1; i < resp.rows.length; i++) {
                            let newTrObj = {}
                            let tr = resp.rows[i]
                            if(tr.length > 0){
                                for (let j = 0; j < headers.length; j++) {
                                    let td = tr[j]
                                    let key = headers[j]
                                    // console.log(isDate(td))
                                    if (td == undefined) {
                                        td = ''
                                    }
                                    if (key.includes('(yyyy-MM-dd)')) {
                                        key = key.split('(yyyy-MM-dd)')[0]
                                        console.log(key)
                                        if (td != "") {
                                            td = ExcelDateToJSDate(td)
                                        }
                                    }
                                    newTrObj[key] = td
                                }
                                arr.push(newTrObj)
                            }
                        }
                        this.setState({ isModalLoading: true })
                        resolve(arr)
                    }
                }
            });
        })

        newPromise.then(resp => {
            console.log(resp)
            // // const arr = resp.slice(0, 10);
            let json = JSON.stringify(resp)
            // console.log(json)
            var fd = new FormData();
            let arr = [`${json}`]
            let query = StringFormatter(this.props.query,arr)
            console.log(query)
            // let query = `[dbo].[SLCI_Web_Proc_EmployeeMaster_ImportUser_Submit] @UserID='${this.props.userid}', @ClientID='${this.props.clientid}', @Json='${json}'`;
            fd.append("Query", query);
            axios({
                mode: "cors",
                method: "POST",
                headers: { "Content-Type": "multipart/form-data" },
                url: this.props.fetchRsUrl,
                data: fd,
            }).then((resp) => {
                console.log(resp.data)
                Swal.fire({
                    html: resp.data[''][0].msg,
                }).then((res) => {
                    // window.location.reload()

                    if (resp.data[''][0].command == 1) {
                        this.props.reloadTable()
                    }
                });
            }).then(re => this.setState({ isModalLoading: false }))
        }).catch(err => console.error(err))
    }
    render() {
        return (
            <>
                <div>
                    <FileUploadIcon style={{height: "20px"}}/>&nbsp;
                    <input type="file" hidden id="excelfile" onChange={(e) => this.ImportFile(e)} value="" />
                    <label htmlFor="excelfile">{this.props.name}</label>
                </div >
                {/* Loading modal start */}
                <Modal className='ScoreModal' size="sm" centered style={{ backgroundColor: 'rgba(0,0,0,0.6)' }} show={this.state.isModalLoading} onHide={() => this.setState({ isModalLoading: false })} backdrop='static' keyboard={false}>
                    <Modal.Body>
                        <div style={{ display: "flex", justifyContent: "center", alignItems: "center", width: "100%", height: "10vh" }}>
                            <Spinner animation="border" variant="dark" />
                        </div>
                    </Modal.Body>
                </Modal>
                {/* Loading modal end */}
            </>
        )
    }
}
