import React, { useEffect, useState } from 'react'
import ProductDatatable from '../usefulcomps/table/ProductDatatable';
import Hexapi from '../usefulcomps/HexAPI/Hexapi';
import { Modal, Spinner } from 'react-bootstrap';
import { useDispatch } from 'react-redux';


const ApprovalPanel = () => {
    const [isDataFound, setisDataFound] = useState(true)
    const [tabledata, settabledata] = useState([]);
    const [showLoading, setshowLoading] = useState(true);
    const [ColumnNames, setColumnNames] = useState([]);
    const [windowidth, setwindowwidth] = useState(window.innerWidth);
    const dispatch = useDispatch();


    useEffect(() => {
        dispatch({ type: 'SaveRoutes', payload: { route: "productpanel" } });

        OpenProductpanel()
        const handleWindowsize = () => {
            // console.log(window.innerWidth)
            setwindowwidth(window.innerWidth);
        }

        window.addEventListener('resize', handleWindowsize);
        return () => {
            window.removeEventListener('resize', handleWindowsize)
        }
    }, [])

    const OpenProductpanel = () => {
        setshowLoading(true);
        let obj = {
            'query': `[dbo].[Seetech_Web_Proc_ProductMaster_GetData] @UserID='{0}',@Search='{1}',@Page='{2}'`,
            'queryArr': [`6`, `-1`, '1']
        }
        Hexapi(obj).then((res) => {
            setisDataFound(true)
            console.log(res);
            settabledata(res[""])
            setColumnNames(Object.keys(res[""][0]));
            setshowLoading(false);

        });
    }

    const productcallback = (value) => {
        console.log(value);
        settabledata([])
        setisDataFound(false)
        OpenProductpanel();
    }

    const getproductrowdata = () => {

    }

    return (
        <>
            <div style={ { width: '100%', height: '600px' }}>
                {

                    isDataFound ?
                        showLoading ?
                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: '10%', color: 'rgb(235 197 137)', minHeight: '400px' }}>
                                <h3>
                                    <Spinner animation='border' style={{ color: '#f9b101' }} />
                                </h3>
                            </div>
                            :
                            <ProductDatatable
                                data={tabledata}
                                headStyle={{ textTransform: "Capitalize" }}
                                heading={"Product Panel"}
                                isNavbar={true}
                                pagination={{
                                    rowsPerPage: 20,
                                    rowsPerPageOptions: [10, 50, 100, { label: 'All', value: -1 }]
                                }}
                                onRowClick={(e) => getproductrowdata(e)}
                                callback={(e) => productcallback(e)}
                                ColumnNames={ColumnNames}
                            />
                        // <Approval data={tabledata} onRowClick={(e) => getrowdata(e)}  callback={(e) => callback(e)}/>
                        :
                        null
                }
            </div>
        </>
    )
}

export default ApprovalPanel