import React, { useEffect, useState } from 'react'
import Datatable from '../usefulcomps/table/Datatable';
import Hexapi from '../usefulcomps/HexAPI/Hexapi';
import { Modal, Spinner } from 'react-bootstrap';
import { useDispatch } from 'react-redux';


const ApprovalPanel = () => {
    const [isDataFound, setisDataFound] = useState(true)
    const [tabledata, settabledata] = useState([]);
    const [ColumnNames, setColumnNames] = useState([]);
    const [windowidth, setwindowwidth] = useState(window.innerWidth);
    const dispatch = useDispatch();


    useEffect(() => {
        dispatch({ type: 'SaveRoutes', payload: { route: "approval" } });

        OpenAdminpanel()
        const handleWindowsize = () => {
            // console.log(window.innerWidth)
            setwindowwidth(window.innerWidth);
        }

        window.addEventListener('resize', handleWindowsize);
        return () => {
            window.removeEventListener('resize', handleWindowsize)
        }
    }, [])

    const getrowdata = (e) => {
        console.log(e)
    }

    const OpenAdminpanel = () => {
        setisDataFound(false)

        let obj = {
            'query': `[dbo].[Seetech_Web_Proc_UserApproval_GetData]@UserID='{0}'`,
            'queryArr': [`${sessionStorage.getItem('userid')}`]
        }
        Hexapi(obj).then((res) => {
            setisDataFound(true)
            console.log(res[""])

            console.log(Object.keys(res[""][0]))
            setColumnNames(Object.keys(res[""][0]));

            settabledata(res[""])
        });
    }

    const callback = (value) => {
        console.log(value);
        settabledata([])
        setisDataFound(false)
        OpenAdminpanel();
    }

    return (
        <>
            <div style={ { width: '100%', height: '600px' }}>
                {

                    isDataFound ?

                        <Datatable
                            data={tabledata}
                            headStyle={{ textTransform: "Capitalize" }}
                            heading={"User Approval Panel"}
                            isNavbar={true}
                            pagination={{
                                rowsPerPage: 20,
                                rowsPerPageOptions: [10, 50, 100, { label: 'All', value: -1 }]
                            }}
                            onRowClick={(e) => getrowdata(e)}
                            callback={(e) => callback(e)}
                            ColumnNames={ColumnNames}
                        />
                        :
                        // <Approval data={tabledata} onRowClick={(e) => getrowdata(e)} callback={(e) => callback(e)} />
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: '10%', color: 'rgb(235 197 137)', minHeight: '400px' }}>
                            <h3>
                                <Spinner  animation='border' style={{ color: '#f9b101' }} />
                            </h3>
                        </div>
                }
            </div>
        </>
    )
}

export default ApprovalPanel