import axios from 'axios';

const otpnotification = async (templateParams, campaign,firstname,phoneno,opt) => {
    const apiKey = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjY1OTNhYTg5ZGZiOWNjN2FiODcyYmVhNCIsIm5hbWUiOiJTZWV0ZWNoIFBhcnRzIiwiYXBwTmFtZSI6IkFpU2Vuc3kiLCJjbGllbnRJZCI6IjY1OTNhYTg5ZGZiOWNjN2FiODcyYmU5ZiIsImFjdGl2ZVBsYW4iOiJCQVNJQ19NT05USExZIiwiaWF0IjoxNzA0MTc2MjY1fQ.xsH0Pq97JwcuPgrYahyrgGs8NzZGOIX-wydm9XxLNW8";
    const data = {
        apiKey: apiKey,
        campaignName: campaign,
        destination: phoneno,
        userName: firstname,
        templateParams: templateParams,
        buttons: [
            {
                type: "button",
                sub_type: "url",
                index: 0,
                parameters: [
                    {
                        type: "text",
                        text: opt
                    }
                ]
            }
        ],
        carouselCards: [],
        location: {},
        paramsFallbackValue: {
            FirstName: firstname
        }
    };

    try {
        const response = await axios.post('https://backend.aisensy.com/campaign/t1/api/v2', data, {
            headers: { "Content-Type": "application/json" }
        });

        console.log('OTP sent successfully:', response.data);
        return response.data; 

    } catch (error) {
        if (error.response) {
            console.error('Error response data:', error.response.data);
            console.error('Error response status:', error.response.status);
            console.error('Error response headers:', error.response.headers);
        } else if (error.request) {
            console.error('Error request data:', error.request);
        } else {
            console.error('Error message:', error.message);
        }
        console.error('Error config:', error.config);
        throw error; 
    }
}

export default otpnotification;
