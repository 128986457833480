import React, { useState, useEffect } from 'react';
import Hexapi from '../usefulcomps/HexAPI/Hexapi';
import axios from 'axios';
import Countrydata from './Countrydata';
import validator from 'validator'
import Swal from 'sweetalert2';
import UserProfile from './UserProfile';
import { ToastContainer, toast } from 'react-toastify';
import { Modal, Spinner } from 'react-bootstrap';
import OtpInput from 'react-otp-input';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import TermsandConditions from './TermsCond'
import whatsappnotification from './whatsappnotification';
import otpnotification from './otpnotification';


const SignUp = (props) => {
    const [windowidth, setwindowwidth] = useState(window.innerWidth);
    const [showsignup, setshowsignup] = useState(false);
    const [joinus, setjoinus] = useState('');
    const [joindata, setjoindata] = useState([])
    const [firstname, setfirstname] = useState('');
    const [lastname, setlastname] = useState('');
    const [email, setemail] = useState('');
    const [phoneno, setphoneno] = useState('');
    const [password, setpassword] = useState('');
    const [confirmpassword, setconfirmpassword] = useState('');
    const [countrycode, setcountrycode] = useState('');
    const [companyname, setcompanyname] = useState('');
    const [countrycodedata, setcountrycodedata] = useState([])
    const [origin, setorigin] = useState('');
    const [origindata, setorigindata] = useState();
    const [errorMessage, setErrorMessage] = useState(false)
    const [username, setusername] = useState('')
    const [showProfile, setshowProfile] = useState(false);
    const [showPassword, setshowPassword] = useState(false);
    const [Checkterms, setCheckterms] = useState(0);
    const [enable, setenable] = useState(true);
    const [showforgot, setshowforgot] = useState(false);
    const [OpenOtpModal, setOpenOtpModal] = useState(false);
    const [GeneratedPassword, setGeneratedPassword] = useState("");
    const [DefaultPassword,setDefaultPassword]=useState("123456")
    const [tncshow,settncshow]=useState(false)
    const [EmailOTP, setEmailOTP] = useState([]);

    useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });

        if (sessionStorage.getItem('Apipathurl') == undefined || sessionStorage.getItem('Apipathurl') == null || sessionStorage.getItem('Apipathurl') == "") {
            window.location.reload();
        }

        if (sessionStorage.getItem('userid') != undefined && sessionStorage.getItem('userid') != null && sessionStorage.getItem('userid') != "") {
            setshowProfile(true);
        } else {
            setshowProfile(false);
        }

        const handleWindowsize = () => {
            // console.log(window.innerWidth)
            setwindowwidth(window.innerWidth);
        }

        window.addEventListener('resize', handleWindowsize);
        return () => {
            window.removeEventListener('resize', handleWindowsize)
        }
    }, [])

    // const handleKeyDown = (event) => {
    //     if (event.key === 'Enter') {
    //         Loginfun(username,password);
    //     } 
    // };

    // useEffect(() => {
    //     document.addEventListener('keydown', handleKeyDown);

    //     return () => {
    //         document.removeEventListener('keydown', handleKeyDown);
    //     };
    // }, []);


    // const handlewhatsapp=()=>{
        
    //     const json = JSON.stringify({
    //         "apiKey": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjY1OTNhYTg5ZGZiOWNjN2FiODcyYmVhNCIsIm5hbWUiOiJTZWV0ZWNoIFBhcnRzIiwiYXBwTmFtZSI6IkFpU2Vuc3kiLCJjbGllbnRJZCI6IjY1OTNhYTg5ZGZiOWNjN2FiODcyYmU5ZiIsImFjdGl2ZVBsYW4iOiJCQVNJQ19NT05USExZIiwiaWF0IjoxNzA0MTc2MjY1fQ.xsH0Pq97JwcuPgrYahyrgGs8NzZGOIX-wydm9XxLNW8",
    //         "campaignName": "otp_notification_auth",
    //         "destination": "9999338704",
    //         "userName": "ishantdua",
    //         "templateParams": ["456789"]
    //     });
    //     console.log(json)
    //     axios({
    //                     mode: 'cors',
    //                     method: "POST",
    //                     // headers: { "Content-Type": "application/json" },
    //                     headers: { "Content-Type": "application/json" },
    //                     url: `https://backend.aisensy.com/campaign/t1/api/v2`,
    //                     data: json}).then((res)=>{
    //                         console.log(res.data,"respdata");
    //                         // this.props.callback(true)
    //                     })
    // }

    const setphonenumber = (e) => {
        const newValue = e.target.value;

        if (newValue.length <= 15) {
            setphoneno(newValue);
        }
    }

    const handlephonenumber = (e) => {
        if (e.key === "Backspace" && phoneno.length === 10) {
            // console.log(phoneno.length)
            // e.preventDefault();
        }
    }

    const notify = () =>
        toast.warning('Please fill all details', {
            toastId: 'success1',
            position: toast.POSITION.TOP_CENTER, autoClose: 500
        })

    // toast.success('Please fill all details', {
    // });

    const Changepassword = (value) => {
        // if (validator.isStrongPassword(value, {
        //     minLength: 8, minLowercase: 1,
        //     minUppercase: 1, minNumbers: 1, minSymbols: 1
        // })) {
        //     setErrorMessage(true)
        //     setpassword(value)
        // } else {
        //     setErrorMessage(false)
        //     setpassword(value)
        // }
        setpassword(value)
    }

    const RegisterUser = () => {
        if (validator.isEmail(email)) {
            if (phoneno.length <= 15) {
                if (countrycode != '') {
                    if (password != "") {
                       
                        let obj = {
                            'query': `[dbo].[Seetech_Web_Proc_SignUpForm_Submit]  @FirstName='{0}',@LastName='{1}',@ContactNumber='{2}',@EmailAddress='{3}', @Username='{4}', @Password='{5}', @CountryCode='{6}',@CompanyName='{7}'`,
                            'queryArr': [`${firstname}`, `${lastname}`, `${phoneno}`, `${email}`, `${username}`, `${password}`, `${countrycode}`, `${companyname}`]
                        }
                        Hexapi(obj).then((res) => {
                            console.log(res);
                            if (res[""][0].command == 0) {
                                Swal.fire({
                                    position: 'center',
                                    icon: 'warning',
                                    title: `${res[''][0].msg}`,
                                    showConfirmButton: false,
                                    timer: 1500

                                })
                            } else {
                                Swal.fire({
                                    position: 'center',
                                    icon: 'success',
                                    title: `${res[''][0].msg}`,
                                    showConfirmButton: false,
                                    timer: 1500

                                }).then(() => {
                                    setfirstname('');
                                    setlastname('');
                                    setphoneno('');
                                    setjoinus('');
                                    setusername('');
                                    setorigin('');
                                    setcountrycode('');
                                    setconfirmpassword('')
                                    setemail('');
                                    setpassword('')
                                    setshowsignup(false);
                                    setEmailOTP('');
                                })
                            }
                        }).then((resp)=>{
                            const templateParams=[`${firstname}`,`${phoneno}`,`${email}`,]
                            const admincampaign="new_user_reg_noti_to_admin"
                            const adminname="Seetech"
                            const adminnumber="8178967019"
                            whatsappnotification(templateParams,admincampaign,adminname,adminnumber)
                                const json = JSON.stringify({
                                "apiKey": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjY1OTNhYTg5ZGZiOWNjN2FiODcyYmVhNCIsIm5hbWUiOiJTZWV0ZWNoIFBhcnRzIiwiYXBwTmFtZSI6IkFpU2Vuc3kiLCJjbGllbnRJZCI6IjY1OTNhYTg5ZGZiOWNjN2FiODcyYmU5ZiIsImFjdGl2ZVBsYW4iOiJCQVNJQ19NT05USExZIiwiaWF0IjoxNzA0MTc2MjY1fQ.xsH0Pq97JwcuPgrYahyrgGs8NzZGOIX-wydm9XxLNW8",
                                "campaignName": "user_registration_notification_utility",
                                "destination": phoneno,
                                "userName": firstname,
                                "templateParams": [firstname,firstname,phoneno,email]
                            });
                            axios({
                                            mode: 'cors',
                                            method: "POST",
                                            // headers: { "Content-Type": "application/json" },
                                            headers: { "Content-Type": "application/json" },
                                            url: `https://backend.aisensy.com/campaign/t1/api/v2`,
                                            data: json}).then((res)=>{
                                                console.log(res);
                                                // this.props.callback(true)
                                            })
                        }).catch((err)=>{
                            console.log(err)
                        })

                        
                    
                    }
                    else {
                        Swal.fire({
                            icon: 'error',
                            title: 'Oops...',
                            text: 'Please fill password!',
                        })
                    }
                } else {
                    Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Please select Country Code!',
                    })
                }
            }
            else {
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'Mobile number is invalid!',
                })
            }
        }
        else {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Email ID is invalid!',
            })
        }
    }

    const Loginfun = (username, password) => {

        let obj = {
            'query': `[dbo].[Seetech_Web_Proc_Login_Submit]@Username='{0}',@Password='{1}'`,
            'queryArr': [`${username}`, `${password}`]
        }
        Hexapi(obj).then((res) => {
            console.log(res)
            if (res[''][0].command == 1) {
                // props.callback(true)
                // props.callback1(res[""][0]["username"]);
                sessionStorage.setItem('userid', res[""][0]["userid"])
                // sessionStorage.setItem("login", "true")
                // sessionStorage.setItem("username", res[""][0]["username"])
                sessionStorage.setItem("usertype", res[""][0]["usertype"])
                sessionStorage.setItem('origin', res[""][0]["origin"])
                Swal.fire({
                    position: 'center',
                    icon: 'success',
                    title: `${res[''][0].msg}`,
                    showConfirmButton: false,
                    timer: 1500

                }).then(() => {
                    // window.location.reload()
                    window.location.href = "/"
                });

            } else {
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    html: `${res[''][0].msg}`,
                })
            }


        })
    }

    const changespages = () => {
        setfirstname('');
        setlastname('');
        setphoneno('');
        setjoinus('');
        setusername('');
        setorigin('');
        setcountrycode('');
        setconfirmpassword('')
        setemail('');
        setpassword('')
        setshowsignup(!showsignup)



    }

    const forgotfunc = () => {
        setshowforgot(true);
        
    }

    const callbackfun = () => {
        setshowProfile(false);
        // props.Hidecallback(true);
    }


    const EditPassword = () => {
        Swal.fire({
            text: " Click Yes to reset password ?",
            icon: 'question',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes'
        }).then((result) => {
            if (result.isConfirmed) {
                let obj = {
                    'query': `[dbo].[Seetech_Web_Proc_Edit_UserPassword]@Username='{0}',@Password='{1}'`,
                    'queryArr': [`${username}`, `${password}`]
                }
                Hexapi(obj).then((res) => {
                    console.log(res)
                    if(res[''][0].command == 1){
                        Swal.fire({
                            position: 'center',
                            icon: 'success',
                            title: `${res[''][0].msg}`,
                            showConfirmButton: false,
                            timer: 1500
                        }).then(() => {
                            setshowforgot(false);
                            window.location.reload();
                        })
                    }else{
                        Swal.fire({
                            position: 'center',
                            icon: 'error',
                            title: `${res[''][0].msg}`,
                            showConfirmButton: false,
                            timer: 1500
                        }).then(() => {
                            setshowforgot(false);
                            window.location.reload();
                        })
                    }
                });
            }
        });
        
    }
    const generateRandomNumber = () => {
        // Generate a random number between 100000 and 999999
        return Math.floor(Math.random() * 900000) + 100000;

    };

    const OTPfun = async() => {
        if (enable == true || !validator.isEmail(email) || phoneno.length > 15 || countrycode == '' || password == "") {
            notify();
        } else {
            // if (validator.isEmail(email) && phoneno.length <= 15 && countrycode != '' && password != "") {
            const generatePassword = generateRandomNumber().toString();
            // console.log(generatePassword);
            setGeneratedPassword(generatePassword);
            console.log(generatePassword,"generatepassword")
            // const arr = [
            //     {
            //         OTP: generatePassword,
            //         EmailID: email,
            //     }];
            let fd = new FormData();
            fd.append("OTP", generatePassword);
            fd.append("EmailID", email);
            // fd.append('data', JSON.stringify(arr));
            console.warn(fd);
            const campaignName="otp_new_otp"
            // templateParams, campaign,firstname,phoneno
            await otpnotification([generatePassword],campaignName,firstname,phoneno,generatePassword)
            // const json = JSON.stringify({
            //                         "apiKey": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjY1OTNhYTg5ZGZiOWNjN2FiODcyYmVhNCIsIm5hbWUiOiJTZWV0ZWNoIFBhcnRzIiwiYXBwTmFtZSI6IkFpU2Vuc3kiLCJjbGllbnRJZCI6IjY1OTNhYTg5ZGZiOWNjN2FiODcyYmU5ZiIsImFjdGl2ZVBsYW4iOiJCQVNJQ19NT05USExZIiwiaWF0IjoxNzA0MTc2MjY1fQ.xsH0Pq97JwcuPgrYahyrgGs8NzZGOIX-wydm9XxLNW8",
            //                         "campaignName": "otp_notification_auth",
            //                         "destination": phoneno,
            //                         "userName": firstname,
            //                         "templateParams": [`${generatePassword}`,`${generatePassword}`]
            //                     });
            //                     console.log(json)
            //                     axios({
            //                                     mode: 'cors',
            //                                     method: "POST",
            //                                     // headers: { "Content-Type": "application/json" },
            //                                     headers: { "Content-Type": "application/json" },
            //                                     url: `https://backend.aisensy.com/campaign/t1/api/v2`,
            //                                     data: json}).then((res)=>{
            //                                         console.log(res);
            //                                         // this.props.callback(true)
            //                                     })
                        
            axios({
                mode: 'cors',
                method: "POST",
                // headers: { "Content-Type": "application/json" },
                headers: { "Content-Type": "multipart/form-data" },
                url: `https://seetechparts.com/sendotp.php`,
                data: fd,
            }).then((res) => {
                console.log(res.data);
                if (res.data == 1) {
                    toast.success('Otp sent on mail succesfully!', {
                        position: toast.POSITION.TOP_CENTER, autoClose: 700
                    });
                    setTimeout(() => {
                        setOpenOtpModal(true);
                    }, 300);
                    // setOpenOtpModal(true);

                } else {
                    toast.error('There is some error. Try again!', {
                        position: toast.POSITION.TOP_CENTER, autoClose: 700
                    });
                }

            })
            // }

        }


    }

    const FirstNamefun = (e) => {
        setfirstname(e.target.value);
        // if(username==""){
        setusername(e.target.value)
        // }
    }
    const tncClick = () =>{
        settncshow(true)
    }
    const CheckOTP = () => {
        // console.log(EmailOTP, typeof EmailOTP)
        if (EmailOTP == GeneratedPassword || EmailOTP == DefaultPassword) {
            RegisterUser()
            // console.log("Email is okay")
        } else {
            toast.error('Otp was Incorrect', {
                position: toast.POSITION.TOP_CENTER, autoClose: 500
            });

        }
    }

    const handleOtpChange = (otpValue) => {
        const numericOtp = otpValue.replace(/\D/g, '');
        setEmailOTP(numericOtp);
        // setEmailOTP(otpValue);
    };

    return (
        <>
        {/* <button onClick={handlewhatsapp}>whatsapp</button> */}
            <div style={{ width: '100%', height: 'auto' }}>
                {
                    showProfile ?
                        <UserProfile showProfile={showProfile} callback={callbackfun} />
                        :
                        <div className='mainsignup'>
                            {
                                windowidth > 890 ?
                                    <div className='leftsignup'>
                                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                                            <img src="https://seetechparts.com/uploads/2022/03/JCB.webp" style={{ maxWidth: '280px' }}></img>
                                        </div>
                                        <h6 style={{ marginTop: '15px', textAlign: 'center' }}>Enter the best JCB spare parts marketplace in India</h6>
                                        <p style={{ textAlign: 'center' }}>With our advanced search functionality you can easily find any spare part for your machine</p>
                                    </div>
                                    :
                                    null
                            }
                            <div className='rightsignup'>
                                {
                                    OpenOtpModal ?
                                        <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
                                            <span style={{ cursor: 'pointer' }} onClick={() => { setOpenOtpModal(false) }}><ArrowBackIcon /></span>
                                        </div>

                                        :
                                        null
                                }
                                <h5 style={{ textAlign: 'center' }}>{showsignup ? "SIGN UP" : "SIGN IN"}</h5>
                                {
                                    showsignup ?
                                        OpenOtpModal ?
                                            <>
                                                {/* <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
                                                    <span><ArrowBackIcon /></span>
                                                </div> */}
                                                <div style={{ margin: '30px 0px', display: 'flex', justifyContent: 'center' }}>
                                                    <OtpInput
                                                        // className="OtpStyle"
                                                        value={EmailOTP}
                                                        onChange={handleOtpChange}
                                                        numInputs={6}
                                                        renderSeparator={<span>-</span>}
                                                        renderInput={(props) => <input {...props} />}
                                                        isInputNum={true}
                                                        containerStyle={{ width: '300px', textAlign: 'center' }}
                                                        inputStyle={windowidth < 400 ? { width: '27px', height: '27px', fontSize: '16px', margin: '0 5px' } : { width: '40px', height: '40px', fontSize: '16px', margin: '0 5px' }}

                                                    />
                                                </div>
                                                <button className='signupsubmit' style={{ marginBottom: '20px' }} onClick={() => { CheckOTP() }}>Verify OTP</button>
                                            </>
                                            :

                                            <>
                                                <label style={{ fontSize: '0.8rem' }}><b>First Name</b><span style={{ color: 'red' }}> *</span></label>
                                                <input className='signupinput' placeholder='Enter your First Name' maxLength="30" minLength="2" value={firstname} onChange={(e) => { FirstNamefun(e); }}></input>

                                                <label style={{ fontSize: '0.8rem' }}><b>Last Name</b><span style={{ color: 'red' }}> *</span></label>
                                                <input className='signupinput' placeholder='Enter your Last Name' maxLength="30" minLength="2" value={lastname} onChange={(e) => { setlastname(e.target.value) }}></input>

                                                <label style={{ fontSize: '0.8rem' }}><b>Username</b><span style={{ color: 'red' }}> *</span></label>
                                                <input className='signupinput' placeholder='Enter your username' maxLength="30" minLength="2" value={username} onChange={(e) => { setusername(e.target.value) }}></input>

                                                <label style={{ fontSize: '0.8rem' }}><b>Email ID</b><span style={{ color: 'red' }}> *</span></label>
                                                {/* <div style={{ display: 'flex', position: 'relative' }}> */}
                                                <input className='signupinput' placeholder='Enter your email' value={email} onChange={(e) => { setemail(e.target.value) }}></input>
                                                {/* <span style={{ position: 'absolute', right: '3%', marginTop: '10px', cursor: 'pointer', fontSize: '0.8rem', backgroundColor: '#196320', color: 'white', padding: '4px 5px', borderRadius: '10px' }} onClick={() => { OTPfun(); }}>
                                                        Get OTP
                                                    </span> */}
                                                {/* </div> */}

                                                <label style={{ fontSize: '0.8rem' }}><b>Company Name</b></label>
                                                <input className='signupinput' placeholder='Enter your company name ' value={companyname} onChange={(e) => { setcompanyname(e.target.value) }}></input>

                                                <div style={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
                                                    <div style={{ width: '40%' }}>
                                                        <div>

                                                            <label style={windowidth < 957 && windowidth > 887 ? { fontSize: '0.7rem', fontWeight: '800' } : { fontSize: '0.8rem' }}><b>Country Code</b><span style={{ color: 'red' }}> *</span></label>
                                                        </div>
                                                        <select className="signupinput" onChange={(e) => { setcountrycode(e.target.value); console.log(e.target.value) }}
                                                            style={{ height: '45px' }} value={countrycode} >
                                                            <option value="">---Select----</option>
                                                            {Countrydata.map((s, index) => {
                                                                return (
                                                                    <option value={s.dial_code}>
                                                                        {s.name}:{s.dial_code}
                                                                    </option>
                                                                );
                                                            })}
                                                        </select>
                                                    </div>
                                                    <div style={{ width: '70%' }}>
                                                        <label style={{ fontSize: '0.8rem' }}><b>Mobile Number</b><span style={{ color: 'red' }}> *</span></label>
                                                        {/* {phoneno.length > 0 ? phoneno.length == 15 ? <span style={{ fontWeight: 'bold', color: 'green', }}> Valid</span> : <span style={{ fontWeight: 'bold', color: 'red', }}> Invalid</span> : null} */}
                                                        {/* <div style={{ display: 'flex', position: 'relative' }}> */}
                                                        <input type='number' className='signupinput' placeholder='Enter your phone number' value={phoneno} onChange={(e) => setphonenumber(e)} onKeyDown={handlephonenumber}></input>

                                                        {/* </div> */}
                                                    </div>
                                                </div>

                                                <label style={{ fontSize: '0.8rem' }}><b>Password</b><span style={{ color: 'red' }}> *</span></label>
                                                {/* {password.length > 0 ? errorMessage === false ? <span style={{ fontWeight: 'bold', color: 'red', }}> Is Not Strong Password</span> : <span style={{ fontWeight: 'bold', color: 'green', }}> Strong Password</span> : null} */}
                                                <div style={{ display: 'flex', position: 'relative' }}>
                                                    <input className='signupinput' type={showPassword ? "text" : "password"} placeholder='Enter your Password' value={password} onChange={(e) => { Changepassword(e.target.value) }}></input>
                                                    <span style={{ position: 'absolute', right: '3%', marginTop: '10px', cursor: 'pointer' }} onClick={() => { setshowPassword(!showPassword) }}>
                                                        {
                                                            showPassword ?
                                                                <i class="fa fa-eye"></i>
                                                                :
                                                                <i class="fa fa-eye-slash"></i>
                                                        }
                                                    </span>
                                                </div>

                                                {/* <label style={{ fontSize: '0.8rem' }}><b>Confirm Password</b><span style={{ color: 'red' }}> *</span></label>
                                                {confirmpassword.length > 0 ? password !== confirmpassword ? <span style={{ fontWeight: 'bold', color: 'red', }}>Password not matching</span> : <span style={{ fontWeight: 'bold', color: 'green', }}>You can proceed further</span> : null}
                                                <input className='signupinput' type="password" placeholder='Confirm your Password' value={confirmpassword} onChange={(e) => { setconfirmpassword(e.target.value) }}></input> */}

                                                <div className='termsstyle'>
                                                    <input type='checkbox' checked={!enable} value={Checkterms} onClick={(e) => { setCheckterms(e.target.checked); setenable(!enable) }}></input>
                                                    <span style={{cursor:'pointer'}} onClick={()=>tncClick()}>Terms and Conditions</span>
                                                </div>
                                                <button className='signupsubmit' style={{ marginBottom: '20px', marginTop: '20px' }} onClick={() => { OTPfun(); }}>VERIFY</button>
                                                <div style={{ width: '100%' }}>
                                                </div>
                                            </>
                                        :


                                        <>

                                            <label ><b>Username</b><span style={{ color: 'red' }}> *</span></label>
                                            <input className='signupinput' placeholder='Enter your username' value={username} onChange={(e) => { setusername(e.target.value) }}></input>

                                            <label ><b>Password</b><span style={{ color: 'red' }}> *</span></label>
                                            <div style={{ display: 'flex', position: 'relative' }}>
                                                <input className='signupinput' type={showPassword ? "text" : "password"} placeholder='Enter your password' value={password} onChange={(e) => { setpassword(e.target.value) }}></input>
                                                <span style={{ position: 'absolute', right: '3%', marginTop: '10px', cursor: 'pointer' }} onClick={() => { setshowPassword(!showPassword) }}>
                                                    {
                                                        showPassword ?
                                                            <i class="fa fa-eye"></i>
                                                            :
                                                            <i class="fa fa-eye-slash"></i>
                                                    }
                                                </span>
                                            </div>

                                            <button className='signupsubmit' onClick={() => { Loginfun(username, password) }}>Submit</button>

                                            <div style={{ width: '100%', height: '30px' }}>
                                                <span style={{ color: 'blue', display: 'flex', justifyContent: 'center', textDecoration: 'underline', cursor: 'pointer', fontSize: '0.9rem' }} onClick={() => { forgotfunc() }}>forgot your password? </span>
                                            </div>
                                        </>
                                }
                                <div>
                                    <button className='signupsubmit' style={{ width: '100%', margin: 'auto' }} onClick={() => { changespages(); setshowsignup(!showsignup); setshowPassword(false); if (showsignup) { setOpenOtpModal(false) } }}>{showsignup ? "SIGN IN" : "SIGN UP / REGISTER"}</button>
                                </div>
                            </div>
                        </div>
                }

            </div>

            <Modal id="documentmodal" centered animation={true} show={showforgot} onHide={() => { setshowforgot(false) }} style={{ backgroundColor: 'rgba(0, 0, 0, 0.9)' }}>
                <Modal.Header closeButton>
                    <Modal.Title>Reset Password</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div class="row" style={{ width: '95%', margin: 'auto' }}>
                        <div style={{ width: '100%' }}>
                            <label ><b>User Name</b><span style={{ color: 'red' }}> *</span></label>
                            <input className="signupinput" placeholder='username' style={{ marginTop: '5px', height: '40px' }} value={username}
                                onChange={(e) => { setusername(e.target.value) }}></input>
                        </div>
                        <div style={{ width: '100%' }}>
                            <label ><b>New Password</b><span style={{ color: 'red' }}> *</span></label>
                            <div style={{ display: 'flex', position: 'relative' }}>
                                <input className='signupinput' type={showPassword ? "text" : "password"} placeholder='Enter New Password' value={password} onChange={(e) => { Changepassword(e.target.value) }}></input>
                                <span style={{ position: 'absolute', right: '3%', marginTop: '10px' }} onClick={() => { setshowPassword(!showPassword) }}>
                                    {
                                        showPassword ?
                                            <i class="fa fa-eye"></i>
                                            :
                                            <i class="fa fa-eye-slash"></i>
                                    }
                                </span>
                            </div>
                        </div>
                        <div style={{ width: '100%' }}>
                            <label ><b>Confirm Password</b><span style={{ color: 'red' }}> *</span></label>
                            <div style={{ display: 'flex', position: 'relative' }}>
                                <input className='signupinput' type={showPassword ? "text" : "password"} placeholder='Enter your Password' value={confirmpassword}
                                    onChange={(e) => { setconfirmpassword(e.target.value) }}></input>
                                <span style={{ position: 'absolute', right: '3%', marginTop: '10px' }} onClick={() => { setshowPassword(!showPassword) }}>
                                    {
                                        showPassword ?
                                            <i class="fa fa-eye"></i>
                                            :
                                            <i class="fa fa-eye-slash"></i>
                                    }
                                </span>
                            </div>
                        </div>
                        <div class="row" id="Submitbtn" style={{ marginTop: '20px' }}>
                            <div style={{ display: 'flex', justifyContent: 'center' }}>
                                <button style={{ color: 'white', margin: '8px 0px', padding: '10px 30px', backgroundColor: 'black', borderRadius: '10px' }} onClick={() => { EditPassword() }}>Submit</button>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
            <Modal id="documentmodal" centered fullscreen={true} animation={true} show={tncshow} onHide={() => { settncshow(false) }} style={{ backgroundColor: 'rgba(0, 0, 0, 0.9)' }}>
                <Modal.Header closeButton>
                    <Modal.Title>TERMS & CONDITIONS</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div>
                        <TermsandConditions></TermsandConditions>
                    </div>
                </Modal.Body>
            </Modal>
            {/* <Modal id="documentmodal" size={"md"} centered animation={true} show={OpenOtpModal} style={{ backgroundColor: 'rgba(0, 0, 0, 0.9)' }}>
                <Modal.Header closeButton onClick={() => { setOpenOtpModal(false); setEmailOTP([]) }}>
                    <Modal.Title>Verify OTP</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div style={{ margin: '30px 0px', display: 'flex', justifyContent: 'center' }}>
                        <OtpInput
                            value={EmailOTP}
                            onChange={handleOtpChange}
                            numInputs={6}
                            renderSeparator={<span>-</span>}
                            renderInput={(props) => <input {...props} />}
                            isInputNum={true}
                            containerStyle={{ width: '300px', textAlign: 'center' }}
                            inputStyle={windowidth < 400 ? { width: '27px', height: '27px', fontSize: '16px', margin: '0 5px' } : { width: '40px', height: '40px', fontSize: '16px', margin: '0 5px' }}

                        />
                    </div>
                    <button className='signupsubmit' onClick={() => { CheckOTP() }}>Verify OTP</button>

                </Modal.Body>
            </Modal > */}

            <ToastContainer/>
        </>
    )
}

export default SignUp