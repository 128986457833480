import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import { Helmet } from 'react-helmet-async';

const About2 = () => {
  const dispatch = useDispatch();
  const [windowidth, setwindowwidth] = useState(window.innerWidth);


  useEffect(() => {
    dispatch({ type: 'SaveRoutes', payload: { route: "about" } });

    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });

    const handleWindowsize = () => {
      // console.log(window.innerWidth)
      setwindowwidth(window.innerWidth);
    }

    window.addEventListener('resize', handleWindowsize);
    return () => {
      window.removeEventListener('resize', handleWindowsize)
    }

  }, [])

  return (
    <>
      <div style={{ width: '95%', height: 'auto', margin: 'auto', marginTop: '1.5rem', marginBottom: '5rem' }}>
        {/* <Helmet>
          <title>Seetech Parts - JCB Spare parts Manufacturer, Faridabad, India</title>
          <meta name="description" content="Seetech Faridabad, Haryana - reliable replacement parts for heavy equipment, jcb spare parts manufacturer, Earth Moving Machinery parts since 2008." />
          <meta name="keywords"
            content="jcb spare parts manufacturer, earth moving equipment, jcb equipment parts, jcb parts dealer near me, jcb machine spares parts manufacturer" />
        </Helmet> */}
        {/* <Helmet>
        <title>Seetech Parts - JCB Spare parts Manufacturer, Faridabad, India</title>
        <meta name="description" content="Seetech Faridabad, Haryana - reliable replacement parts for heavy equipment, jcb spare parts manufacturer, Earth Moving Machinery parts since 2008." />
        <meta name="keywords"
          content="jcb spare parts manufacturer, earth moving equipment, jcb equipment parts, jcb parts dealer near me, jcb machine spares parts manufacturer" />
        <link rel="canonical" href="https://seetechparts.com/" />
      </Helmet> */}

        <div style={{ width: '100%', marginTop: '20px' }}>
          <h3 style={{ borderBottom: '2px solid #f9b101' }}>About us</h3>
        </div>
        {/* <h5 style={{ marginTop: '1.5rem' }}>Here at Seetech, we are driven by one purpose – Customer centricity</h5> */}
        <p style={{ marginTop: '0.5rem' }}>
          In 2008, we embarked on this journey when our promoters recognized the challenges faced
          by heavy machine owners due to the lack of availability and poor quality of spare parts. Since
          then, we have worked closely with our customers to fulfil all their spare parts requirements
          and offer them the necessary support.

        </p>

        <p style={{ marginTop: '0.5rem' }}>
          Seetech is a well-known provider of reliable replacement parts for heavy equipment used in
          various industries such as construction, infrastructure, mining, quarry, road and highways,
          material handling, transport, agriculture, and more. We offer parts for a wide range of global
          brands such as JCB, L&T, Case, Terex, Escorts, CAT and more with the convenience of
          online ordering. Our services are highly regarded in the industry and we are committed
          to providing the best possible experience for our customers.
        </p>

        <p style={{ marginTop: '0.5rem' }}>
          Our products are renowned for their reliability, quality and durability. We assist Spare Parts
          dealers and equipment owners in finding the right parts within the required lead time and at
          competitive prices. Customization is also one of our specialties as we can support and provide
          on-demand customized components and accessories required for all kinds of Heavy
          Machinery Equipment, seamlessly replacing the original parts. We are continuously expanding
          our product list to meet our customers requirements, aiming to provide a one-stop shop for
          all your parts and services needs while you own and operate your equipment.
        </p>

        <p style={{ marginTop: '0.5rem' }}>
          We are proud of our team which includes some of the most experienced and knowledgeable
          professionals with background in Innovation, market research, technologies and finance
          having more than 30 years of industry experience.
        </p>

        <p style={{ marginTop: '0.5rem' }}>
          Seetech facilities have complete in-house testing and delivery management. At Seetech we
          take pride in delivering high-quality spare parts made from the best available materials.
        </p>

        <p style={{ marginTop: '0.5rem' }}>Seetech has wide presence in Domestic and International market with customer partner
          network in all major parts of globe like Europe, Asia, Africa, America, Middle East etc. Our
          Truly global customer base is best testimonial of our product qualities and excellent service.</p>

        <div style={{ width: '100%', marginTop: '20px' }}>
          <h6>Our Vision</h6>
        </div>

        <p style={{ marginTop: '0.5rem', marginBottom: '20px' }}>Our mission is to become the preferred choice for all types of spare parts needs in the
          Global Heavy Equipment industry by creating a more transparent and dynamic system for
          our customers.</p>

      </div>
    </>
  )
}

export default About2