import React,{useEffect, useState}  from 'react'
import './Disclaimerpolicy.css'
import { useDispatch } from 'react-redux';

const Disclaimerpolicy = () => {
  const dispatch = useDispatch();
  const [windowidth, setwindowwidth] = useState(window.innerWidth);

  useEffect(() => {
    dispatch({ type: 'SaveRoutes', payload: { route: "disclaimer" } });

    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    const handleWindowsize = () => {
      // console.log(window.innerWidth)
      setwindowwidth(window.innerWidth);
    }

    window.addEventListener('resize', handleWindowsize);
    return () => {
      window.removeEventListener('resize', handleWindowsize)
    }
}, [])

  return (
    <>
      <div style={{ width: '90%', height: 'auto', margin: 'auto', marginTop: '1.5rem', marginBottom: '5rem' }}>
        <div style={{ width: '100%', marginTop: '20px' }}>
          <h3 style={{ borderBottom: '2px solid #f9b101' }}>Disclaimer Policy</h3>
        </div>
        <div>
          {/* <p className="s1" style={{ paddingTop: '2pt', paddingLeft: '5pt', textIndent: '0pt', textAlign: 'justify' }}><a name="bookmark0">Disclaimer Policy</a></p> */}
          <p className="s4" style={{ paddingTop: '13pt', paddingLeft: '5pt', textIndent: '0pt', textAlign: 'justify' }}><a href="http://www.seetechparts.com/" style={{ color: '#011522', fontFamily: 'Arial, sans-serif', fontStyle: 'normal', fontWeight: 'normal', textDecoration: 'none', fontSize: '12pt' }} target="_blank">This Disclaimer Policy is created by </a><span style={{ color: '#00F', fontFamily: 'Arial, sans-serif', fontStyle: 'normal', fontWeight: 'normal', textDecoration: 'underline', fontSize: '12pt' }}>http://www.seetechparts.com</span><span className="s3"> </span><span className="s2">for the products provided through the Website and Platform. In this
            Disclaimer Policy, </span><b>“We”, “Us” </b><span className="s2">“Seetech” </span><b>or “Our” </b><span className="s2">shall refer to </span><b>M/s Seetech, </b><span className="s2">and</span>“<b>You</b>”<span className="s2">, </span>“<b>Your</b>”<span className="s2">, </span>“<b>Yourself</b>”<span className="s2">,
            </span>“<b>User</b>” <span className="s2">shall mean and refer to registered Users of this Website.</span></p>
          <p style={{ textIndent: '0pt', textAlign: 'left' }}><br /></p>
          <ol id="l1">
            <li data-list-text={1.}>
              <p style={{ paddingLeft: '41pt', textIndent: '-18pt', textAlign: 'justify' }}>ANY COMPANY NAME, NUMBERS, LOGOS,
                SYMBOLS, DESCRIPTION, TRADEMARKS&amp;PRODUCT ID’S MENTIONED ON THE WEBSITE ARE FOR REFERENCE AND
                IDENTIFICATION PURPOSES ONLY. WE PROCURE PRODUCTS FROM OEM AUTHORISED DEALERS AND ALSO FROM THIRD-PARTY
                MANUFACTURERS AND ‘SEETECH’ ISN’T AFFILIATED WITH ANY OF THESE COMPANIES.</p>
            </li>
            <li data-list-text={2.}>
              <p style={{ paddingLeft: '41pt', textIndent: '-18pt', textAlign: 'left' }}>THE COMPANIES INCLUDES BUT ARE NOT
                LIMITED TO JCB, L&amp;T, TEREX, CAT, ESCORTS, CARARO, CASE OR ANY OTHER REGISTERED ORIGINAL EQUIPMENT
                MANUFACTURERS. ALL OTHER BRAND NAMES, PRODUCT NAMES, OR TRADEMARKS BELONG TO THEIR RESPECTIVE HOLDERS.
                USE OF THESE NAMES, TRADEMARKS AND BRANDS DOES NOT IMPLY ENDORSEMENT OR AFFILIATION.</p>
            </li>
            <li data-list-text={3.}>
              <p style={{ paddingLeft: '41pt', textIndent: '-18pt', textAlign: 'justify' }}>WE MAY USE MANUFACTURER’S NAMES,
                NUMBERS, LOGOS, SYMBOLS, DESCRIPTIONS &amp; TRADEMARKS IN A REFERENTIAL PHRASE, AND FOR THE PURPOSE OF
                DESCRIBING THE PRODUCT ONLY. PLEASE NOTE THAT ALL TRADEMARKS, TRADE NAMES, NUMBERS, OR LOGOS MENTIONED
                OR USED ARE THE PROPERTY OF THEIR RESPECTIVE ORIGINAL EQUIPMENT MANUFACTURERS. FURTHER, ALL OTHER
                TRADEMARKS CITED ON THE WEBSITE ARE THE PROPERTY OF THEIR RESPECTIVE OWNERS.</p>
            </li>
            <li data-list-text={4.}>
              <p style={{ paddingLeft: '41pt', textIndent: '-18pt', textAlign: 'justify' }}>EQUIPMENT WARRANTY DISCLAIMER: WE MAKE
                NO EXPRESS WARRANTIES; AND DISCLAIM ALL IMPLIED WARRANTIES, WARRANTY STRICTLY AS PER WARRANTY POLICY.
              </p>
            </li>
            <li data-list-text={5.}>
              <p style={{ paddingLeft: '41pt', textIndent: '-18pt', textAlign: 'justify' }}>THE USE OF ANY IMAGE ON THE WEBSITE IS
                FOR ADVERTISEMENT PURPOSES ONLY AND IT DOESN’T IMPLY ANY CONDUCT, WATERMARK OF REFERENCE ON ANY OTHER
                ORGANISATION WITH WHICH WE ARE NOT ASSOCIATED WITH, WE WILL NOT MAKE REPRESENTATIONS OR WARRANTIES OF
                ANY KIND FOR YOUR USE OF ANY IMAGE DISPLAYED ON THE WEBSITE.</p>
            </li>
            <li data-list-text={6.}>
              <p style={{ paddingLeft: '41pt', textIndent: '-18pt', textAlign: 'justify' }}>WE WILL NOT BE RESPONSIBLE FOR ANY
                DAMAGE SUFFERED BY FROM USE OF THIS WEBSITE. THIS INCLUDES BUT IS NOT LIMITED TO, PRICING, LOSS OF
                REVENUE/DATA RESULTING FROM DELAYS OR SERVICE INTERRUPTIONS AS MAY OCCUR BECAUSE OF ANY ACT/OMISSION OF
                PARTIES.</p>
            </li>
            <li data-list-text={7.}>
              <p style={{ paddingLeft: '41pt', textIndent: '-18pt', textAlign: 'justify' }}>THIS DISCLAIMER OF LIABILITY ALSO
                APPLIES TO ANY DAMAGES OR INJURY CAUSED BY ANY FAILURE OF PERFORMANCE, ERROR, OMISSION, INTERRUPTION,
                DELETION, DEFECT, DELAY IN OPERATION OR TRANSMISSION, COMPUTER VIRUS, COMMUNICATION LINE FAILURE, THEFT
                OR DESTRUCTION OR UNAUTHORIZED ACCESS TO, ALTERATION OF, OR USE OF RECORD, WHETHER FOR BREACH OF</p>
              <p style={{ paddingTop: '3pt', paddingLeft: '41pt', textIndent: '0pt', textAlign: 'justify' }}>CONTRACT, TORTUOUS
                BEHAVIOUR, NEGLIGENCE, OR UNDER ANY OTHER CAUSE OF ACTION.</p>
            </li>
            <li data-list-text={8.}>
              <p style={{ paddingLeft: '41pt', textIndent: '-18pt', textAlign: 'justify' }}>OUR WEBSITE IS SUBJECT TO
                AVAILABILITY, WITHOUT ANY PROMISES OR GUARANTEES ON THE SAME BY US AND WHILE CERTAIN INFORMATION
                AVAILABLE ON THE WEBSITE IS OUR PROPERTY AND WE ENDEAVOUR TO KEEP THE SAID INFORMATION UPDATED AND
                ACCURATE, WE WILL NOT MAKE REPRESENTATIONS OR WARRANTIES OF ANY KIND, EXPRESS OR IMPLIED, ABOUT THE
                COMPLETENESS, ACCURACY, RELIABILITY, SUITABILITY OR AVAILABILITY CONCERNING THE WEBSITE OR THE
                INFORMATION OR THE SERVICES PROVIDED BY US THROUGH THE WEBSITE FOR ANY PURPOSE. ANY RELIANCE YOU PLACE
                ON SUCH INFORMATION IS THEREFORE STRICTLY AT YOUR OWN RISK.</p>
            </li>
            <li data-list-text={9.}>
              <p style={{ paddingLeft: '41pt', textIndent: '-18pt', textAlign: 'justify' }}>CERTAIN INTELLECTUAL PROPERTY SHALL
                BELONG TO US, THAT MAY OR MAY HAVE NOT BEEN TRADEMARKED OR COPYRIGHTED BY US AND OR ANY OTHER SUCH
                PERSON AND ANY USE, MISUSE OR MANIPULATIONS THAT MAY BE CONCERNING THE INTELLECTUAL PROPERTY SHALL BE AT
                YOUR SOLE DISCRETION. HOWEVER, WHEN BROUGHT TO OUR NOTICE, THERE SHALL BE IMMEDIATE LEGAL ACTION TAKEN.
              </p>
            </li>
            <li data-list-text={10.}>
              <p style={{ paddingLeft: '41pt', textIndent: '-18pt', textAlign: 'justify' }}>THE USE OF ANY INFORMATION ON THE
                WEBSITE IS AT YOUR SOLE RESPONSIBILITY AND IN CASE OF ANY NEGLIGENCE ON YOUR PART SHALL NOT BE CONSTRUED
                AS IMPOSING ANY LIABILITY, DIRECT OR INDIRECT, ON THE COMPANY OR ANY OF THE EMPLOYEES, DIRECTORS OR SUCH
                OTHER AUTHORISED REPRESENTATIVES OF THE COMPANY.</p>
            </li>
            <li data-list-text={11.}>
              <p style={{ paddingLeft: '41pt', textIndent: '-18pt', textAlign: 'justify' }}>IN NO EVENT WILL WE BE LIABLE FOR ANY
                LOSS OR DAMAGE INCLUDING WITHOUT LIMITATION, INDIRECT OR CONSEQUENTIAL LOSS OR DAMAGE, OR ANY LOSS OR
                DAMAGE WHATSOEVER ARISING FROM LOSS OF DATA OR PROFITS ARISING OUT OF, OR IN CONNECTION WITH, THE USE OF
                THE WEBSITE.</p>
            </li>
            <li data-list-text={12.}>
              <p style={{ paddingLeft: '41pt', textIndent: '-18pt', textAlign: 'justify' }}>THROUGH THIS WEBSITE, YOU MAY BE ABLE
                TO LINK TO OTHER WEBSITES WHICH ARE NOT UNDER OUR CONTROL. WE HAVE NO CONTROL OVER THE NATURE, CONTENT
                AND AVAILABILITY OF THOSE SITES. THE INCLUSION OF ANY LINKS DOES NOT NECESSARILY IMPLY A RECOMMENDATION
                OR ENDORSE THE VIEWS EXPRESSED WITHIN THEM. EVERY EFFORT IS MADE TO KEEP THE WEBSITE UP AND RUNNING
                SMOOTHLY. HOWEVER, WE TAKE NO RESPONSIBILITY FOR, AND WILL NOT BE LIABLE FOR, THE WEBSITE BEING
                TEMPORARILY UNAVAILABLE DUE TO TECHNICAL ISSUES BEYOND OUR CONTROL.</p>
            </li>
            <li data-list-text={13.}>
              <p style={{ paddingLeft: '41pt', textIndent: '-18pt', textAlign: 'justify' }}>YOU UNDERSTAND AND AGREE THAT OUR
                SERVICES ARE PROVIDED <i>“AS IS” </i>AND <i>“AS AVAILABLE” </i>WITHOUT EXPRESS OR IMPLIED WARRANTY OR
                CONDITION OF ANY KIND. WE AND ALL OWNERS OF THE CONTENT MAKE NO REPRESENTATIONS AND DISCLAIM ANY
                WARRANTIES OR CONDITIONS OF SATISFACTORY QUALITY, MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, OR
                NON-INFRINGEMENT. NEITHER US NOR ANY OWNER OF CONTENT WARRANTS THAT THE SERVICES OR CONTENT ON THE
                WEBSITE IS FREE OF MALWARE OR OTHER HARMFUL COMPONENTS. BESIDES, WE MAKE NO REPRESENTATION NOR DO WE
                WARRANT, ENDORSE,</p>
              <p style={{ paddingTop: '3pt', paddingLeft: '41pt', textIndent: '0pt', textAlign: 'justify' }}>GUARANTEE, OR ASSUME
                RESPONSIBILITY FOR ANY THIRD-PARTY APPLICATIONS (OR THE CONTENT THEREOF), USER CONTENT, DEVICES OR
                SERVICE ADVERTISED, PROMOTED OR OFFERED BY A THIRD PARTY ON OR THROUGH OUR SERVICES OR ANY HYPERLINKED
                WEBSITE, OR FEATURED IN ANY BANNER AND WE ARE NOT RESPONSIBLE OR LIABLE FOR ANY TRANSACTION BETWEEN YOU
                AND THIRD-PARTY PROVIDERS OF THE FOREGOING. NO ADVICE OR INFORMATION WHETHER ORAL OR IN WRITING OBTAINED
                BY YOU FROM US SHALL CREATE ANY WARRANTY ON BEHALF OF US. WHILE USING OUR WEBSITE, YOU MAY HAVE ACCESS
                TO EXPLICIT CONTENT FILTERING FEATURES, BUT THE USE OF THESE FEATURES MAY STILL RESULT IN SOME EXPLICIT
                CONTENT BEING SERVED AND YOU SHOULD NOT RELY ON SUCH FEATURES TO FILTER ALL EXPLICIT CONTENT. THIS
                SECTION APPLIES TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW.</p>
            </li>
            <li data-list-text={14.}>
              <p style={{ paddingLeft: '41pt', textIndent: '-18pt', textAlign: 'justify' }}>THIS SECTION DOES NOT AFFECT YOUR
                STATUTORY RIGHTS AS A USER AND CONSUMER OF THE PLATFORM.</p>
            </li>
          </ol>
        </div>
      </div>

    </>
  )
}

export default Disclaimerpolicy