import React from 'react'

const ErrorPage = () => {
  return (
    <div style={{width:'100%', display:'flex',height:'500px',justifyContent:'center', alignItems:'center'}}>
        <h2 style={{fontWeight:'700', color:'rgb(249, 177, 1)'}}>
           No Page Found
        </h2>
    </div>
  )
}

export default ErrorPage