import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import Hexapi from '../usefulcomps/HexAPI/Hexapi';
import LogoutIcon from '@mui/icons-material/Logout';
import Swal from 'sweetalert2';
import MenuSharpIcon from '@mui/icons-material/MenuSharp';
import CloseSharpIcon from '@mui/icons-material/CloseSharp';
import AccountCircleSharpIcon from '@mui/icons-material/AccountCircleSharp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import Tooltip from '@mui/material/Tooltip';
// import Typography from '@mui/material/Typography';
import { useDispatch, useSelector } from 'react-redux';
import SearchIcon from '@mui/icons-material/Search';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import CloseIcon from '@mui/icons-material/Close';
import './header.scss'
import seetechimg from "../Comp/Seetechimages/Seetechimages/seetech-transformed.webp"

const Header = () => {
    const [windowidth, setwindowwidth] = useState(window.innerWidth);
    const [showsignup, setshowsignup] = useState(false);
    const [username, setusername] = useState('');
    const [shownavbar, setnavbar] = useState(false);
    const [showCompanyli, setshowCompanyli] = useState(false);
    const [activelink, setactivelink] = useState('');
    const CartItems = useSelector(state => state.counter.CartItems);
    const ReduxSearchData = useSelector(state => state.counter.SearchData);
    const [usertype, setusertype] = useState('');
    const dispatch = useDispatch();
    const [searchvalue, setsearchvalue] = useState('');
    const [expandfilters, setfilters] = useState(false)
    useEffect(() => {
        const handleWindowsize = () => {
            setwindowwidth(window.innerWidth);
        }
        window.addEventListener('resize', handleWindowsize);
        return () => {
            window.removeEventListener('resize', handleWindowsize)
        }
    }, [])
    useEffect(() => {
        if(ReduxSearchData == ''){
            clearSearch()
        }
    }, [ReduxSearchData])
    useEffect(() => {
        if (sessionStorage.getItem('Apipathurl') == undefined || sessionStorage.getItem('Apipathurl') == null || sessionStorage.getItem('Apipathurl') == "") {
            window.location.reload();
        }
        if (sessionStorage.getItem('userid')) {
            // setshowsignup(true);
            let obj1 = {
                'query': `[dbo].[Seetech_Web_Proc_User_Details]@UserID='{0}'`,
                'queryArr': [`${sessionStorage.getItem('userid')}`]
            }
            Hexapi(obj1).then((res) => {
                console.log(res[""])
                dispatch({ type: 'CartItems', payload: { CartItems: res[''][0].totalcartitem } });
                setusername(res[""][0].username)
                setusertype(res[""][0].usertype);
                console.log(usertype,)
                if (res[""][0].usertype == "Admin") {
                    setshowsignup(true);
                }
            })
        }
    }, [])
    const Logoutfun = () => {
        Swal.fire({
            text: " Do you want to Logout?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes'
        }).then((result) => {
            if (result.isConfirmed) {
                setshowsignup(false);
                setusername('')
                sessionStorage.removeItem("username")
                sessionStorage.removeItem("userid")
                sessionStorage.removeItem("login")
                sessionStorage.removeItem("usertype")
                window.location.href = "/"

            }
        })
    }
    const navigate = useNavigate()
    const storeSearch = () => {
        navigate('/Shop')
        let searchtext = -1;
        if (ReduxSearchData != "") {
            searchtext = searchvalue;
        }
        dispatch({ type: 'SaveSearch', payload: { SearchVal: searchvalue } });
        dispatch({ type: 'SaveNormalFilter', payload: { filtervalue: "" } });
        dispatch({ type: 'UpCheckbox', payload: { filtercheckbox: {} } });
        dispatch({ type: 'Filtertext', payload: { filtertext: [] } });
        dispatch({ type: 'SaveFilterObject', payload: { FilterOj: {} } });
        dispatch({ type: 'ModalCheckbox', payload: { modalcheckbox: "" } });
        dispatch({ type: 'SaveModalFilter', payload: { Modalfiltervalue: "" } });
    }
    const clearSearch = () => {
        setsearchvalue('');
        dispatch({ type: 'SaveSearch', payload: { SearchVal: "" } });
    }
    const handleKeyDown = (event) => {
        if (event.key === 'Enter' && searchvalue.length > 0) {
            console.log("handlekey down")
            storeSearch();
        }
        else if (event.key === 'Backspace' && searchvalue.length === 1) {
            clearSearch();
        }
    };
    return (
        <>
            <div className='MobileNavbar' style={windowidth < 800 ? { width: '100%', height: '80px', backgroundColor: 'black', position: 'sticky', top: '0', zIndex: '11' } : { width: '100%', height: '80px', backgroundColor: 'black', position: 'sticky', top: '0', zIndex: '11' }}>
                <div style={{ margin: 'auto', width: '96%', display: 'flex', justifyContent: 'space-between' }}>
                    {
                        windowidth < 1263 ?
                            <div style={{ display: 'flex', alignItems: 'center' }}>

                                <span onClick={() => { setnavbar(!shownavbar) }}>
                                    {shownavbar ?
                                        <CloseSharpIcon style={{ color: '#f9b101', height: '25px', width: '40px', transition: 'all .3s ease-in-out' }} />
                                        :
                                        <MenuSharpIcon style={{ color: '#f9b101', height: '25px', width: '40px', transition: 'all .3s ease-in-out' }} />
                                    }
                                </span>
                            </div>
                            :
                            null
                    }
                    <div style={{ height: '80px', display: 'flex', alignItems: 'center', gap: '10px' }}>
                        <Link to={"/"} style={{ textDecoration: 'none', color: 'white' }}>
                            <img src={seetechimg} onClick={() => { setshowCompanyli(false); setactivelink("home") }} height="40px" width="100px"></img>
                        </Link>

                    </div>
                    {/* <div style={{display:'flex',alignItems:'center'}}>
                            
                        </div> */}
                    <div className='Navbar' style={{ display: 'flex', height: 'auto' }}>
                        <div className='Navbody' >
                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
                                {
                                    windowidth >= 1263 ?
                                        <nav style={{ color: 'white' }}>
                                            <ul className="menu">
                                                <Link to={"/"} style={{ textDecoration: 'none', color: 'white' }}>
                                                    <li className='menuitems' style={activelink == "home" ? { color: '#f9b101' } : {}} onClick={() => { setshowCompanyli(false); setactivelink("home") }}  >
                                                        Home
                                                    </li>
                                                </Link>

                                                <Link to={"/about"} style={{ textDecoration: 'none', color: 'white' }}>
                                                    <li className='menuitems'
                                                        style={activelink == "about" ? { color: '#f9b101' } : {}} onClick={() => { setshowCompanyli(false); setactivelink("about") }}  >
                                                        About Us

                                                    </li>
                                                </Link>

                                                <Link to={"/Shop"} style={{ textDecoration: 'none', color: 'white' }}>
                                                    <li className='menuitems' style={activelink == "Shop" ? { color: '#f9b101' } : {}} onClick={() => { setshowCompanyli(false); setactivelink("Shop"); }} >
                                                        Shop
                                                    </li>
                                                </Link>
                                                <Link to={"/Blog"} style={{ textDecoration: 'none', color: 'white' }}>
                                                    <li className='menuitems' style={activelink == "Blog" ? { color: '#f9b101' } : {}} onClick={() => { setshowCompanyli(false); setactivelink("Blog"); }} >
                                                        Blog
                                                    </li>
                                                </Link>
                                                {/* {
                                                    sessionStorage.getItem('userid') ?
                                                        <Link to={"/Cart"} style={{ textDecoration: 'none', color: 'white' }}>
                                                            <li className='menuitems' style={activelink == "Cart" ? { color: '#f9b101' } : {}} onClick={() => { setshowCompanyli(false); setactivelink("Cart") }}  >
                                                                Cart
                                                            </li>
                                                        </Link>
                                                        :
                                                        null
                                                } */}
                                                {
                                                    usertype.toLowerCase() == "visitor" ?
                                                        null
                                                        :
                                                        sessionStorage.getItem('userid') ?
                                                            <Link to={"/wishlist"} style={{ textDecoration: 'none', color: 'white' }}>
                                                                <li className='menuitems' style={activelink == "wishlist" ? { color: '#f9b101' } : {}} onClick={() => { setshowCompanyli(false); setactivelink("wishlist") }} >
                                                                    Wishlist
                                                                </li>
                                                            </Link>
                                                            :
                                                            null
                                                }
                                                <Link to={"/contact"} style={{ textDecoration: 'none', color: 'white' }}>
                                                    <li className='menuitems' style={activelink == "contact" ? { color: '#f9b101' } : {}} onClick={() => { setshowCompanyli(false); setactivelink("contact") }} >
                                                        Contact Us
                                                    </li>
                                                </Link>
                                                {
                                                    showsignup ?
                                                        <>

                                                            <li className='menuitems' onClick={() => { setshowCompanyli(!showCompanyli) }}>
                                                                <span style={{ display: 'flex', gap: '5px' }}>Company dashboard
                                                                    {
                                                                        showCompanyli ?
                                                                            <KeyboardArrowUpIcon />
                                                                            :
                                                                            <KeyboardArrowDownIcon />

                                                                    }
                                                                </span>
                                                                {
                                                                    showCompanyli ?
                                                                        <ul style={{ position: 'absolute', marginTop: '150px', fontSize: '1rem', textDecoration: 'none', backgroundColor: 'rgb(0, 0, 0)', padding: '5px', borderRadius: '10px', width: '185px' }}>
                                                                            <Link to={"/Productpanel"} style={{ textDecoration: 'none', color: 'white' }}>
                                                                                <li className='inneritems' style={activelink == "productpanel" ? { color: '#f9b101' } : {}} onClick={() => { setshowCompanyli(false); setactivelink("productpanel") }} >Product Panel</li>
                                                                            </Link>
                                                                            <Link to={"/approvalpanel"} style={{ textDecoration: 'none', color: 'white' }}>
                                                                                <li className='inneritems' style={activelink == "approvalpanel" ? { color: '#f9b101' } : {}} onClick={() => { setshowCompanyli(false); setactivelink("approvalpanel") }} >Approval Panel</li>
                                                                            </Link>
                                                                            <Link to={"/orderpanel"} style={{ textDecoration: 'none', color: 'white' }}>
                                                                                <li className='inneritems' style={activelink == "orderpanel" ? { color: '#f9b101' } : {}} onClick={() => { setshowCompanyli(false); setactivelink("orderpanel") }} >Order Panel</li>
                                                                            </Link>
                                                                            <Link to={"/schemepanel"} style={{ textDecoration: 'none', color: 'white' }}>
                                                                                <li className='inneritems' style={activelink == "schemepanel" ? { color: '#f9b101' } : {}} onClick={() => { setshowCompanyli(false); setactivelink("schemepanel") }} >Scheme Panel</li>
                                                                            </Link>
                                                                        </ul>
                                                                        :
                                                                        null
                                                                }
                                                            </li>
                                                        </>
                                                        :
                                                        null
                                                }
                                                {/* {showsignup ?
                                                    <li className='menuitems'
                                                    >
                                                        <Link to={"/Productpanel"}>Product Panel</Link>
                                                    </li>
                                                    :
                                                    null
                                                }
                                                {showsignup ?
                                                    <li className='menuitems'
                                                    >
                                                        <Link to={"/approvalpanel"}>Approval Panel</Link>
                                                    </li>
                                                    :
                                                    null}
                                                
                                                {showsignup ?
                                                    <li className='menuitems' >
                                                        <Link to={"/schemepanel"}>Scheme Panel</Link>
                                                    </li>
                                                    :
                                                    null} */}
                                                {sessionStorage.getItem('userid') && sessionStorage.getItem('userid') != 1 ?
                                                    usertype.toLowerCase() == "visitor" ?
                                                        null
                                                        :
                                                        <Link to={"/orderhistory"} style={{ textDecoration: 'none', color: 'white' }}>
                                                            <li className='menuitems' style={activelink == "orderhistory" ? { color: '#f9b101' } : {}} onClick={() => { setshowCompanyli(false); setactivelink("orderhistory") }} >Order History</li>
                                                        </Link>
                                                    :
                                                    null
                                                }
                                            </ul>
                                        </nav>
                                        :
                                        <>
                                        </>
                                }
                            </div>
                        </div>
                    </div>
                    <div style={{ display: 'flex', gap: '5px' }}>
                        {
                            username != "" ?
                                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'end', cursor: 'pointer' }}>
                                    {
                                        CartItems == 0 ?
                                            null
                                            :
                                            <span style={{ color: 'white', backgroundColor: 'red', position: 'absolute', borderRadius: '50%', width: '20px', textAlign: 'center', marginTop: '-25px', marginRight: '-4px' }}>{CartItems}</span>
                                    }
                                    {
                                        usertype.toLowerCase() == "visitor" ?
                                            null
                                            :
                                            <Link to="/Cart" style={{ color: '#f9b101', fontSize: '1.1rem', fontWeight: '600', textDecoration: 'none' }}  >
                                                <Tooltip title={"Cart"} disableInteractive style={{ backgroundColor: "black" }}>
                                                    <ShoppingCartIcon style={{ color: '#f9b101', cursor: 'pointer' }} onClick={() => { setnavbar(false); setactivelink("Cart") }} />
                                                </Tooltip>
                                            </Link>
                                    }
                                    {/* <span style={{ color: '#f9b101', fontFamily: 'UniversLTW02-67BoldCn,Arial Narrow,Arial,sans-serif', fontSize: '1.1rem', fontWeight: '600' }}>Logout</span> */}
                                </div>
                                :
                                null
                        }

                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'end', cursor: 'pointer' }}
                            //  onClick={() => { refreshsignup() }}
                            onClick={() => { setnavbar(false); setactivelink(""); }}
                        >
                            <Link to="/login" style={{ color: '#f9b101', fontFamily: 'UniversLTW02-67BoldCn,Arial Narrow,Arial,sans-serif', fontSize: '1.1rem', fontWeight: '600', textDecoration: 'none' }} >
                                <span style={{ display: 'flex' }}>
                                    <Tooltip title={username!=""?"Profile":"Login"} disableInteractive style={{ backgroundColor: "black",width:"max-content" }}>
                                        <div>
                                            <AccountCircleSharpIcon pIcon style={{ color: '#f9b101', height: '25px', width: '40px', cursor: 'pointer' }} />
                                            {    
                                                username!=""?null:<span>Login</span>
                                            }
                                        </div>
                                    </Tooltip>
                                    {/* {username != "" ? username : "SignIn"} */}
                                </span>
                            </Link>
                        </div>
                        {
                            username != "" ?
                                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'end', cursor: 'pointer' }} onClick={() => { Logoutfun() }}>
                                    <Tooltip title={"Logout"} disableInteractive style={{ backgroundColor: "black" }}>
                                        <LogoutIcon style={{ color: '#f9b101', height: '20px', width: '30px', cursor: 'pointer' }} />
                                    </Tooltip>

                                    {/* <span style={{ color: '#f9b101', fontFamily: 'UniversLTW02-67BoldCn,Arial Narrow,Arial,sans-serif', fontSize: '1.1rem', fontWeight: '600' }}>Logout</span> */}
                                </div>
                                :
                                null
                        }

                    </div>
                </div>
            </div>
            {/*  */}
            <div className='header-searchbar-root'>
                <div className='header-searchbar-inputdiv'>
                    <input type='text' placeholder='Search....' value={searchvalue} onKeyDown={handleKeyDown} onChange={(e) => { setsearchvalue(e.target.value) }} style={{ border: 'none', outline: 'none' }}></input>
                    <SearchIcon style={{ color: '#f9b101',cursor:'pointer' }} onClick={() => { storeSearch() }} />
                    {
                        searchvalue.length > 0 ?
                            <CloseIcon style={{ color: '#f9b101',cursor:'pointer' }} onClick={() => { clearSearch()}} />
                            :
                            null
                    }
                </div>
                {
                    // windowidth < 1067 ?
                    <span onClick={() => { setfilters(!expandfilters) }} >
                        {/* <FilterAltIcon style={{ color: "#f9b101" }} /> */}
                    </span>
                    // :
                    // null
                }
            </div>
            {/*  */}

            {/* {
                windowidth < 1263 &&
                <div className='Navbar'>
                    <div style={{ width: '99%', margin: 'auto', justifyContent: 'flex-start', display: 'flex' }} >
                        <div style={{ display: 'flex', alignItems: 'center' }}>

                            <span onClick={() => { setnavbar(!shownavbar) }}>
                                {shownavbar ?
                                    <CloseSharpIcon style={{ color: '#f9b101', height: '30px', width: '40px', transition: 'all .3s ease-in-out' }} />
                                    :
                                    <MenuSharpIcon style={{ color: '#f9b101', height: '30px', width: '40px', transition: 'all .3s ease-in-out' }} />
                                }
                            </span>
                        </div>
                    </div>
                </div>
            } */}
            {
                shownavbar && windowidth < 1263 ?
                    <div style={{ position: 'fixed', width: '100%', backgroundColor: 'black', zIndex: '2', width: '96%' }}>
                        <nav style={{ color: 'white', transition: 'all ease-in-out 0.3s' }}>
                            <ul style={{ margin: '0', padding: '0', listStyle: 'none' }}>
                                <Link to={"/"} style={{ textDecoration: 'none', color: 'white' }}>
                                    <li className='menuitems'
                                        style={activelink == "home" ? { color: '#f9b101' } : {}}
                                        onClick={() => { setnavbar(false); setactivelink("home") }}
                                    >Home
                                    </li>
                                </Link>

                                <Link to={"/about"} style={{ textDecoration: 'none', color: 'white' }}>
                                    <li className='menuitems'
                                        style={activelink == "about" ? { color: '#f9b101' } : {}}
                                        onClick={() => { setnavbar(false); setactivelink("about") }}

                                    >About Us
                                    </li>
                                </Link>

                                <Link to={"/Shop"} style={{ textDecoration: 'none', color: 'white' }}>
                                    <li className='menuitems'
                                        style={activelink == "Shop" ? { color: '#f9b101' } : {}}
                                        onClick={() => { setnavbar(false); setactivelink("Shop"); }}
                                    >Shop
                                    </li>
                                </Link>

                                <Link to={"/Blog"} style={{ textDecoration: 'none', color: 'white' }}>
                                    <li className='menuitems'
                                        style={activelink == "Blog" ? { color: '#f9b101' } : {}}
                                        onClick={() => { setnavbar(false); setactivelink("Blog"); }}
                                    >Blog
                                    </li>
                                </Link>
                                {/* {
                                    sessionStorage.getItem('userid') ?
                                        <Link to={"/Cart"} style={{ textDecoration: 'none', color: 'white' }}>
                                            <li className='menuitems'
                                                style={activelink == "Cart" ? { color: '#f9b101' } : {}}
                                                onClick={() => { setnavbar(false); setactivelink("Cart") }}
                                            >Cart
                                            </li>
                                        </Link>
                                        :
                                        null
                                } */}

                                {
                                    sessionStorage.getItem('userid') ?
                                        <Link to={"/wishlist"} style={{ textDecoration: 'none', color: 'white' }}>
                                            <li className='menuitems'
                                                style={activelink == "wishlist" ? { color: '#f9b101' } : {}}
                                                onClick={() => { setnavbar(false); setactivelink("wishlist") }}
                                            >Wishlist
                                            </li>
                                        </Link>
                                        :
                                        null
                                }

                                <Link to={"/contact"} style={{ textDecoration: 'none', color: 'white' }}>
                                    <li className='menuitems'
                                        style={activelink == "contact" ? { color: '#f9b101' } : {}}
                                        onClick={() => { setnavbar(false); setactivelink("contact") }}
                                    >Contact Us
                                    </li>
                                </Link>

                                {
                                    showsignup ?
                                        <li className='menuitems' style={{ fontSize: '1rem' }}
                                            onClick={() => { setshowCompanyli(!showCompanyli); }}>
                                            Company dashboard
                                            {
                                                showCompanyli ?
                                                    <KeyboardArrowUpIcon />
                                                    :
                                                    <KeyboardArrowDownIcon />

                                            }
                                        </li>
                                        :
                                        null
                                }
                                {showCompanyli ?
                                    <Link to={"/Productpanel"} style={{ textDecoration: 'none', color: 'white' }}>
                                        <li className='menuitems'
                                            style={activelink == "Productpanel" ? { color: '#f9b101', paddingLeft: '1rem' } : { paddingLeft: '1rem' }}
                                            onClick={() => { setnavbar(false); setactivelink("Productpanel") }}>
                                            Product Panel
                                        </li>
                                    </Link>
                                    :
                                    null}
                                {showCompanyli ?
                                    <Link to={"/approvalpanel"} style={{ textDecoration: 'none', color: 'white' }}>
                                        <li className='menuitems'
                                            style={activelink == "approvalpanel" ? { color: '#f9b101', paddingLeft: '1rem' } : { paddingLeft: '1rem' }}
                                            onClick={() => { setnavbar(false); setactivelink("approvalpanel") }}>
                                            Approval Panel
                                        </li>
                                    </Link>
                                    :
                                    null}
                                {showCompanyli ?
                                    <Link to={"/orderpanel"} style={{ textDecoration: 'none', color: 'white' }}>
                                        <li className='menuitems'
                                            style={activelink == "orderpanel" ? { color: '#f9b101', paddingLeft: '1rem' } : { paddingLeft: '1rem' }}
                                            onClick={() => { setnavbar(false); setactivelink("orderpanel") }}>
                                            Order Panel </li>
                                    </Link>
                                    :
                                    null
                                }
                                {showCompanyli ?
                                    <Link to={"/schemepanel"} style={{ textDecoration: 'none', color: 'white' }}>
                                        <li className='menuitems'
                                            style={activelink == "schemepanel" ? { color: '#f9b101', paddingLeft: '1rem' } : { paddingLeft: '1rem' }}
                                            onClick={() => { setnavbar(false); setactivelink("schemepanel") }}
                                        >Scheme Panel
                                        </li>
                                    </Link>
                                    :
                                    null
                                }
                                {
                                    sessionStorage.getItem('userid') && sessionStorage.getItem('userid') != 1 ?
                                        usertype.toLowerCase() == "visitor" ?
                                            null
                                            :
                                            <Link to={"/orderhistory"} style={{ textDecoration: 'none', color: 'white' }}>
                                                <li className='menuitems'
                                                    style={activelink == "orderhistory" ? { color: '#f9b101' } : {}} onClick={() => { setnavbar(false); setactivelink("orderhistory") }}>
                                                    Order History
                                                </li>
                                            </Link>
                                        :
                                        null
                                }
                            </ul>
                        </nav>
                    </div>
                    : null
            }
        </>
    )
}
export default Header