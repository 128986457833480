import React, { useEffect, useState } from 'react'
import './blogmerge.scss'
import Blog from './Blog'
import Blogpreview from './Blogpreview'

const Blogmerge = (props) => {

    const [isblogPreview, setBlogPreview] = useState(false)
    const [index, setindex] = useState('')

    // useEffect(() => {
    
    // }, [])

    const showBlogpreview = (index) => {
        setBlogPreview(true)
        setindex(index)
    }

    const showBlog = (index) => {
        setBlogPreview(false)
    }



    return (
        <>
            <div className="blogmerge-root">
                {
                    isblogPreview ? <Blogpreview showBlog={(index) => showBlog()} index={index} /> : <Blog showBlogpreview={(index) => showBlogpreview(index)} />
                }
            </div>
        </>
    )
}

export default Blogmerge