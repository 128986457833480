import React, { useState, useEffect, useRef } from 'react'
import Hexapi from '../usefulcomps/HexAPI/Hexapi';
import { Modal, Spinner } from 'react-bootstrap';
import axios from 'axios';
import Swal from 'sweetalert2';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import { Link } from 'react-router-dom'
import { Input } from "../usefulcomps/HexInput/InputBox";
import AddIcon from '@mui/icons-material/Add';
import Countrydata from './Countrydata';
import AllStates from './AllStates';
import { useDispatch, useSelector } from 'react-redux';
import DeleteIcon from '@mui/icons-material/Delete';
import whatsappnotification from './whatsappnotification';
import { type } from '@testing-library/user-event/dist/type';


const Cart = (props) => {
    const dispatch = useDispatch();
    const [windowidth, setwindowwidth] = useState(window.innerWidth);
    const [Cartdata, setCartdata] = useState([]);
    const [TotalPrice, setTotalPrice] = useState(0);
    const [productQuantity, setProductQuantity] = useState([])
    const [showLoading, setshowLoading] = useState(true);
    const [showquotationpdf, setshowquotationpdf] = useState(false);
    const [quotationpdffile, setquotationpdffile] = useState('');
    const [OverseasINR, setOverseasINR] = useState(false);
    const [totalitems, settotalitems] = useState(0);
    const [Userorigin, setUserorigin] = useState('');
    const [usertype, setusertype] = useState('');
    const [currency, setcurrency] = useState('');
    const [Address, setAddress] = useState("")
    const [OpenExtraModal, setOpenExtraModal] = useState(false);
    const [ShowCheckout, setShowCheckout] = useState(false);
    const [AddressData, setAddressData] = useState([]);
    const [OpenAddressModal, setOpenAddressModal] = useState(false);
    const [firstname, setfirstname] = useState('');
    const [countrycode, setcountrycode] = useState('');
    const [pincode, setpincode] = useState('');
    const [flatAddress, setflatAddress] = useState('');
    const [AreaAddress, setAreaAddress] = useState('');
    const [Landmark, setLandmark] = useState('');
    const [city, setcity] = useState('');
    const [state, setstate] = useState('');
    const TotalCartItems = useSelector(state => state.counter.CartItems);

    useEffect(()=>{
        const date = new Date().toISOString().split('T')[0];
console.log(date,"date")
    },[])
    useEffect(() => {
        document.title = 'JCB Spare Parts - Earth Moving Equipment Manufacturer | Seetech Parts, Faridabad'
        dispatch({ type: 'SaveRoutes', payload: { route: "cart" } });
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
        if (sessionStorage.getItem('userid') != undefined && sessionStorage.getItem('userid') != null && sessionStorage.getItem('userid') != "") {
            let obj1 = {
                'query': `[dbo].[Seetech_Web_Proc_UserAddress_Getdata] @UserID='{0}'`,
                'queryArr': [`${sessionStorage.getItem('userid')}`]
            }
            Hexapi(obj1).then((res) => {
                console.log(res);
                if(res[""] != undefined && res[""].length >0){
                    let dataadd = res[""].filter(col => col.useraddress != null)
                    console.log(dataadd,res[""]);
                    // setflatAddress()
                    if(dataadd != null){
                        setAddress(dataadd[dataadd.length - 1]?.useraddress)
                        setAddressData(dataadd)
                        // console.log(dataadd[0].useraddress);
                        let splitAdd = dataadd[dataadd.length - 1]?.useraddress.split(",");
                        setflatAddress(splitAdd[0]);
                        setpincode(splitAdd[1]);
                        setAreaAddress(splitAdd[2]);
                        setLandmark(splitAdd[3]);
                        setcity(splitAdd[4]);
                        setstate(splitAdd[5]);
                        setcountrycode(splitAdd[6])
                    }
                }
            })
        }
        else {
            window.location.href = "/"
        }
        if (sessionStorage.getItem('userid') != undefined && sessionStorage.getItem('userid') != null && sessionStorage.getItem('userid') != "") {
            let obj1 = {
                'query': `[dbo].[Seetech_Web_Proc_User_Details]@UserID='{0}'`,
                'queryArr': [`${sessionStorage.getItem('userid')}`]
            }
            Hexapi(obj1).then((res) => {
                // console.log(res[''][0])
                if (res[''][0]) {
                    setUserorigin(res[""][0].origin);
                    setcurrency(res[""][0].currency)
                    setusertype(res[""][0].usertype)
                    if (res[""][0].currency == "inr" || res[""][0].currency == "INR") {
                        setOverseasINR(true);
                    }
                    else if (res[""][0].currency == "usd" || res[""][0].currency == "USD") {
                        setOverseasINR(false);
                    }
                }
                CartgetData()
            });
        }   
        setTimeout(() => {
            console.log(OverseasINR)
            console.log(usertype)
            console.log(Userorigin)
        }, 300);

        const handleWindowsize = () => {
            // console.log(window.innerWidth)
            setwindowwidth(window.innerWidth);
        }

        window.addEventListener('resize', handleWindowsize);
        return () => {
            window.removeEventListener('resize', handleWindowsize)
        }

    }, [Userorigin])

    const CartgetData = () => {

        setshowLoading(true);
        let obj = {
            'query': `[dbo].[Seetech_Web_Proc_Cart_GetData] @UserID='{0}'`,
            'queryArr': [`${sessionStorage.getItem('userid')}`]
        }
        Hexapi(obj).then((res) => {
            console.log(res)
            if (res[""].length > 0) {
                let data = res[""];
                // console.log(data)
                let totalprice = 0;
                let Quantities = [];
                let items = 0;


                data.map((s, index) => {
                    items += s.qty;
                    Quantities.push(s.qty);
                    console.log(Userorigin.toLowerCase())
                    console.log(usertype.toLowerCase())
                    if (Userorigin.toLowerCase() == "domestic") {
                        if (usertype.toLowerCase() == "retailer") {
                            let price = Number(s.mrp).toFixed(2)
                            totalprice = totalprice + s.qty * Number(price);
                            setTotalPrice(totalprice)


                        } else {
                            let price = Number(s.wholesaleprice).toFixed(2)
                            totalprice = totalprice + s.qty * Number(price);
                            setTotalPrice(totalprice)

                        }

                    } else {
                        console.log(Userorigin.toLowerCase())
                        if (Userorigin.toLowerCase() == "overseas") {
                            console.log(currency,s.overseasprice)
                                if (currency == "inr" || currency == "INR") {
                                    let price = Number(s.overseasprice * s.conversionrate).toFixed(2)
                                    totalprice = totalprice + s.qty * Number(price)
                                    setTotalPrice(totalprice)
                                } else {
                                    let price = Number(s.overseasprice).toFixed(2)
                                    totalprice = totalprice + s.qty * Number(price)
                                    setTotalPrice(totalprice)

                                }
                        }
                    }
                })
                settotalitems(items)
                setProductQuantity(Quantities)
                // console.warn(totalprice)
                // setTotalPrice(totalprice)
                setCartdata(res[""])
                // console.warn(TotalPrice)
                setshowLoading(false);

            } else {
                console.log(Cartdata.length)
                setCartdata([])
                setshowLoading(false);
            }
        })
    }


    const ClearItems = () => {
        let obj = {
            'query': `[dbo].[Seetech_Web_Proc_Cart_Remove] @UserID='{0}',@ProductID='{1}'`,
            'queryArr': [`${sessionStorage.getItem('userid')}`, `-1`]
        }
        Hexapi(obj).then((res) => {
            console.log(res);
            CartgetData()

        });
    }

    const notify = () =>
        toast.success('Successfully Cart data Updated!', {
            position: toast.POSITION.TOP_CENTER
            , autoClose: 500
        });


    const MarkItems = (Id) => {

    }

    const DeleteItemCart = (Id) => {
        let obj = {
            'query': `[dbo].[Seetech_Web_Proc_Cart_Remove] @UserID='{0}',@ProductID='{1}'`,
            'queryArr': [`${sessionStorage.getItem('userid')}`, `${Id}`]
        }
        Hexapi(obj).then((res) => {
            // console.log(res);
            dispatch({ type: 'CartItems', payload: { CartItems: TotalCartItems - 1 } });

            CartgetData()

        });
    }

    const QuantityChange = async(Id, index, moq,data) => {
        // console.log(data,"data")
        // let obj1 = {
        //     'query': `[dbo].[Seetech_Web_Proc_User_Details]@UserID='{0}'`,
        //     'queryArr': [`${sessionStorage.getItem('userid')}`]
        // }
        // let dataArray={}
        // await Hexapi(obj1).then((res) => {
        //     console.log(res,"response")
        //     dataArray=res && res[""] && res[""][0] ? res[""][0] : {}
        // })
        // console.log(dataArray,"dataarray")
        if (productQuantity[index] > 0) {
            setshowLoading(true);
            const Quantity = productQuantity[index] * moq;
            console.log(Quantity,"quantity")
            let obj = {
                'query': `[dbo].[Seetech_Web_Proc_Cart_Quantity_OnChange] @UserID='{0}',@ProductID='{1}',@Qty='{2}'`,
                'queryArr': [`${sessionStorage.getItem('userid')}`, `${Id}`, `${Quantity}`]
            }
            await Hexapi(obj).then((res) => {
                setshowLoading(false);
                notify();
                CartgetData()
                // console.log(res);
            });
            setShowCheckout(false);
            // let date=new Date().toISOString().split('T')[0]
            // const linkdata=`https://SeetechParts.com/SeetechQuotations/${sessionStorage.getItem('userid')}-${Id}.pdf`
            // const templateparams=[data.name,`${Id}`,`${Id}`,date,`${Quantity}`,linkdata,linkdata]
            // const campaign="qty_change_by_user_notifications"
            // await whatsappnotification(templateparams,campaign,dataArray.firstname,dataArray.contactnumber);

        } else {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Quantity cannot be negative',
            })

        }
    }

    const QuotationCheck = () => {
        let obj1 = {
            'query': `[dbo].[Seetech_Web_Proc_User_Details]@UserID='{0}'`,
            'queryArr': [`${sessionStorage.getItem('userid')}`]
        }
        Hexapi(obj1).then((res) => {
            console.log(res,"response")
            // console.warn(res);

            if (res[""][0]?.currency == "inr" || res[""][0]?.currency == "INR") {
                // setOverseasINR(true);
                OpenQuotation(Cartdata, "inr", res[""][0].emailaddress, res[""][0].contactnumber,res[""][0].firstname)
            } else {
                OpenQuotation(Cartdata, "usd", res[""][0].emailaddress, res[""][0].contactnumber,res[""][0].firstname)
            }

        });
    }


    const OpenQuotation = (data, currency, emailid, contactnumber,firstname) => {
        console.log(data,"data")
        console.log(data[0].name,"productname")
        let date=new Date().toISOString().split('T')[0]
        console.log(Address)

        Swal.fire({
            text: " Are you sure you want to Checkout this address ?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes',
            customClass: {
                container: 'custom-swal-container', // Add a custom class to the container
            },
        }).then((result) => {
            if (result.isConfirmed) {
                setshowLoading(true);
                setOpenExtraModal(false);
                setCartdata([])
                dispatch({ type: 'CartItems', payload: { CartItems: 0 } });

                let obj = {
                    'query': `[dbo].[Seetech_Web_Proc_RequestQuote_Submit] @UserID='{0}'`,
                    'queryArr': [`${sessionStorage.getItem('userid')}`]
                }
                Hexapi(obj).then((res) => {
                    console.log(res,"DATA");
                    const orderid = res[""][0].orderid;

                    let obj = {
                        'query': `[dbo].[Seetech_Web_Proc_Submit_UserAdressDetails] @UserID='{0}',  @UserAddress='{1}',@OrderID='{2}'`,
                        'queryArr': [`${sessionStorage.getItem('userid')}`, `${Address}`, `${orderid}`]
                    }
                    Hexapi(obj).then((res) => {
                        console.log(res);
                    });

                    let arr = [];
                    console.log(Userorigin, usertype)
                    let price = 0;
                    data.forEach((value) => { // Loop through your data array
                        let GST = 0;
                     
                        if (Userorigin.toLowerCase() === "domestic") {
                            if (usertype.toLowerCase() == "retailer") {
                                price = Number(value.mrp).toFixed(2);
                                GST = Number(value.mrp).toFixed(2) * value.gst / 100;
                            } else if (usertype.toLowerCase() == "wholesaler") {
                                price = Number(value.wholesaleprice).toFixed(2);
                                GST = Number(value.wholesaleprice).toFixed(2) * value.gst / 100;
                            }
                        }
                        else {
                            if (currency == "inr") {
                                price = value.overseasprice * value.conversionrate;
                            } else {
                                price = value.overseasprice;

                            }
                        }

                        let images = value.images;
                        images = images.split(',');
                        console.log(images, images[0].split('wp-content/')[1])
                        var image = ''
                        if (images[0].split('wp-content/')[1] == undefined) {
                            image = 'uploads/2023/11/dummy.png'
                        } else {
                            image = images[0].split('wp-content/')[1]
                        }

                        // Create an object for each item and push it to the array
                        arr.push({
                            Image: 'https://seetechparts.com/' + image, // Make sure you have the correct image variable
                            description: value.description,
                            Qty: (value.qty == undefined || !value.qty ? 1 : value.qty),
                            price: price,
                            Gst: GST,
                            GSTpercentage: value.gst,
                            Totalprice: usertype.toLowerCase() == "wholesaler" ? Number(value.wholesaleprice).toFixed(2) * value.qty : Number(value.mrp).toFixed(2) * value.qty,
                            hsnCode: value.hsncode,
                            modelReference: value.suitableforpartreference,
                            companyCode: value.companycode,
                            sku: value.sku,
                            productName: value.name,
                            pricetag: currency == "inr" ? "Rs" : "$",
                            conversionrate: value.conversionrate,
                            address: Address,
                            emailID: emailid,
                            ContactNo: contactnumber,
                            UserID: sessionStorage.getItem('userid'),
                            OrderId: orderid,
                            // pricetag: rowData.origin.toLowerCase() === "domestic" || Originvalue == "inr" ? "Rs" : "$",
                            servicecharge: value.servicecharge == NaN ? 0.00 : Number(value.servicecharge).toFixed(2),
                            specialdiscount: value.specialdiscount == NaN ? 0.00 : Number(value.specialdiscount).toFixed(2),
                            packingforward: value.packingcharge == NaN ? 0.00 : Number(value.packingcharge).toFixed(2),
                            origin: Userorigin.toLowerCase() === "domestic" ? "domestic" : "overseas",
                            cifcharges: value.cifcharges == undefined || value.cifcharges == null ? 0.00 : value.cifcharges,
                            bankcharges: value.bankcharges == undefined || value.bankcharges == null ? 0.00 : value.cifcharges,
                        });
                    });
                    console.warn(arr);
                    let fd = new FormData();
                    fd.append('dataArray', JSON.stringify(arr));
                    console.warn(fd);
                    axios({
                        mode: 'cors',
                        method: "POST",
                        // headers: { "Content-Type": "application/json" },
                        headers: { "Content-Type": "multipart/form-data" },
                        // url: `https://hexbss.xyz/testenvironment/seetechApiDomestic.php`,
                        // url: sessionStorage.getItem("origin") === "Domestic" ? `${sessionStorage.getItem('Apipathurl')}/seetechApiDomestic.php` : `${sessionStorage.getItem('Apipathurl')}/seetechApiOverseas.php`,
                        url: currency == "inr" ? `${sessionStorage.getItem('Apipathurl')}seetechINR.php` : `${sessionStorage.getItem('Apipathurl')}seetechUSD.php`,
                        data: fd,
                        responseType: 'arraybuffer',
                    })
                        .then((res) => {
                            console.log(res);
                            const pdfBlob = new Blob([res.data], { type: 'application/pdf' });
                            const link = document.createElement('a');
                            link.href = URL.createObjectURL(pdfBlob);
                            link.download = `SeetechtechQuoation-${sessionStorage.getItem('userid')}-${orderid}`;
                            document.body.appendChild(link);
                            link.click();
                            document.body.removeChild(link);
                            setshowLoading(false);
                            Checkoutnotify()
                            console.log(data[0].name,"productname")
                        }).then((res)=>{
                            console.log(price,"price")
                            console.log(typeof(Number(price)),"typeofprice")
                            const pricedata=Number(price)
                            const pricenumbers=pricedata.toFixed(2)
                            console.log(pricenumbers,"pricenumbers")
                            const linkdata=`https://SeetechParts.com/SeetechQuotations/${sessionStorage.getItem('userid')}-${orderid}.pdf`
                            const templateparams=[`${orderid}`,date,`${pricenumbers}`,linkdata]
                            const campaign="order_notification_new_utility"
                            const admincampaign="new_order_noti_to_admin"
                            const adminname="Seetech"
                            const adminnumber="8178967019"
                            whatsappnotification(templateparams,admincampaign,adminname,adminnumber)
                            whatsappnotification(templateparams,campaign,firstname,contactnumber);
                        })
                })}
            else {
                setOpenAddressModal(true)
            }
        });

    }

    const Checkoutnotify = () =>
        toast.success('Quotaion downloaded Successfully! You can now view the quotation', {
            position: toast.POSITION.TOP_CENTER
            , autoClose: 1500
        });

    const AddUserAddress = () => {
        // console.log(pincode.length)
        if (pincode.length == 6) {
            if (flatAddress != "" && AreaAddress != "" && city != "" && state != "" && Landmark != "" && countrycode != "") {
                const personAddress = `${flatAddress},${pincode},${AreaAddress},${Landmark},${city},${state},${countrycode}`;
                setAddress(personAddress);
                const address = [{ useraddress: personAddress }]
                setAddressData(address)
                Swal.fire({
                    position: 'center',
                    icon: 'success',
                    title: `Address  succesfully submitted`,
                    showConfirmButton: false,
                    timer: 1500
                }).then(() => {
                    setOpenAddressModal(false);
                    // QuotationCheck()
                })

                // let obj = {
                //     'query': `[dbo].[Seetech_Web_Proc_Submit_UserAdressDetails] @UserID='{0}',  @UserAddress='{1}',@OrderID='{2}'`,
                //     'queryArr': [`${sessionStorage.getItem('userid')}`, `${personAddress}`]
                // }
                // Hexapi(obj).then((res) => {
                //     console.log(res);

                //     let obj1 = {
                //         'query': `[dbo].[Seetech_Web_Proc_UserAddress_Getdata] @UserID='{0}'`,
                //         'queryArr': [`${sessionStorage.getItem('userid')}`]
                //     }
                //     Hexapi(obj1).then((resp) => {
                //         console.log(resp);
                //         setAddressData(resp[""])
                //         Swal.fire({
                //             position: 'center',
                //             icon: 'success',
                //             title: `Address  succesfully submitted`,
                //             showConfirmButton: false,
                //             timer: 1500
                //         }).then(() => {
                //             setOpenAddressModal(false);
                //             // QuotationCheck()
                //         })
                //     })
                // })
            } else {
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'Please fill all details',
                })
            }
        } else {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Please fill pincode',
            })
        }
    }

    // const SubmitOtherDetails = () => {
    //     if (Address != "") {
    //         let obj1 = {
    //             'query': `[dbo].[Seetech_Web_Proc_Submit_UserAdressDetails] @UserID='{0}',  @UserAddress='{1}'`,
    //             'queryArr': [`${sessionStorage.getItem('userid')}`, `${Address}`]
    //         }
    //         Hexapi(obj1).then((res) => {
    //             console.log(res);
    //         }).then(() => {
    //             QuotationCheck()
    //         })
    //     } else {
    //         Swal.fire({
    //             icon: 'error',
    //             title: 'Oops...',
    //             text: 'Please fill the address',
    //         })
    //     }
    // }


    return (
        <>
            <div style={{ width: '92%', height: 'auto', margin: 'auto' }}>
                <div style={{ width: '100%', marginTop: '20px' }}>
                    <h3 style={{ borderBottom: '2px solid #f9b101' }}>Cart Items</h3>
                </div>
                {
                    sessionStorage.getItem("userid") == "" || sessionStorage.getItem("userid") == undefined ?
                        <div style={{ height: '400px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <Link to="/login" style={{ textDecoration: 'none' }} className='LoginItemstyle' ><h4 style={{ textAlign: 'center', color: 'white', textTransform: 'none !important' }} >Please Login </h4></Link>
                        </div>
                        :
                        showLoading ?
                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: '10%', color: 'rgb(235 197 137)', minHeight: '400px' }}>
                                <h3>
                                    <Spinner animation='border' style={{ color: '#f9b101' }} />
                                </h3>
                            </div>
                            :

                            Cartdata.length <= 0 ?
                                <div style={{ height: '400px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                    <h4 style={{ textAlign: 'center', color: 'black' }}>No Items in Carts </h4>
                                </div>
                                :
                                <>

                                    <div style={{ display: 'flex', justifyContent: ' flex-start', margin: '5px 0px 0px' }}>
                                        <span className='apply_filterstyle' style={{ backgroundColor: '#f9b101', padding: '5px', cursor: 'pointer', color: 'black', fontWeight: '600' }} onClick={() => { ClearItems() }}>Delete All Items</span>
                                    </div>
                                    <div >
                                        {
                                            Cartdata.map((data, index) => {
                                                let images = data.images;
                                                images = images.split(',');
                                                return (


                                                    <div className='CartItems'>
                                                        <div className='Cartimagestyle' >
                                                            <div style={{ width: '100%', display: 'flex' }}>
                                                                {/* <div className='CartCheckbox' >
                                                                    <input type='checkbox' onClick={() => { MarkItems(data.recid) }} style={{ height: '15px', width: '20px' }}></input>
                                                                </div> */}
                                                                {/* <img id="zoom1" src={data.images != "" ? `${images[0].replace('wp-content/',"")}`: `${sessionStorage.getItem('Apipathurl')}uploads/2023/11/dummy.png`} alt="image" className='CartImages' style={windowidth < 900 ? { width: '100%', height: '100%', margin: 'auto', cursor: 'pointer' } : { width: '80%', height: '80%', margin: 'auto', cursor: 'pointer' }} ></img> */}
                                                                <img src='https://seetechparts.com/uploads/2023/11/dummy.png' className='img-fluid'/>
                                                            </div>
                                                        </div>
                                                        <div style={{ width: '100%' }}
                                                        // style={windowidth < 800 ? { width: '100%', height: 'auto', padding: '40px 0' } : { width: '50%', height: 'auto', padding: '40px 0' }}
                                                        >
                                                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                                                <h5 style={{ fontWeight: '400 !important' }}>{data.name}</h5>
                                                            </div>
                                                            <div>
                                                                <h6 style={{ fontSize: '16px', marginBottom: '0' }}>Description</h6>
                                                                <div>
                                                                    <span style={{ color: '#817575', fontSize: '0.8rem', fontWeight: '500' }}>{data.description}</span>
                                                                </div>
                                                            </div>
                                                            <div>
                                                                <span>by : {data.brand}</span>
                                                            </div>
                                                            <div style={{ display: 'flex', gap: '10px', alignItems: 'center', fontWeight: '600', fontSize: '1.3rem' }}>
                                                                {
                                                                    // usertype == undefined || usertype == null || usertype == "" ?
                                                                    //     null
                                                                    //     :
                                                                    Userorigin.toLowerCase() === "domestic" ?
                                                                        usertype.toLowerCase() == "visitor" ?
                                                                            null
                                                                            :
                                                                            usertype.toLowerCase() == "retailer" ?
                                                                                (`₹${Number(data.mrp).toFixed(2)} `)
                                                                                :
                                                                                (`₹${Number(data.wholesaleprice).toFixed(2)} `)

                                                                        :
                                                                        OverseasINR == true ?
                                                                            `₹ ${Number(data.overseasprice * data.conversionrate).toFixed(2)}`
                                                                            :
                                                                            (
                                                                                `$${Number(data.overseasprice).toFixed(2)}`
                                                                            )
                                                                }
                                                                {
                                                                    Userorigin.toLowerCase() === "domestic" ?
                                                                        <span style={{ fontSize: '0.9rem' }}>exc. GST</span> : null
                                                                }
                                                                {/* {
                                                                    usertype == undefined || usertype == null || usertype == "" ?
                                                                        null
                                                                        :
                                                                        Userorigin.toLowerCase() === "domestic" ?
                                                                            usertype.toLowerCase() == "visitor" ?
                                                                                null
                                                                                :
                                                                                usertype.toLowerCase() == "retailer" ?
                                                                                    (`₹${Number(data.mrp).toFixed(2)}`)
                                                                                    :
                                                                                    (`₹${Number(data.wholesaleprice).toFixed(2)}`)

                                                                            :
                                                                            usertype.toLowerCase() == "visitor" ?
                                                                                null
                                                                                :
                                                                                OverseasINR == true ?
                                                                                    `₹ ${Number(data.overseasprice * data.conversionrate).toFixed(2)}`
                                                                                    : (
                                                                                        `$${Number(data.overseasprice).toFixed(2)}`
                                                                                    )
                                                                } */}
                                                                <span style={{ textDecoration: 'line-through', fontSize: '16px', }}>
                                                                    {
                                                                        usertype == undefined || usertype == null || usertype == "" ?
                                                                            null
                                                                            :
                                                                            Userorigin.toLowerCase() === "domestic" ? (
                                                                                usertype.toLowerCase() == "wholesaler" &&
                                                                                (
                                                                                    ` ₹${Number(data.mrp).toFixed(2)}`
                                                                                )
                                                                            ) : null
                                                                    }

                                                                </span>
                                                            </div>

                                                            <div style={{}}>
                                                                <span style={{ color: 'rgb(208, 132, 12)', fontWeight: 'bold' }}>In stock</span>
                                                            </div>
                                                            <div style={{ display: 'flex', gap: '10px', alignItems: 'center', justifyContent: 'space-between' }}>
                                                                <span>Eligible for shipping</span>
                                                            </div>
                                                            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                                                <div style={windowidth < 684 ? { display: 'flex', gap: '20px', alignItems: 'center', flexFlow: 'wrap' } : { display: 'flex', gap: '20px', alignItems: 'center' }}>
                                                                    <div className='quantitystyle' style={{ cursor: 'pointer', width: '100px' }}>
                                                                        <span>Qty: </span>
                                                                        {/* <select name="cars" id="cars" style={{ border: 'none', backgroundColor: '#f1f1f1' }}
                                                                        onChange={(e) => {
                                                                            // setProductQuantity((prev)=>({...prev,[index]:e.target.value}))
                                                                            setProductQuantity((prev) => {
                                                                                const updatedQuantity = [...prev];
                                                                                updatedQuantity[index] = Number(e.target.value);
                                                                                return updatedQuantity;
                                                                            });
                                                                            QuantityChange(data.recid, e.target.value);
                                                                        }}
                                                                    >

                                                                        {
                                                                            Optionquantity.map((s, i) => {
                                                                                return (
                                                                                    <>
                                                                                        <option value={i + 1} selected={i + 1 == data.qty ? true : false}>{i + 1} </option>
                                                                                    </>
                                                                                )
                                                                            })
                                                                        }
                                                                    </select> */}

                                                                        <input type='number' name="cars" id="cars" value={productQuantity[index] == 0 ? "" : productQuantity[index]} style={{ border: 'none', backgroundColor: '#f1f1f1', width: '45px' }}
                                                                            onChange={(e) => {
                                                                                const enteredValue = Number(e.target.value);

                                                                                if (enteredValue < 0) {
                                                                                    setProductQuantity((prev) => {
                                                                                        const updatedQuantity = [...prev];
                                                                                        updatedQuantity[index] = "";
                                                                                        return updatedQuantity;
                                                                                    });
                                                                                } else {
                                                                                    setProductQuantity((prev) => {
                                                                                        const updatedQuantity = [...prev];
                                                                                        updatedQuantity[index] = enteredValue;
                                                                                        return updatedQuantity;
                                                                                    });
                                                                                    setShowCheckout(true);
                                                                                }

                                                                                //     setProductQuantity((prev) => {
                                                                                //         const updatedQuantity = [...prev];
                                                                                //         updatedQuantity[index] = Number(e.target.value);
                                                                                //         return updatedQuantity;
                                                                                //     });
                                                                                //     setShowCheckout(true);
                                                                                //     // QuantityChange(data.recid, e.target.value)
                                                                            }}
                                                                        ></input>

                                                                    </div>
                                                                    {/* <div class="vl"></div> */}
                                                                    <div>
                                                                        <span className='quantitystyle' style={{ fontSize: '0.8rem', cursor: 'pointer', backgroundColor: 'black', color: '#f9b101', fontWeight: '600' }} onClick={() => {
                                                                            QuantityChange(data.recid, index, data.moq,data)
                                                                        }}>
                                                                            Modify Quantity
                                                                        </span>
                                                                    </div>
                                                                    <div class="vl"></div>
                                                                    <div>
                                                                        <span style={{ fontSize: '0.8rem', cursor: 'pointer', color: '#ea3636', fontWeight: '700' }} onClick={() => { DeleteItemCart(data.recid) }}>
                                                                            <DeleteIcon style={{ color: 'black' }} />
                                                                        </span>
                                                                    </div>

                                                                </div>
                                                                {/* <div style={{}}>
                                                                    <span onClick={() => {
                                                                        QuantityChange(data.recid, index, data.moq)
                                                                        // this.ChangeProductdata(data.recid, index, data.servicecharge, data.packingcharge, data.specialdiscount, data.userid) 
                                                                    }}> <DoneAllIcon /> </span>
                                                                </div> */}


                                                            </div>
                                                        </div>
                                                    </div>

                                                )
                                            })
                                        }
                                    </div>
                                    <div style={{ display: 'flex', justifyContent: 'flex-end', marginBottom: '30px', gap: '10px' }}>
                                        <span style={{ fontSize: '18px' }}>Subtotal ({totalitems}item): <b>
                                            {
                                                usertype == undefined || usertype == null || usertype == "" ?
                                                    null
                                                    :
                                                Userorigin.toLowerCase() === "domestic" ?
                                                    usertype.toLowerCase() == "visitor" ?
                                                        (`₹${Number(TotalPrice).toFixed(2)}`)
                                                        :
                                                        usertype.toLowerCase() == "retailer" ?
                                                            (`₹${Number(TotalPrice).toFixed(2)}`)
                                                            :
                                                            (`₹${Number(TotalPrice).toFixed(2)}`)

                                                    :
                                                    usertype.toLowerCase() == "visitor" ?
                                                        null
                                                        :
                                                        OverseasINR == true ?
                                                            `₹ ${Number(TotalPrice).toFixed(2)}`
                                                            :
                                                            (
                                                                `$${Number(TotalPrice).toFixed(2)}`
                                                            )
                                            }
                                        </b> </span>
                                        {
                                            ShowCheckout ?
                                                // <span onClick={(e) => {}}>Save Cart</span>
                                                null
                                                :
                                                <span className='apply_filterstyle' style={{ backgroundColor: '#f9b101', borderRadius: '4px', padding: '5px 10px', cursor: 'pointer', color: 'black', fontWeight: '600' }}
                                                    onClick={(e) => { setOpenExtraModal(true) }}>Checkout</span>
                                        }
                                    </div>
                                </>
                }
            </div >

            <Modal id="documentmodal" fullscreen={true} centered animation={true} show={OpenExtraModal} onHide={() => { setOpenExtraModal(false); }} style={{ backgroundColor: 'rgba(0, 0, 0, 0.9)' }}>
                <Modal.Header style={{ display: 'flex', justifyContent: 'center' }} closeButton>
                    <Modal.Title >Checkout Details</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div style={{ width: '90%', margin: 'auto' }}>
                        <div>
                            <h6 style={{ color: '#c45500', fontWeight: '600' }}>1.   Select a delivery address</h6>
                        </div>
                        <div style={{ width: '94%', margin: 'auto', border: '1px #D5D9D9 solid', padding: '10px 10px 20px', borderRadius: '6px', }}>
                            <div style={{ borderBottom: '1px #D5D9D9 solid', fontWeight: '700', marginBottom: '6px', }}>
                                Your Address
                            </div>
                            <div style={windowidth < 570 ? { borderBottom: '1px #D5D9D9 solid', paddingBottom: '10px', width: '96%', marginLeft: 'auto', padding: '10px 0px' } : { borderBottom: '1px #D5D9D9 solid', paddingBottom: '10px', width: '96%', marginLeft: 'auto', display: 'flex', justifyContent: 'space-between', padding: '10px 0px' }}>

                                {
                                    AddressData.length > 0 ?
                                        <div>
                                            <span>{AddressData[AddressData.length - 1].useraddress}</span>
                                        </div>
                                        :
                                        null

                                }
                                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                    <div>
                                        <span onClick={() => { setOpenAddressModal(true) }} className='quantitystyle' style={{ fontSize: '0.8rem', cursor: 'pointer', backgroundColor: '#474343', color: 'white', padding: '3px 5px' }} >
                                            Edit Address
                                        </span>
                                    </div>
                                </div>
                            </div>

                            {

                                AddressData.length == 0 ?
                                    <div style={{ width: '94%', margin: 'auto', display: 'flex', alignItems: 'center', padding: '10px 0px' }}>
                                        <span onClick={() => { setflatAddress(""); setpincode(""); setAreaAddress(""); setLandmark(); setcity(""); setstate(""); setOpenAddressModal(true); }}><AddIcon style={{ color: '#D5D9D9', fontWeight: '600', cursor: "pointer" }} /> </span> <span>Add a new address</span>
                                    </div>
                                    :
                                    null
                            }

                            <div style={{ width: '96%', margin: 'auto', backgroundColor: '#F4A51C', padding: '10px', marginTop: '20px', textAlign: 'center', color: 'black', fontWeight: '700', borderRadius: '4px', cursor: 'pointer' }}
                                onClick={() => { QuotationCheck() }}
                            >
                                Checkout
                            </div>
                        </div>
                    </div>
                    {/* <div >
                        <div >
                            <Input
                                type={"text"}
                                label="Delivery Address "
                                placeholder=" Enter your address"
                                required={true}
                                labelStyle={{ fontWeight: "600" }}
                                value={Address}
                                onChange={(e) => { setAddress(e.target.value) }}
                            />
                        </div>
                        <div style={{ marginTop: '30px', display: 'flex', justifyContent: 'center' }}>
                            <span style={{ backgroundColor: 'Black', padding: '10px 20px', borderRadius: '10px', color: 'white', cursor: 'pointer' }}
                                onClick={() => { SubmitOtherDetails() }}>Submit</span>
                        </div>
                    </div> */}
                </Modal.Body>
            </Modal>


            <Modal id="documentmodal" centered animation={true} show={OpenAddressModal} style={{ backgroundColor: 'rgba(0, 0, 0, 0.9)' }}>
                <Modal.Header style={{ display: 'flex', justifyContent: 'center' }} closeButton onClick={() => { setOpenAddressModal(false); }}>
                    <Modal.Title >Add a new Address</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div style={{ width: '96%', margin: 'auto', height: '600px', overflow: 'scroll' }} className='CartAddress'>
                        <div >
                            <div>

                                <label style={{ fontSize: '0.8rem' }}><b>Country</b><span style={{ color: 'red' }}> *</span></label>
                            </div>
                            <select className="signupinput" onChange={(e) => { setcountrycode(e.target.value); console.log(e.target.value) }}
                                style={{ height: '45px' }} value={countrycode} >
                                <option value="">---Select----</option>
                                {Countrydata.map((s, index) => {
                                    return (
                                        <option value={s.name}>
                                            {s.name}
                                        </option>
                                    );
                                })}
                            </select>
                        </div>

                        {/* <div>
                            <label style={{ fontSize: '0.8rem' }}><b>Country</b><span style={{ color: 'red' }}> *</span></label>
                            <input className='signupinput' type="text" placeholder='' value={flatAddress} onChange={(e) => { setflatAddress(e.target.value) }}></input>
                        </div> */}

                        <div>
                            <label style={{ fontSize: '0.8rem' }}><b>Flat,House no,Company</b><span style={{ color: 'red' }}> *</span></label>
                            <input className='signupinput' type="text" placeholder='' value={flatAddress} onChange={(e) => { setflatAddress(e.target.value) }}></input>
                        </div>

                        <div>
                            <label style={{ fontSize: '0.8rem' }}><b>Pincode</b><span style={{ color: 'red' }}> *</span></label>
                            <input className='signupinput' type="text" placeholder='6 digits [0-9] PIN Code' value={pincode} onChange={(e) => { setpincode(e.target.value) }}></input>
                        </div>

                        <div>
                            <label style={{ fontSize: '0.8rem' }}><b>Area, Street,Sector</b><span style={{ color: 'red' }}> *</span></label>
                            <input className='signupinput' type="text" placeholder='' value={AreaAddress} onChange={(e) => { setAreaAddress(e.target.value) }}></input>
                        </div>

                        <div>
                            <label style={{ fontSize: '0.8rem' }}><b>Landmark</b><span style={{ color: 'red' }}> *</span></label>
                            <input className='signupinput' type="text" placeholder='' value={Landmark} onChange={(e) => { setLandmark(e.target.value) }}></input>
                        </div>

                        <div>
                            <label style={{ fontSize: '0.8rem' }}><b>Town/City</b><span style={{ color: 'red' }}> *</span></label>
                            <input className='signupinput' type="text" placeholder='' value={city} onChange={(e) => { setcity(e.target.value) }}></input>
                        </div>

                        <div>
                            <label style={{ fontSize: '0.8rem' }}><b>State</b><span style={{ color: 'red' }}> *</span></label>
                            {
                                countrycode == "India" ?
                                    <select className="signupinput" onChange={(e) => { setstate(e.target.value); console.log(e.target.value) }}
                                        style={{ height: '45px' }} value={state} >
                                        <option value="">---Select----</option>
                                        {AllStates.map((s, index) => {
                                            return (
                                                <option value={s.name}>
                                                    {s.name}
                                                </option>
                                            );
                                        })}
                                    </select>
                                    :
                                    <input className='signupinput' type="text" placeholder='' value={state} onChange={(e) => { setstate(e.target.value) }}></input>
                            }
                        </div>

                        <div style={{ marginTop: '30px', display: 'flex', justifyContent: 'center' }}>
                            <span style={{ backgroundColor: 'Black', padding: '10px 20px', borderRadius: '10px', color: 'white', cursor: 'pointer' }}
                                onClick={() => { AddUserAddress() }}>Submit</span>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>

            <Modal
                fullscreen={true}
                size="xl"
                scrollable={true}
                // fullscreen
                show={showquotationpdf}
                // onHide={showquotationpdf}
                keyboard={false}
                backdrop="static"
                style={{ backgroundColor: "rgba(0,0,0,0.9)" }}
                centered >
                <Modal.Header closeButton onClick={() => { setshowquotationpdf(false) }}>
                    <Modal.Title>Download PDF</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div style={{ display: 'flex', justifyContent: 'center', height: '98%' }}>
                        <iframe
                            src={quotationpdffile}
                            title="Download pdf"
                            // height="300px"
                            // width="500px"
                            // src={`data:application/pdf;base64,${btoa(String.fromCharCode(...new Uint8Array(this.state.quotationpdffile)))}`}
                            style={{ display: 'flex', justifyContent: 'center', height: '100%', width: '100%' }}
                        ></iframe>
                    </div>
                </Modal.Body>
            </Modal>
            <ToastContainer
            // style={windowidth < 450 ? { width: '200px', fontSize: '0.7rem', } : null}
            />
        </>
    )
}

export default Cart