import React, { Component } from 'react'
import axios from 'axios'
import TimeFormatter from './TimeFormatter'
import FileDownloadIcon from '@mui/icons-material/FileDownload';
export default class ExportFile extends Component {
    constructor(props) {
        super(props)
        this.ExportFile = this.ExportFile.bind(this)
    }

    ExportFile(e) {
        e.preventDefault()
        // console.log(e)
        var fd = new FormData();
        let queryString = this.props.query;
        fd.append("Query", queryString);
        axios({
            mode: "cors",
            method: "POST",
            headers: { "Content-Type": "multipart/form-data" },
            url: this.props.fetchRsUrl,
            data: fd,
        }).then((resp) => {
            console.log(resp.data)
            function setDate(date) {
                var dateObj = TimeFormatter(date)
                var str = dateObj.userSplitedDate[2] + ' ' + dateObj.ShiftMonth + ' ' + dateObj.userSplitedDate[0]
                return str
            }
            let tabledata = []
            var today = new Date();
            var dd = String(today.getDate()).padStart(2, '0');
            var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
            var yyyy = today.getFullYear();
            var hrs = String(today.getHours()).padStart(2, '0')
            var mins = String(today.getMinutes()).padStart(2, '0')
            var newfilename = `Template_${yyyy}${mm}${dd}_${hrs}${mins}`

            var datakeys = Object.keys(resp.data)
            let tableObj = {},
                validations = {}
            for(const key in resp.data){
                console.log(datakeys)
                var arr = []
                if(key != 'fileinfo'){
                    if(key == "validations"){
                        validations[key] = resp.data[key]
                        console.log(resp.data[key])
                    }else{
                        let data = resp.data[key]
                        for(let i=0;i<data.length;i++){
                            let rowKeys = Object.keys(data[i])
                            let obj = {}
                            for(let j=0;j<rowKeys.length;j++){
                                if(data[i][rowKeys[j]] == "null"){
                                    data[i][rowKeys[j]] = ''
                                }
                                if(data[i][rowKeys[j]] != undefined && data[i][rowKeys[j]] != null && data[i][rowKeys[j]].date != undefined && data[i][rowKeys[j]].date != null){
                                    obj[rowKeys[j]] = setDate(data[i][rowKeys[j]]['date'])
                                }else{
                                      obj[rowKeys[j]] = data[i][rowKeys[j]]
                                }
                            }
                            arr.push(obj)
                        }
                       
                        tableObj[key] = arr
                    }
                    
                }
            }
            console.log(tableObj)
            console.log(validations)

            if (document.getElementById('tabledata')) {
                document.getElementById('tabledata').value = JSON.stringify(tableObj)
                document.getElementById('filename').value = newfilename
                document.getElementById('validations').value = JSON.stringify(validations)
                document.getElementById('ExcelExport').click()
            }
        })
    }
    render() {
        return (
            <div>
                <form action={this.props.downloadUrl} method="POST" style={{ display: "none" }}>
                    <input type="hidden" id='tabledata' name='tabledata' />
                    <input type="hidden" id='filename' name='filename' />
                    <input type="hidden" id='validations' name='validations' />
                    <button type="submit" id='ExcelExport' />
                </form>
                <div className="export" onClick={(e) => this.ExportFile(e)}>
                    <FileDownloadIcon style={{height: "20px"}}/>&nbsp;
                    <span>{this.props.name}</span>
                </div>
            </div>
        )
    }
}
