import React, {useState, useEffect} from 'react';
import { useDispatch } from 'react-redux';
import './PrivacyPolicy.css';

const Privacypolicy = () => {

  const dispatch = useDispatch();

  const [windowidth, setwindowwidth] = useState(window.innerWidth);

  useEffect(() => {
    document.title = 'JCB Spare Parts - Earth Moving Equipment Manufacturer | Seetech Parts, Faridabad'
      dispatch({ type: 'SaveRoutes', payload: { route: "termsconditions" } });

      window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
      const handleWindowsize = () => {
          // console.log(window.innerWidth)
          setwindowwidth(window.innerWidth);
        }
    
        window.addEventListener('resize', handleWindowsize);
        return () => {
          window.removeEventListener('resize', handleWindowsize)
        }
  }, [])

  return (
    <>

      <div style={{ width: '90%', height: 'auto', margin: 'auto', marginTop: '1.5rem', marginBottom: '5rem' }}>
        <div style={{ width: '100%', marginTop: '20px' }}>
          <h3 style={{ borderBottom: '2px solid #f9b101' }}>Terms Conditions</h3>
        </div>
        <div>
          <p
            className="s1"
            style={{
              paddingTop: "2pt",
              paddingLeft: "5pt",
              textIndent: "0pt",
              textAlign: "left"
            }}
          >
            <a name="bookmark0">Privacy Policy</a>
          </p>
          <p
            style={{
              paddingTop: "13pt",
              paddingLeft: "5pt",
              textIndent: "0pt",
              textAlign: "left"
            }}
          >
            This legal agreement is an electronic record in terms of the{" "}
            <b>Indian Information Technology Act, 2000 </b>and rules there under as
            applicable and the amended provisions about electronic records in various
            statutes as amended by the <b>Indian Information Technology Act, 2000</b>.
            This electronic record is generated by a computer system and does not
            require any physical or digital signatures.
          </p>
          <p style={{ textIndent: "0pt", textAlign: "left" }}>
            <br />
          </p>
          <p style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "left" }}>
            This legal document is published in accordance with the provisions of{" "}
            <i>
              <b>Rule 3 (1) </b>
            </i>
            of the{" "}
            <b>Indian Information Technology (Intermediaries guidelines) Rules,</b>
          </p>
          <p style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "left" }}>
            <b>2011 </b>and{" "}
            <i>
              <b>Rule 4 </b>
            </i>
            of the
            <b>
              Information Technology (Reasonable security practices and procedures and
              sensitive personal data or information) Rules,
            </b>
          </p>
          <h1 style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "left" }}>
            2011 <span className="p">of </span>Information Technology Act, 2000{" "}
            <span className="p">amended through </span>Information Technology Amendment
            Act, 2008{" "}
            <span className="p">
              that require publishing the practices for access and usage of any
              functional website.
            </span>
          </h1>
          <p style={{ textIndent: "0pt", textAlign: "left" }}>
            <br />
          </p>
          <p style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "left" }}>
            This Website is created and operated by <b>M/s</b>
            <a href="http://www.seetechparts.com/" className="s5" target="_blank">
              Seetech Earthmoving Equipments Private Limited.The creator of this Privacy
              Policy ensures a steady commitment to Your privacy with regard to the
              protection of your invaluable information that you may share across on
              this Website. This privacy policy contains information about the Website{" "}
            </a>
            <a href="http://www.seetechparts.com/" className="a" target="_blank">
              “www.s
            </a>
            <i>
              <b>eetechparts.com” (“Website”).</b>
            </i>
          </p>
          <p style={{ textIndent: "0pt", textAlign: "left" }}>
            <br />
          </p>
          <ol id="l1">
            <li data-list-text={1}>
              <p
                style={{ paddingLeft: "41pt", textIndent: "-18pt", textAlign: "left" }}
              >
                For the purpose of these Privacy Policy (“<b>Privacy Policy</b>”),
                wherever the context so requires, <i>“We”</i>, <i>“Company”, “Our”</i>,
                and
                <i>“Us” </i>shall mean and refer to the Website.
              </p>
            </li>
            <li data-list-text={2}>
              <p
                className="s3"
                style={{ paddingLeft: "41pt", textIndent: "-18pt", textAlign: "left" }}
              >
                “You”<span className="p">,</span>“Your”<span className="p">, </span>
                “Yourself”<span className="p">, </span>“User”{" "}
                <span className="p">
                  shall mean and refer to natural and legal individuals who shall be
                  users of this website provided by us and who is competent to enter
                  into binding contracts, as per Indian laws.
                </span>
              </p>
            </li>
            <li data-list-text={3}>
              <p
                className="s3"
                style={{ paddingLeft: "41pt", textIndent: "-18pt", textAlign: "left" }}
              >
                “Third Parties”{" "}
                <span className="p">
                  refer to any website, platform or individual apart from the Users and
                  the creator of this Website
                </span>
              </p>
            </li>
          </ol>
          <p style={{ textIndent: "0pt", textAlign: "left" }}>
            <br />
          </p>
          <p style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "left" }}>
            To provide You with Our uninterrupted use of the Website, We may collect
            and, in some circumstances, disclose information about you with your
            permission. To ensure better protection of Your privacy, We provide this
            notice explaining Our information collection and disclosure policies, and
            the choices You make about the way Your information is collected and used.
          </p>
          <p style={{ textIndent: "0pt", textAlign: "left" }}>
            <br />
          </p>
          <p style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "left" }}>
            <a href="mailto:info@seetech.in" className="s4" target="_blank">
              This Privacy Policy shall be in compliance with the General Data
              Protection Regulation (GDPR) in effect from May 25, 2018, and any and all
              provisions that may read to the contrary shall be deemed to be void and
              unenforceable as of that date. If you do not agree with the terms and
              conditions of our Privacy Policy, including in relation to the manner of
              collection or use of your information, please do not use or access the
              Site. If you have any questions or concerns regarding this Privacy Policy,
              you should contact our Customer Support Desk at{" "}
            </a>
            <a href="mailto:info@seetech.in" className="a" target="_blank">
              info@seetech.in
            </a>
            <a href="mailto:info@seetech.in" className="s4" target="_blank">
              .
            </a>
          </p>
          <p style={{ textIndent: "0pt", textAlign: "left" }}>
            <br />
          </p>
          <p style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "left" }}>
            ANY CAPITALIZED WORDS USED HENCEFORTH SHALL HAVE THE MEANING ACCORDED TO
            THEM UNDER THIS AGREEMENT. FURTHER, ALL HEADING USED
          </p>
          <p
            style={{
              paddingTop: "3pt",
              paddingLeft: "5pt",
              textIndent: "0pt",
              textAlign: "left"
            }}
          >
            HEREIN ARE ONLY FOR THE PURPOSE OF ARRANGING THE VARIOUS PROVISIONS OF THE
            AGREEMENT IN ANY MANNER. NEITHER THE USER NOR THE CREATORS OF THIS PRIVACY
            POLICY MAY USE THE HEADING TO INTERPRET THE PROVISIONS CONTAINED WITHIN IT
            IN ANY MANNER.
          </p>
          <p style={{ textIndent: "0pt", textAlign: "left" }}>
            <br />
          </p>
          <h1 style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "left" }}>
            INFORMATION WE COLLECT
          </h1>
          <p style={{ textIndent: "0pt", textAlign: "left" }}>
            <br />
          </p>
          <p style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "left" }}>
            We are committed to respecting Your online privacy. We further recognize
            Your need for appropriate protection and management of any Personal
            Information You share with us. We may collect the following information:
          </p>
          <p style={{ textIndent: "0pt", textAlign: "left" }}>
            <br />
          </p>
          <ol id="l2">
            <li data-list-text={1}>
              <p
                style={{ paddingLeft: "41pt", textIndent: "-18pt", textAlign: "left" }}
              >
                Registration data, such as full name, date of birth, contact number,
                email Id, user Id, Password, residence / place of business information,
                billing information, shipping information, bank account details and
                other personal information as indicated on the forms throughout the
                website and any other required personal information which we may ask on
                the website.
              </p>
            </li>
            <li data-list-text={2}>
              <p
                style={{ paddingLeft: "41pt", textIndent: "-18pt", textAlign: "left" }}
              >
                Tracking Information such as, but not limited to the IP address of your
                device and Device ID when connected to the Internet. This information
                may include the URL that you just came from (whether this URL is on the
                Website or not), which URL you next go to (whether this URL is on the
                Website or not), your computer or device browser information, and other
                information associated with your interaction with the Website;
              </p>
            </li>
          </ol>
          <p style={{ textIndent: "0pt", textAlign: "left" }}>
            <br />
          </p>
          <p style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "left" }}>
            This privacy policy also applies to data we collect from users who are not
            registered as members of this Website, including, but not limited to,
            browsing behaviour, pages viewed etc. We also collect and store personal
            information provided by You from time to time on the Website. We only
            collect and use such information from you that we consider necessary for
            achieving a seamless, efficient and safe experience, customized to your
            needs including:
          </p>
          <p style={{ textIndent: "0pt", textAlign: "left" }}>
            <br />
          </p>
          <ol id="l3">
            <li data-list-text={1}>
              <p
                style={{ paddingLeft: "41pt", textIndent: "-18pt", textAlign: "left" }}
              >
                To enable the provision of services opted for by you;
              </p>
            </li>
            <li data-list-text={2}>
              <p
                style={{
                  paddingLeft: "41pt",
                  textIndent: "-18pt",
                  lineHeight: "14pt",
                  textAlign: "left"
                }}
              >
                To enable the viewing of content in your interest;
              </p>
            </li>
            <li data-list-text={3}>
              <p
                style={{ paddingLeft: "41pt", textIndent: "-18pt", textAlign: "left" }}
              >
                To communicate the necessary account and service-related information
                from time to time;
              </p>
            </li>
            <li data-list-text={4}>
              <p
                style={{
                  paddingLeft: "41pt",
                  textIndent: "-18pt",
                  lineHeight: "14pt",
                  textAlign: "left"
                }}
              >
                To allow you to receive quality customer care services and data
                Collection;
              </p>
            </li>
            <li data-list-text={5}>
              <p
                style={{ paddingLeft: "41pt", textIndent: "-18pt", textAlign: "left" }}
              >
                To comply with applicable laws, rules and regulations;
              </p>
            </li>
          </ol>
          <p style={{ textIndent: "0pt", textAlign: "left" }}>
            <br />
          </p>
          <p style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "left" }}>
            Where any service requested by You involves a third party, such information
            as is reasonably necessary by the Company to carry out Your service request
            may be shared with such third party. We also do use your contact information
            to send you offers based on your interests and prior activity and also to
            view the content preferred by you. The Company may also use contact
            information internally to direct its efforts for service improvement but
            shall immediately delete all such information upon withdrawal of your
            consent for the same through the ‘<b>unsubscribe</b>
            <a href="mailto:toinfo@seetech.in" className="s5" target="_blank">
              ’ button or through an email to be sent to
            </a>
            <a href="mailto:toinfo@seetech.in" className="a" target="_blank">
              info@seetech.in
            </a>
            <a href="mailto:toinfo@seetech.in" className="s5" target="_blank">
              .
            </a>
          </p>
          <p style={{ textIndent: "0pt", textAlign: "left" }}>
            <br />
          </p>
          <p style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "left" }}>
            To the extent possible, we provide You with the option of not divulging any
            specific information that you wish for us not to collect, store or use. You
            may also choose not to use a particular service or feature on the Website
            and opt-out of any non-essential communications from the Website.
          </p>
          <p
            style={{
              paddingTop: "3pt",
              paddingLeft: "5pt",
              textIndent: "0pt",
              textAlign: "left"
            }}
          >
            Further, transacting over the internet has inherent risks which can only be
            avoided by you following security practices yourself, such as not revealing
            account/login related information to any other person and informing our
            customer care team about any suspicious activity or where your account
            has/may have been compromised.
          </p>
          <p style={{ textIndent: "0pt", textAlign: "left" }}>
            <br />
          </p>
          <h1 style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "left" }}>
            OUR USE OF YOUR INFORMATION
          </h1>
          <p style={{ textIndent: "0pt", textAlign: "left" }}>
            <br />
          </p>
          <p style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "left" }}>
            The information provided by you shall be used to provide and improve the
            service for you and all users.
          </p>
          <p style={{ textIndent: "0pt", textAlign: "left" }}>
            <br />
          </p>
          <ol id="l4">
            <li data-list-text={1}>
              <p
                style={{ paddingLeft: "41pt", textIndent: "-18pt", textAlign: "left" }}
              >
                To provide you with services on your request.
              </p>
            </li>
            <li data-list-text={2}>
              <p
                style={{
                  paddingLeft: "41pt",
                  textIndent: "-18pt",
                  lineHeight: "14pt",
                  textAlign: "left"
                }}
              >
                For maintaining an internal record.
              </p>
            </li>
            <li data-list-text={3}>
              <p
                style={{
                  paddingLeft: "41pt",
                  textIndent: "-18pt",
                  lineHeight: "14pt",
                  textAlign: "left"
                }}
              >
                For enhancing the Services provided.
              </p>
            </li>
            <li data-list-text={4}>
              <p
                style={{ paddingLeft: "41pt", textIndent: "-18pt", textAlign: "left" }}
              >
                For maintaining record under the legal and statutory provisions.
              </p>
            </li>
          </ol>
          <p style={{ textIndent: "0pt", textAlign: "left" }}>
            <br />
          </p>
          <p style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "left" }}>
            Further, your data and Sensitive Personal data may be collected and stored
            by Us for internal record.
          </p>
          <p style={{ textIndent: "0pt", textAlign: "left" }}>
            <br />
          </p>
          <p style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "left" }}>
            We use Your tracking information such as IP addresses, and or Device ID to
            help identify you and to gather broad demographic information and make
            further services available to you.
          </p>
          <p style={{ textIndent: "0pt", textAlign: "left" }}>
            <br />
          </p>
          <p style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "left" }}>
            We will not sell, license or trade Your personal information. We will not
            share your personal information with others unless they are acting under our
            instructions or we are required to do so by law.
          </p>
          <p style={{ textIndent: "0pt", textAlign: "left" }}>
            <br />
          </p>
          <p style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "left" }}>
            Information collected via Our server logs includes users’ IP addresses and
            the pages visited; this will be used to manage the web system and
            troubleshoot problems. We also use third-party analytics, tracking,
            optimization and targeting tools to understand how users engage with our
            Website so that we can improve it and cater personalized content/ads
            according to their preferences.
          </p>
          <p style={{ textIndent: "0pt", textAlign: "left" }}>
            <br />
          </p>
          <h1 style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "left" }}>
            HOW INFORMATION IS COLLECTED
          </h1>
          <p style={{ textIndent: "0pt", textAlign: "left" }}>
            <br />
          </p>
          <p style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "left" }}>
            Before or at the time of collecting personal information, we will identify
            the purposes for which information is being collected. If the same is not
            identified to you, you have the right to request the Companyto elucidate the
            purpose of collection of said personal information, pending the fulfilment
            of which you shall not be mandated to disclose any information whatsoever.
          </p>
          <p style={{ textIndent: "0pt", textAlign: "left" }}>
            <br />
          </p>
          <p style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "left" }}>
            We will collect and use your personal information solely to fulfil those
            purposes specified by us, within the scope of the consent of the individual
            concerned or as required by law. We will only retain personal information as
            long as necessary for the fulfilment of those purposes. We will collect
            personal information by lawful and fair means and with the knowledge and
            consent of the individual concerned.
          </p>
          <p style={{ textIndent: "0pt", textAlign: "left" }}>
            <br />
          </p>
          <p style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "justify" }}>
            Personal data should be relevant to the purposes for which it is to be used,
            and, to the extent necessary for those purposes, should be accurate,
            complete, and up-to-date.
          </p>
          <h1
            style={{
              paddingTop: "3pt",
              paddingLeft: "5pt",
              textIndent: "0pt",
              textAlign: "left"
            }}
          >
            COOKIES
          </h1>
          <p style={{ textIndent: "0pt", textAlign: "left" }}>
            <br />
          </p>
          <p style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "left" }}>
            A cookie is a small file of letters and numbers that we store on your
            browser or the hard drive of your computer if you agree. By continuing to
            browse the site, you are agreeing to our use of cookies. Cookies contain
            information that is transferred to your computer’s hard drive. You can set
            your browser to refuse all or some browser cookies, or to alert you when
            websites set or access cookies. If you disable or refuse cookies, please
            note that some parts of this website may become inaccessible or not function
            properly. A list of the type of cookies we use is as follows;
          </p>
          <p style={{ textIndent: "0pt", textAlign: "left" }}>
            <br />
          </p>
          <ol id="l5">
            <li data-list-text={1}>
              <h1
                style={{ paddingLeft: "41pt", textIndent: "-18pt", textAlign: "left" }}
              >
                Strictly necessary cookies.{" "}
                <span className="p">
                  These are cookies that are required for the operation of our website.
                  They include, for example, cookies that enable you to log into secure
                  areas of our website, use a shopping cart or make use of e-billing
                  services.
                </span>
              </h1>
              <ol id="l6">
                <li data-list-text={1}>
                  <h1
                    style={{
                      paddingLeft: "77pt",
                      textIndent: "-18pt",
                      textAlign: "left"
                    }}
                  >
                    Analytical/performance cookies.
                    <span className="p">
                      They allow us to recognize and count the number of visitors and to
                      see how visitors move around our website when they are using it.
                      This helps us to improve the way our website works, for example,
                      by ensuring that users are finding what they are looking for
                      easily.
                    </span>
                  </h1>
                </li>
                <li data-list-text={2}>
                  <h1
                    style={{
                      paddingLeft: "77pt",
                      textIndent: "-18pt",
                      textAlign: "left"
                    }}
                  >
                    Functionality cookies.{" "}
                    <span className="p">
                      These are used to recognize you when you return to our website.
                      This enables us to personalize our content for you, greet you by
                      name and remember your preferences (for example, your choice of
                      language or region).
                    </span>
                  </h1>
                </li>
                <li data-list-text={3}>
                  <h1
                    style={{
                      paddingLeft: "77pt",
                      textIndent: "-18pt",
                      textAlign: "left"
                    }}
                  >
                    Targeting cookies.{" "}
                    <span className="p">
                      These cookies record your visit to our website, the pages you have
                      visited and the links you have followed. We will use this
                      information to make our website and the advertising displayed on
                      it more relevant to your interests. We may also share this
                      information with third-parties for this purpose.
                    </span>
                  </h1>
                </li>
              </ol>
            </li>
          </ol>
          <p style={{ textIndent: "0pt", textAlign: "left" }}>
            <br />
          </p>
          <p style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "left" }}>
            Please note that third-parties (including, for example, advertising networks
            and providers of external services like web traffic analysis services) may
            also use cookies, over which we have no control. These cookies are likely to
            be analytical/performance cookies or targeting cookies. You can block
            cookies by activating the setting on your browser that allows you to refuse
            the setting of all or some cookies. However, if you use your browser
            settings to block all cookies (including essential cookies) you may not be
            able to access all or parts of our website.
          </p>
          <p style={{ textIndent: "0pt", textAlign: "left" }}>
            <br />
          </p>
          <h1 style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "left" }}>
            GOOGLE ANALYTICS
          </h1>
          <p style={{ textIndent: "0pt", textAlign: "left" }}>
            <br />
          </p>
          <p style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "left" }}>
            We use Google Analytics to help us to understand how you make use of our
            content and work out how we can make things better. These cookies may follow
            all visitors of the Platform and collect anonymous data on where they have
            come from, which pages they visit, and how long they spend on the site. This
            data is then stored by Google to create reports. These cookies do not store
            personal data or business data. The information generated by the cookie
            about the use of the Website, including IP address, may be transmitted to,
            and stored by Google on servers in the United
          </p>
          <p style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "left" }}>
            States. Google may use this information to evaluate the visitor’s use of the
            website, compiling reports on website activity for us and providing other
            services relating to website activity and internet usage. Google may also
            transfer this information to third
          </p>
          <p
            style={{
              paddingTop: "3pt",
              paddingLeft: "5pt",
              textIndent: "0pt",
              lineHeight: "14pt",
              textAlign: "left"
            }}
          >
            parties where required to do so by law, or where such third parties process
            the
          </p>
          <p style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "left" }}>
            information on Google’s behalf. Google will not associate any IP address’
            with any other data held by Google. By using this website, all visitor’s
            consent to the processing of data by Google in the manner and for the
            purposes set out above. The Google website contains further information
            about Analytics and a copy of
          </p>
          <p style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "left" }}>
            Google’s privacy policy pages.
          </p>
          <p style={{ textIndent: "0pt", textAlign: "left" }}>
            <br />
          </p>
          <h1 style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "left" }}>
            EXTERNAL LINKS ON THE WEBSITE
          </h1>
          <p style={{ textIndent: "0pt", textAlign: "left" }}>
            <br />
          </p>
          <p style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "left" }}>
            The Website may include advertisements, hyperlinks to other Websites or
            resources including developer, PDF, Woocommerce, WordPress, etc. We have no
            control over any other website or resources or contents available on these
            other websites, which are provided by companies or persons other than Us.
            You acknowledge and agree that we are not responsible for the availability
            of any such external sites or resources, and do not endorse any advertising,
            services or other materials on or available from such Website or resources.
            You acknowledge and agree that We are not liable for any loss or damage
            which may be incurred by you as a result of the availability of those
            external sites or resources, or as a result of any reliance placed by you on
            the completeness, accuracy or existence of any advertising, services or
            other materials on, or available from, such Websites. These external
            third-party websites and resource providers may have their privacy policies
            governing the collection, storage, retention and disclosure of Your Personal
            Information that You may be subject to. We recommend that you enter the
            Website and review their privacy policy.
          </p>
          <p style={{ textIndent: "0pt", textAlign: "left" }}>
            <br />
          </p>
          <h1 style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "left" }}>
            YOUR RIGHTS
          </h1>
          <p style={{ textIndent: "0pt", textAlign: "left" }}>
            <br />
          </p>
          <p
            style={{
              paddingLeft: "23pt",
              textIndent: "-18pt",
              lineHeight: "202%",
              textAlign: "left"
            }}
          >
            Unless subject to an exemption, you have the following rights concerning
            your data: 1.
          </p>
          <p style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "left" }}>
            Where you hold an account with any of our services, you are entitled to a
            copy of all personal data which we hold concerning you. You are also
            entitled to request that we restrict how we use your data in your account
            when you log in.
          </p>
          <p style={{ textIndent: "0pt", textAlign: "left" }}>
            <br />
          </p>
          <h1 style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "left" }}>
            CONFIDENTIALITY
          </h1>
          <p style={{ textIndent: "0pt", textAlign: "left" }}>
            <br />
          </p>
          <p style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "left" }}>
            You further acknowledge that the Website may contain information that is
            designated confidential by us and that you shall not disclose such
            information without our prior written consent. Your information is regarded
            as confidential and therefore will not be divulged to any third party,
            unless if legally required to do so to the appropriate authorities. We will
            not sell, share, or rent your personal information to any third party or use
            your e-mail address for unsolicited mail. Any emails sent by us will only be
            in connection with the provision of agreed services, and you retain sole
            discretion to seek for discontinuation of such communications at any point
            in time.
          </p>
          <p style={{ textIndent: "0pt", textAlign: "left" }}>
            <br />
          </p>
          <h1 style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "left" }}>
            OTHER INFORMATION COLLECTORS
          </h1>
          <p style={{ textIndent: "0pt", textAlign: "left" }}>
            <br />
          </p>
          <p style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "left" }}>
            Except as otherwise expressly included in this Privacy Policy, this document
            only addresses the use and disclosure of information we collect from you. To
            the extent
          </p>
          <p
            style={{
              paddingTop: "3pt",
              paddingLeft: "5pt",
              textIndent: "0pt",
              textAlign: "left"
            }}
          >
            that you disclose your information to other parties, whether they are on our
            Website or other sites throughout the Internet, different rules may apply to
            their use or disclosure of the information you disclose to them. To the
            extent that we use third party advertisers, they adhere to their privacy
            policies. Since we do not control the privacy policies of the third parties,
            you are subject to ask questions before you disclose your personal
            information to others.
          </p>
          <p style={{ textIndent: "0pt", textAlign: "left" }}>
            <br />
          </p>
          <h1 style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "left" }}>
            OUR DISCLOSURE OF YOUR INFORMATION
          </h1>
          <p style={{ textIndent: "0pt", textAlign: "left" }}>
            <br />
          </p>
          <p style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "left" }}>
            We may host surveys for survey creators for our Website who are the owners
            and users of your survey responses. We do not own or sell your responses.
            Anything you expressly disclose in your responses will be disclosed to
            survey creators. Please contact the survey creator directly to better
            understand how they might share your survey responses.
          </p>
          <p style={{ textIndent: "0pt", textAlign: "left" }}>
            <br />
          </p>
          <p style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "justify" }}>
            Information collected will not be considered as sensitive if it is freely
            available and accessible in the public domain or is furnished under the
            Right to Information Act, 2005, any rules made thereunder or any other law
            for the time being in force.
          </p>
          <p style={{ textIndent: "0pt", textAlign: "left" }}>
            <br />
          </p>
          <p style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "left" }}>
            Due to the existing regulatory environment, we cannot ensure that all of
            your private communications and other personally identifiable information
            will never be disclosed in ways not otherwise described in this Privacy
            Policy. By way of example (without limiting and foregoing), we may be forced
            to disclose information to the government, law enforcement agencies or third
            parties. Therefore, although we use industry-standard practices to protect
            your privacy, we do not promise, and you should not expect, that your
            personally identifiable information or private communications would always
            remain private. We do however assure you that any disclosure of your
            personally identifiable information shall be personally intimated to you
            through an email sent to your provided email address.
          </p>
          <p style={{ textIndent: "0pt", textAlign: "left" }}>
            <br />
          </p>
          <p style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "left" }}>
            As a matter of policy, we do not sell or rent any personally identifiable
            information about you to any third party. However, the following describes
            some of the ways that your personally identifiable information may be
            disclosed:
          </p>
          <p style={{ textIndent: "0pt", textAlign: "left" }}>
            <br />
          </p>
          <ol id="l7">
            <li data-list-text={1}>
              <p
                style={{ paddingLeft: "41pt", textIndent: "-18pt", textAlign: "left" }}
              >
                External Service Providers: There may be several services offered by
                external service providers that help you use our Website. If you choose
                to use these optional services, and in the course of doing so, disclose
                information to the external service providers, and/or permit them to
                collect information about you, then their use of your information is
                governed by their privacy policy.
              </p>
              <ol id="l8">
                <li data-list-text={1}>
                  <p
                    style={{
                      paddingLeft: "77pt",
                      textIndent: "-18pt",
                      textAlign: "left"
                    }}
                  >
                    Law and Order: We cooperate with law enforcement inquiries, as well
                    as other third parties to enforce laws, such as intellectual
                    property rights, fraud and other rights. We can (and you authorize
                    us to) disclose any information about you to law enforcement and
                    other government officials as we, in our sole discretion, believe
                    necessary or appropriate, in connection with an investigation of
                    fraud, intellectual property infringements, or other activity that
                    is illegal or may expose us or you to legal liability.
                  </p>
                </li>
              </ol>
            </li>
          </ol>
          <p style={{ textIndent: "0pt", textAlign: "left" }}>
            <br />
          </p>
          <h1 style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "justify" }}>
            ACCESSING, REVIEWING AND CHANGING YOUR PROFILE
          </h1>
          <p
            style={{
              paddingTop: "3pt",
              paddingLeft: "5pt",
              textIndent: "0pt",
              textAlign: "left"
            }}
          >
            Following registration, you can review and change the information you
            submitted at the stage of registration, except Email ID and mobile number.
            An option for facilitating such change shall be present on the Website and
            such change shall be facilitated by the User. If you change any information,
            we may or may not keep track of your old information. We will not retain in
            our files information you have requested to remove for certain
            circumstances, such as to resolve disputes, troubleshoot problems and
            enforce our terms and conditions. Such prior information shall be completely
            removed from our databases, including stored ‘back up’ systems. If you
            believe that any information, we are holding on to you is incorrect or
            incomplete, or to remove your profile so that others cannot view it, the
            User needs to remediate, and promptly correct any such incorrect
            information.
          </p>
          <p style={{ textIndent: "0pt", textAlign: "left" }}>
            <br />
          </p>
          <h1 style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "left" }}>
            PERMISSION SOUGHT FROM DEVICE
          </h1>
          <p style={{ textIndent: "0pt", textAlign: "left" }}>
            <br />
          </p>
          <p style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "left" }}>
            The website requires the information related to Browser, Internet, Antivirus
            from the device.
          </p>
          <p style={{ textIndent: "0pt", textAlign: "left" }}>
            <br />
          </p>
          <h1 style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "left" }}>
            SECURITY
          </h1>
          <p style={{ textIndent: "0pt", textAlign: "left" }}>
            <br />
          </p>
          <p style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "left" }}>
            We treat data as an asset that must be protected against loss and
            unauthorized access. We employ many different security techniques to protect
            such data from unauthorized access by members inside and outside the
            Company. We follow generally accepted industry standards to protect the
            Personal Information submitted to us and information that we have accessed.
          </p>
          <p style={{ textIndent: "0pt", textAlign: "left" }}>
            <br />
          </p>
          <p style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "left" }}>
            However, as effective as encryption technology is, no security system is
            impenetrable. Our Companycannot guarantee the security of our database, nor
            can we guarantee that information you provide won’t be intercepted while
            being transmitted to the Companyover the Internet.
          </p>
          <p style={{ textIndent: "0pt", textAlign: "left" }}>
            <br />
          </p>
          <h1 style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "left" }}>
            SEVERABILITY
          </h1>
          <p style={{ textIndent: "0pt", textAlign: "left" }}>
            <br />
          </p>
          <p style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "left" }}>
            Each paragraph of this Privacy Policy shall be and remain separate from and
            independent of and severable from all and any other paragraphs herein except
            where otherwise expressly indicated or indicated by the context of the
            agreement. The decision or declaration that one or more of the paragraphs
            are null and void shall not affect the remaining paragraphs of this privacy
            policy.
          </p>
          <p style={{ textIndent: "0pt", textAlign: "left" }}>
            <br />
          </p>
          <h1 style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "left" }}>
            AMENDMENT
          </h1>
          <p style={{ textIndent: "0pt", textAlign: "left" }}>
            <br />
          </p>
          <p style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "left" }}>
            Our Privacy Policy may change from time to time. The most current version of
            the policy will govern our use of your information and will always be on the
            Website. Any amendments to this Policy shall be deemed as accepted by the
            User on their continued use of the Website.
          </p>
          <p style={{ textIndent: "0pt", textAlign: "left" }}>
            <br />
          </p>
          <h1 style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "left" }}>
            CONSENT WITHDRAWAL, DATA DOWNLOAD &amp; DATA REMOVAL REQUESTS
          </h1>
          <p style={{ textIndent: "0pt", textAlign: "left" }}>
            <br />
          </p>
          <p style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "left" }}>
            <a href="mailto:info@seetech.in" className="s5" target="_blank">
              To withdraw your consent, or to request the download or delete your data
              with us for any or all our services at any time, please email to{" "}
            </a>
            <a href="mailto:info@seetech.in" target="_blank">
              info@seetech.in
            </a>
          </p>
          <h1
            style={{
              paddingTop: "3pt",
              paddingLeft: "5pt",
              textIndent: "0pt",
              textAlign: "left"
            }}
          >
            CONTACT US
          </h1>
          <p style={{ textIndent: "0pt", textAlign: "left" }}>
            <br />
          </p>
          <p style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "left" }}>
            <a href="mailto:info@seetech.in" className="s5" target="_blank">
              If you have any questions or concerns regarding this privacy policy, you
              should contact us by sending an e-mail to{" "}
            </a>
            <a href="mailto:info@seetech.in" target="_blank">
              info@seetech.in.
            </a>
          </p>


        </div>
      </div>


    
    </>
  )
}



export default Privacypolicy