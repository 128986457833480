import React, { useEffect, useState } from 'react'
import "./TermsCond.css";
import { useDispatch } from 'react-redux';

const TermsCond = () => {
    const dispatch = useDispatch();
    const [windowidth, setwindowwidth] = useState(window.innerWidth);

    useEffect(() => {
        document.title = 'JCB Spare Parts - Earth Moving Equipment Manufacturer | Seetech Parts, Faridabad'
        dispatch({ type: 'SaveRoutes', payload: { route: "termsconditions" } });

        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
        const handleWindowsize = () => {
            // console.log(window.innerWidth)
            setwindowwidth(window.innerWidth);
          }
      
          window.addEventListener('resize', handleWindowsize);
          return () => {
            window.removeEventListener('resize', handleWindowsize)
          }
    }, [])

    return (
        <>
            <div style={{ width: '90%', height: 'auto', margin: 'auto', marginTop: '1.5rem', marginBottom: '5rem' }}>
                <div style={{ width: '100%', marginTop: '20px' }}>
                    <h3 style={{ borderBottom: '2px solid #f9b101' }}>Terms & Conditions</h3>
                </div>
                <h1 style={{ paddingTop: '13pt', paddingLeft: '5pt', textIndent: '0pt', textAlign: 'left' }}>TERMS OF USE</h1>
                <p style={{ textIndent: '0pt', textAlign: 'left' }}><br /></p>
                <ol id="l1">
                    <li data-list-text={1.}>
                        <h1 style={{ paddingTop: '4pt', paddingLeft: '41pt', textAlign: 'justify' }}>General Introduction
                        </h1>
                        <ol id="l2">
                            <li data-list-text={1.}>
                                <p style={{ paddingLeft: '77pt', textAlign: 'justify' }}>Any use of the website Seetech
                                    parts.com (hereinafter referred to as the “Seetech Website”), owned and operated by Seetech
                                    Earthmoving Equipments Private Limited (hereinafter referred to as Seetech ) is subject to these
                                    Terms of Use.</p>
                            </li>
                            <li data-list-text={2.}>
                                <p style={{ paddingLeft: '77pt', textAlign: 'justify' }}>For the purposes of these
                                    Terms of Use, the term ‘User’ shall mean any person registering/ downloading or using/browsing
                                    the Seetech Website. The term ‘User’ shall mean any person using the Seetech Website.</p>
                            </li>
                            <li data-list-text={3.}>
                                <p style={{ paddingLeft: '77pt', textAlign: 'justify' }}>These Terms of Use are
                                    provided in an electronic format as prescribed under the Information Technology Act, 2000, the
                                    Rules made there under and the amendments regarding documents preserved in electronic format.
                                    These Terms of Use will govern the use of Seetech Website by the User(s).</p>
                            </li>
                            <li data-list-text={4.}>
                                <p style={{ paddingLeft: '77pt', textAlign: 'justify' }}>Downloading, installing,
                                    browsing or otherwise using the Seetech Website signifies acceptance of these Terms of Use as
                                    well as formal agreement to be legally bound by the same.</p>
                            </li>
                            <li data-list-text={5.}>
                                <p style={{ paddingLeft: '77pt', textAlign: 'justify' }}>These Terms of Use constitute
                                    the comprehensive terms and conditions for the use of the Seetech Website. Any non-compliance
                                    with these Terms of Use may lead to disqualification from using the Seetech Website.</p>
                            </li>
                            <li data-list-text={6.}>
                                <p style={{ paddingLeft: '77pt', textAlign: 'justify' }}>All rights to alter these
                                    Terms of Use are reserved as it may deem fit in its sole and absolute discretion without any
                                    obligation of issuing prior notification. Any major change or revision in these Terms of Use
                                    will be communicated to the User along with the date when the modification was made.</p>
                            </li>
                            <li data-list-text={7.}>
                                <p style={{ paddingLeft: '77pt', textAlign: 'justify' }}>This document is an electronic
                                    record generated by a computer system and does not require any physical or digital signatures.
                                </p>
                            </li>
                            <li data-list-text={8.}>
                                <p style={{ paddingLeft: '77pt', textAlign: 'justify' }}>Any changes made to these
                                    Terms of Use will be effective immediately and any continued use of the Seetech Website after
                                    such change constitutes acceptance of such changes.</p>
                            </li>
                            <li data-list-text={9.}>
                                <p style={{ paddingLeft: '77pt', textAlign: 'justify' }}>In order to use the Seetech
                                    Website, agreement to these Terms of Use is the first step. The Terms of Use can be accepted by
                                    simply clicking on the space provided adjacent to “I Agree to Terms &amp; Conditions”.</p>
                            </li>
                            <li data-list-text={10.}>
                                <p style={{ paddingLeft: '77pt', textAlign: 'justify' }}>The Seetech Website must not
                                    be used in any manner or case where there is non-acceptance of these Terms of Use or inability
                                    to be bound by them.</p>
                            </li>
                        </ol>
                    </li>
                </ol>
                <p style={{ textIndent: '0pt', textAlign: 'left' }}><br /></p>
                <ul id="l3">
                    <li data-list-text="o">
                        <h1 style={{ paddingLeft: '41pt', lineHeight: '14pt', textAlign: 'justify' }}>Registration
                            process</h1>
                        <ul id="l4">
                            <li data-list-text="o">
                                <p style={{ paddingLeft: '77pt', textAlign: 'justify' }}>The Seetech Website also
                                    permits the Users to register on the Seetech Website after furnishing all relevant information
                                    as sought. The User who chooses to register on the Seetech Website and provide the requisite
                                    details, shall be responsible for maintaining the confidentiality of its username and password.
                                </p>
                            </li>
                            <li data-list-text="o">
                                <p style={{ paddingLeft: '77pt', textAlign: 'justify' }}>On successful registration,
                                    the User will be able to access his/her own account by using the username and password chosen
                                    during the</p>
                                <p style={{ paddingTop: '4pt', paddingLeft: '77pt', textIndent: '0pt', textAlign: 'justify' }}>registration
                                    process. The User(s) shall be responsible for all activities that occur through his/her username
                                    and password.</p>
                            </li>
                            <li data-list-text="o">
                                <p style={{ paddingLeft: '77pt', lineHeight: '14pt', textAlign: 'justify' }}>Further, the
                                    User agrees, inter alia, to:</p>
                                <ul id="l5">
                                    <li data-list-text="o">
                                        <p style={{ paddingLeft: '113pt', textAlign: 'justify' }}>Provide true,
                                            accurate, current and complete information as prompted by the Seetech Website
                                            registration form while registering (such information being the “Registration Data”);
                                        </p>
                                    </li>
                                    <li data-list-text="o">
                                        <p style={{ paddingLeft: '113pt', textAlign: 'justify' }}>Maintain and promptly
                                            update the Registration Data to keep it true, accurate, current and complete at all
                                            times;</p>
                                    </li>
                                    <li data-list-text="o">
                                        <p style={{ paddingLeft: '113pt', textAlign: 'justify' }}>Immediately notifying
                                            the Seetech Website of any unauthorized use of password or account or any other actual
                                            or suspected breach of security; and</p>
                                    </li>
                                    <li data-list-text="o">
                                        <p style={{ paddingLeft: '113pt', lineHeight: '14pt', textAlign: 'justify' }}>
                                            Ensure logging out from account at the end of each session.</p>
                                    </li>
                                </ul>
                            </li>
                            <li data-list-text="o">
                                <p classname="s2" style={{ paddingLeft: '77pt', textAlign: 'justify' }}> <span classname="p">For the purposes of these Terms of Use, the term ‘Personally Identifiable
                                    Information’ or ‘PII’ shall mean and include any information or set of information, whether
                                    alone or in combination with other personal information gathered, processed, used or stored
                                    which is sufficient to identify an individual.</span></p>
                            </li>
                            <li data-list-text="o">
                                <p style={{ paddingLeft: '77pt', textAlign: 'justify' }}>In relation to any PII being
                                    saved or processed, shall meet the requirements of Information Technology Law and shall adopt
                                    the technical and organizational measures as industry standards which are necessary to secure
                                    the PII of the User.</p>
                            </li>
                            <li data-list-text="o">
                                <p style={{ paddingLeft: '77pt', textAlign: 'justify' }}>As the first step of the
                                    Registration process, the Seetech Website may require the following PII to be provided,
                                    including without limitation to:</p>
                            </li>
                        </ul>
                    </li>
                    <li data-list-text="o">
                        <p style={{ paddingLeft: '41pt', lineHeight: '14pt', textAlign: 'left' }}>Name (First and Last
                            name);</p>
                    </li>
                    <li data-list-text="o">
                        <p style={{ paddingLeft: '41pt', lineHeight: '14pt', textAlign: 'left' }}>Email address;</p>
                    </li>
                    <li data-list-text="o">
                        <p style={{ paddingLeft: '41pt', lineHeight: '14pt', textAlign: 'left' }}>Mobile number;</p>
                    </li>
                    <li data-list-text="o">
                        <p style={{ paddingLeft: '41pt', lineHeight: '14pt', textAlign: 'left' }}>Username;</p>
                    </li>
                    <li data-list-text="o">
                        <p style={{ paddingLeft: '41pt', lineHeight: '14pt', textAlign: 'left' }}>Address;</p>
                    </li>
                    <li data-list-text="o">
                        <p style={{ paddingLeft: '41pt', lineHeight: '14pt', textAlign: 'left' }}>Country;</p>
                    </li>
                    <li data-list-text="o">
                        <p style={{ paddingLeft: '41pt', lineHeight: '14pt', textAlign: 'left' }}>Province state;</p>
                    </li>
                    <li data-list-text="o">
                        <p style={{ paddingLeft: '41pt', lineHeight: '14pt', textAlign: 'left' }}>City;</p>
                    </li>
                    <li data-list-text="o">
                        <p style={{ paddingLeft: '41pt', lineHeight: '14pt', textAlign: 'left' }}>Company Name;</p>
                    </li>
                    <li data-list-text="o">
                        <p style={{ paddingLeft: '41pt', lineHeight: '14pt', textAlign: 'left' }}>Company Address;</p>
                        <p style={{ textIndent: '0pt', textAlign: 'left' }}><br /></p>
                        <p style={{ paddingLeft: '5pt', textIndent: '0pt', textAlign: 'left' }}>Upon collection, the above-mentioned
                            information shall be subject to the Privacy Policy of Seetech .</p>
                        <p style={{ textIndent: '0pt', textAlign: 'left' }}><br /></p>
                    </li>
                    <li data-list-text="o">
                        <p style={{ paddingLeft: '41pt', textAlign: 'justify' }}>The User(s) shall be individually
                            responsible for the accuracy and correctness of all such details/information provided during
                            registration on the Seetech Website. If there is a reason to doubt the correctness of any
                            details/information furnished or in case any information furnished is found to be incorrect, false or
                            misleading in nature or otherwise not in accordance with these Terms of Use, then the account of the
                            User(s) may be subject to cancellation or indefinite suspension or access to the same may be blocked/
                            refused.</p>
                        <ul id="l6">
                            <li data-list-text="o">
                                <p style={{ paddingLeft: '77pt', textAlign: 'justify' }}>Seetech shall not be liable
                                    for any loss or damage arising from failure of the User(s) to comply with these Terms of Use.
                                    Seetech shall not be liable for any unauthorized hacking of the Seetech Website leading to
                                    leakage and misuse of PII.</p>
                            </li>
                            <li data-list-text="o">
                                <p style={{ paddingLeft: '77pt', textAlign: 'justify' }}>The User shall not –</p>
                                <ul id="l7">
                                    <li data-list-text="o">
                                        <p style={{ paddingTop: '4pt', paddingLeft: '113pt', textAlign: 'justify' }}>
                                            select a username, mobile number, email address or any other personally identifiable
                                            information of any other person with the intent to impersonate that person; or</p>
                                    </li>
                                    <li data-list-text="o">
                                        <p style={{ paddingLeft: '113pt', textAlign: 'justify' }}>use a name or contact
                                            details subject to any rights of any other person without appropriate authorization.</p>
                                        <p style={{ textIndent: '0pt', textAlign: 'left' }}><br /></p>
                                        <p style={{ paddingLeft: '5pt', textIndent: '0pt', textAlign: 'left' }}>In case a User does not
                                            adhere to the above conditions, Seetech reserves the right to refuse registration of or
                                            cancel registration of such User in its discretion.</p>
                                        <p style={{ textIndent: '0pt', textAlign: 'left' }}><br /></p>
                                    </li>
                                </ul>
                            </li>
                        </ul>
                    </li>
                    <li data-list-text="o">
                        <p style={{ paddingLeft: '41pt', textAlign: 'justify' }}>Internet Carrier charges levied for
                            the use of the Seetech Website shall be borne by the User(s) himself/ herself.</p>
                        <ul id="l8">
                            <li data-list-text="o">
                                <p style={{ paddingLeft: '77pt', textAlign: 'justify' }}>Seetech do not levy any
                                    registration fees for the purpose of registration on the Seetech Website nor does not charge any
                                    fee for use of the Seetech Website</p>
                                <p style={{ textIndent: '0pt', textAlign: 'left' }}><br /></p>
                            </li>
                        </ul>
                    </li>
                    <li data-list-text="o">
                        <h1 style={{ paddingLeft: '41pt', lineHeight: '14pt', textAlign: 'left' }}>Eligibility criteria
                        </h1>
                        <ul id="l9">
                            <li data-list-text="o">
                                <p style={{ paddingLeft: '77pt', textAlign: 'left' }}>Before making any use of Seetech
                                    Website, User(s) hereby represents and warrants of being at least eighteen (18) years of age or
                                    above and being fully able and competent as defined under the provisions of the Indian Contract
                                    Act, 1872 to understand and agree the terms, conditions, obligations, affirmations,
                                    representations, and warranties set forth in these Terms of Use. In case the User(s) who are
                                    below the age of eighteen (18) years wish to use the Seetech Website, then such use shall be
                                    under the direct supervision of the legal guardian or parents of the User, who have agreed to
                                    these Terms of Use on behalf of such User. In the event a minor uses the Seetech Website, it is
                                    assumed that he/she has obtained the consent of the legal guardian or parents and such use is
                                    made available by his/ her legal guardian or parents. Seetech will not be responsible for any
                                    consequence that arises as a result of misuse of any kind of the Seetech Website that may occur
                                    by virtue of any person including a minor registering for using the Seetech Website.</p>
                            </li>
                            <li data-list-text="o">
                                <p style={{ paddingLeft: '77pt', textAlign: 'justify' }}>Seetech reserve the right to
                                    terminate a User account and / or refuse to provide a User(s) with access to the Seetech Website
                                    if it is discovered that such User(s) is under 18 years of age and his/ her parent/legal
                                    guardian do not consent to use of Seetech Website or if any information provided by the User(s)
                                    is inaccurate.</p>
                            </li>
                            <li data-list-text="o">
                                <p style={{ paddingLeft: '77pt', textAlign: 'justify' }}>It is agreed that Seetech do
                                    not have the responsibility to ensure that User conforms to the aforesaid eligibility criteria.
                                    Any person under the age of 18 years should seek the consent of their parents/legal guardians
                                    before providing any information about himself/ herself or his/ her parents/ legal guardian on
                                    the Seetech Website.</p>
                            </li>
                            <li data-list-text="o">
                                <p style={{ paddingLeft: '77pt', textAlign: 'justify' }}>Only those Users which comply
                                    with all laws and regulations in force shall be eligible to register on the Seetech Website and
                                    the Seetech Website shall be used only in compliance with these Terms of Use in a manner that
                                    does not violate the legal rights of Seetech .</p>
                                <p style={{ textIndent: '0pt', textAlign: 'left' }}><br /></p>
                            </li>
                        </ul>
                    </li>
                    <li data-list-text="o">
                        <h1 style={{ paddingLeft: '41pt', lineHeight: '14pt', textAlign: 'left' }}>Privacy policy</h1>
                        <ul id="l10">
                            <li data-list-text="o">
                                <p style={{ paddingLeft: '77pt', textAlign: 'left' }}>Seetech shall be committed to
                                    ensuring that the information furnished, as a part of registration process mentioned above, is
                                    secure and no</p>
                                <p style={{ paddingTop: '4pt', paddingLeft: '77pt', textIndent: '0pt', textAlign: 'left' }}>unauthorized
                                    access or disclosure takes place with respect to the same. Seetech have privacy policy in place
                                    to ensure the same.</p>
                            </li>
                            <li data-list-text="o">
                                <p style={{ paddingLeft: '77pt', textAlign: 'left' }}>Purpose of collecting PII: The
                                    major purposes for the collection of PII are listed below:</p>
                            </li>
                        </ul>
                    </li>
                    <li data-list-text="o">
                        <p style={{ paddingLeft: '41pt', lineHeight: '14pt', textAlign: 'left' }}>For improving services
                            offered and received using the Seetech Website;</p>
                    </li>
                    <li data-list-text="o">
                        <p style={{ paddingLeft: '41pt', lineHeight: '14pt', textAlign: 'left' }}>For keeping internal
                            records;</p>
                    </li>
                    <li data-list-text="o">
                        <p style={{ paddingLeft: '41pt', lineHeight: '14pt', textAlign: 'left' }}>For market research
                            purposes;</p>
                    </li>
                    <li data-list-text="o">
                        <p style={{ paddingLeft: '41pt', lineHeight: '14pt', textAlign: 'left' }}>For the purpose of
                            enhancing User’s experience.</p>
                        <ul id="l11">
                            <li data-list-text="o">
                                <p style={{ paddingLeft: '77pt', textAlign: 'justify' }}><a href="mailto:info@seetech.in" classname="a" target="_blank">In case a User changes his/ her mind
                                    and wishes to discontinue sharing his/ her PII, he/ she/ it may express such intention by
                                    mailing </a><a href="mailto:info@seetech.in" target="_blank">info@seetech.in.</a></p>
                            </li>
                            <li data-list-text="o">
                                <p style={{ paddingLeft: '77pt', textAlign: 'justify' }}>Seetech reserve the right at
                                    all times to disclose any information as may be required by law. This may include disclosure of
                                    information for investigation of an alleged illegal activity or its response to a lawful court
                                    order.</p>
                            </li>
                        </ul>
                    </li>
                    <li data-list-text="o">
                        <h1 style={{ paddingLeft: '41pt', lineHeight: '14pt', textAlign: 'justify' }}>Prohibited use</h1>
                        <ul id="l12">
                            <li data-list-text="o">
                                <p style={{ paddingLeft: '77pt', textAlign: 'justify' }}>The Users shall not decompile,
                                    reverse engineer, or disassemble the contents of the Seetech Website or modify, copy,
                                    distribute, transmit, display, perform, reproduce, publish, license, create derivative works
                                    from, transfer, or sell any information obtained from the use of Seetech Website.</p>
                            </li>
                            <li data-list-text="o">
                                <p style={{ paddingLeft: '77pt', textAlign: 'justify' }}>The User shall not use the
                                    Seetech Website in any way that is unlawful or harms the goodwill or reputation of Seetech .</p>
                            </li>
                            <li data-list-text="o">
                                <p style={{ paddingLeft: '77pt', lineHeight: '14pt', textAlign: 'justify' }}>The User
                                    shall not use the Seetech Website in any way which:</p>
                            </li>
                        </ul>
                    </li>
                    <li data-list-text="o">
                        <p style={{ paddingLeft: '41pt', lineHeight: '14pt', textAlign: 'justify' }}>Interferes with
                            another person’s use of the Seetech Website;</p>
                    </li>
                    <li data-list-text="o">
                        <p style={{ paddingLeft: '41pt', textAlign: 'justify' }}>Makes or transmits electronic copies
                            of materials protected by copyright without the permission of the owner, committing any act that amounts
                            to the infringement of intellectual property or making available any material that infringes any
                            intellectual property rights or other proprietary rights of anyone else;</p>
                    </li>
                    <li data-list-text="o">
                        <p style={{ paddingLeft: '41pt', textAlign: 'justify' }}>Transmits or makes available any
                            material that contains viruses, trojan horses, worms, spyware, time bombs, cancelbots, or other computer
                            programming routines, code, files or such other programs that may harm the Seetech Website, interests or
                            rights of other User(s) or limit the functionality of any computer software, hardware or
                            telecommunications;</p>
                    </li>
                    <li data-list-text="o">
                        <p style={{ paddingLeft: '41pt', textAlign: 'justify' }}>Uses the Seetech Website in any manner
                            that could damage, disable, overburden or impair any of the servers or the networks connected to any of
                            the servers on which the Seetech Website is hosted;</p>
                    </li>
                    <li data-list-text="o">
                        <p style={{ paddingLeft: '41pt', textAlign: 'justify' }}>Use the Seetech Website for purposes
                            that are not permitted by these Terms of Use or any applicable law, regulation or generally accepted
                            practices or guidelines in the relevant jurisdiction.</p>
                        <p style={{ textIndent: '0pt', textAlign: 'left' }}><br /></p>
                    </li>
                    <li data-list-text="o">
                        <h1 style={{ paddingLeft: '41pt', lineHeight: '14pt', textAlign: 'justify' }}>Access</h1>
                        <ul id="l13">
                            <li data-list-text="o">
                                <p style={{ paddingLeft: '77pt', textAlign: 'justify' }}>The User’s access to the
                                    Seetech Website may be occasionally suspended or restricted for the purpose of repairs,
                                    maintenance, or the introduction of new facilities or services at any time without prior notice.
                                </p>
                            </li>
                            <li data-list-text="o">
                                <p style={{ paddingLeft: '77pt', textAlign: 'justify' }}>There is no warranty,
                                    representation or guarantee that the Seetech Website or the use thereof will be uninterrupted,
                                    complete, accurate, current, reliable, error-free, secure, or that any problems will be
                                    corrected, or that any information, software or other material accessible</p>
                                <p style={{ paddingTop: '4pt', paddingLeft: '77pt', textIndent: '0pt', textAlign: 'justify' }}>from the
                                    Seetech Website is free from viruses or other harmful components.</p>
                            </li>
                            <li data-list-text="o">
                                <p style={{ paddingLeft: '77pt', textAlign: 'justify' }}>Seetech do not warrant,
                                    guarantee or make any representation regarding the use of or the results arising from the use of
                                    the Seetech Website either in terms of its compatibility with hardware or other software or
                                    equipment, and the User must assume all responsibility and risk for use of the Seetech Website
                                    its services, information.</p>
                            </li>
                            <li data-list-text="o">
                                <p style={{ paddingLeft: '77pt', textAlign: 'justify' }}>Seetech reserves the right to
                                    change, modify, or remove the content or the services from time to time to comply with new laws
                                    or regulations or to update our offerings.</p>
                                <p style={{ textIndent: '0pt', textAlign: 'left' }}><br /></p>
                            </li>
                        </ul>
                    </li>
                    <li data-list-text="o">
                        <h1 style={{ paddingLeft: '41pt', lineHeight: '14pt', textAlign: 'justify' }}>Breach</h1>
                        <ul id="l14">
                            <li data-list-text="o">
                                <p style={{ paddingLeft: '77pt', textAlign: 'justify' }}>Without limiting other
                                    remedies, Seetech may, at their sole discretion may limit activity of the User, immediately for
                                    temporary/ indefinite period or may suspend or terminate or block membership and/or refuse to
                                    provide access to the Seetech Website in the event, including but not limited to:<b>Breach of
                                        the Terms of Use or any other rules and policies as displayed on the Seetech Website;If
                                        Seetech are unable to verify or authenticate the information furnished by the User;In such
                                        circumstances wherein Seetech are of the opinion that there is a violation of any law(s) in
                                        force.</b></p>
                            </li>
                            <li data-list-text="o">
                                <p style={{ paddingLeft: '77pt', textAlign: 'justify' }}>The User(s) willing to use the
                                    Seetech Website also agrees that any breach of these Terms of Use or any other rules or policy
                                    as displayed on the Seetech Website will constitute an unlawful and unfair business practice,
                                    and will cause irreparable harm to Seetech , for which monetary damages would normally be
                                    inadequate. In case payment of monetary damages is deemed adequate remedy in the opinion of
                                    Seetech , the User(s) consents to pay the same to Seetech , which shall not forestall the
                                    obtaining of any injunctive or equitable relief that Seetech deem necessary or appropriate in
                                    such circumstances. These remedies shall be in addition to any other remedies Seetech may have
                                    in law or in equity.</p>
                            </li>
                            <li data-list-text="o">
                                <p style={{ paddingLeft: '77pt', textAlign: 'justify' }}>If Seetech initiates any legal
                                    action as a result of any breach of these Terms of Use or any other rules or policy, Seetech
                                    shall also be entitled to recover all reasonable legal costs in respect of such action, in
                                    addition to any other relief available to them under law.</p>
                            </li>
                        </ul>
                    </li>
                    <li data-list-text="o">
                        <h1 style={{ paddingLeft: '41pt', lineHeight: '14pt', textAlign: 'justify' }}>Product and
                            Services Information</h1>
                        <ul id="l15">
                            <li data-list-text="o">
                                <p style={{ paddingLeft: '77pt', textAlign: 'justify' }}>Seetech attempts to be as
                                    accurate as possible in providing the description of the product(s) on the Seetech Website.</p>
                            </li>
                            <li data-list-text="o">
                                <p style={{ paddingLeft: '77pt', textAlign: 'justify' }}>Seetech does not warrant that
                                    the product information, description, image or colour to be a hundred percent accurate.</p>
                            </li>
                            <li data-list-text="o">
                                <p style={{ paddingLeft: '77pt', textAlign: 'justify' }}>The Users acknowledges and
                                    understands that the product images are for illustrative purposes only and may differ from the
                                    actual product.</p>
                            </li>
                            <li data-list-text="o">
                                <p style={{ paddingLeft: '77pt', textAlign: 'justify' }}>The User acknowledges and
                                    understands that the colour of the product(s) may differ from that shown on the Seetech website.
                                </p>
                            </li>
                            <li data-list-text="o">
                                <p style={{ paddingLeft: '77pt', textAlign: 'justify' }}>The User acknowledges and
                                    understands that some product(s) may have associated images for the reference and illustrative
                                    purpose for the Users.</p>
                            </li>
                            <li data-list-text="o">
                                <p style={{ paddingLeft: '77pt', textAlign: 'justify' }}>Seetech reserves the right to
                                    update, change, modify or omit any information, inaccuracies including but not limited to the
                                    pricing and</p>
                                <p style={{ paddingTop: '4pt', paddingLeft: '77pt', textIndent: '0pt', textAlign: 'left' }}>availability of
                                    the product (including after an order being placed by the User) without any prior notice.</p>
                            </li>
                        </ul>
                    </li>
                    <li data-list-text="o">
                        <p classname="s3" style={{ paddingLeft: '41pt', textAlign: 'left' }}>Recommendation of the Product
                        </p>
                    </li>
                </ul>
                <p style={{ textIndent: '0pt', textAlign: 'left' }}><br /></p>
                <p style={{ paddingLeft: '5pt', textIndent: '0pt', textAlign: 'justify' }}>The User accepts and acknowledges that any
                    recommendation made to the User by the on the Seetech Website during the course of use of the Seetech Website is
                    purely for information purpose and for the convenience of the User. Such recommendation does not amount to any
                    endorsement of the product(s) by Seetech or any of its associates in any manner.</p>
                <p style={{ textIndent: '0pt', textAlign: 'left' }}><br /></p>
                <h5 classname="s3" style={{ paddingLeft: '5pt', textIndent: '0pt', textAlign: 'justify' }}>THIRD-PARTY CONTENT, SITES, AND
                    SERVICES</h5>
                <p style={{ textIndent: '0pt', textAlign: 'left' }}><br /></p>
                <ol id="l16">
                    <li data-list-text={1.}>
                        <p style={{ paddingLeft: '5pt', textIndent: '0pt', textAlign: 'justify' }}>The Content available through the Seetech
                            site may contain features and functionalities that may link you or provide you with access to
                            third-party content which is completely independent of Seetech, including websites, directories,
                            servers, networks, systems, information and databases, applications, software, programs, products or
                            services, and the Internet as a whole. Your interactions with organizations and/or individuals found on
                            or through the service, including payment and delivery of goods or services, and any other terms,
                            conditions, warranties or representations associated with such dealings, are solely between you and such
                            organizations and/or individuals. The Company shall not be held responsible for any content, product(s)
                            and/ or service(s) offered on the website(s) other than Seetech even in cases where the user is
                            redirected to such website(s) from Seetech.</p>
                        <p style={{ textIndent: '0pt', textAlign: 'left' }}><br /></p>
                    </li>
                    <li data-list-text={2.}>
                        <p style={{ paddingLeft: '5pt', textIndent: '0pt', textAlign: 'justify' }}>The user shall be wholly responsible for
                            consuming content, availing the product(s) and services(s) rendered on other websites, shall make
                            themselves aware of the guidelines and terms of such websites and indemnify the Company against any
                            damages or losses arising out of the content, product(s) and/ or service(s) of any other website other
                            than Seetech. You should make whatever investigation you feel necessary or appropriate before proceeding
                            with any online or offline communication/ transaction with any of these third parties. You agree that
                            the Company cannot be held responsible or liable for any loss or damage of any sort incurred as the
                            result of any such dealings.</p>
                        <p style={{ textIndent: '0pt', textAlign: 'left' }}><br /></p>
                    </li>
                    <li data-list-text={3.}>
                        <p style={{ paddingLeft: '5pt', textIndent: '0pt', textAlign: 'justify' }}>If there is a dispute between
                            participants on this platform(s), or between users and any third party, you understand and agree that
                            the Company is under no obligation to become involved. In the event that you have a dispute with one or
                            more other users, you hereby release the Company, its representatives, officers, employees, agents and
                            successors in rights from claims, demands and damages (actual and consequential) of every kind or
                            nature, known or unknown, suspected and unsuspected, disclosed and undisclosed, arising out of or in any
                            way related to such disputes and/ or our service. Customer disputes with workshops/ outlets/ shops/
                            producers/ manufacturers and/ or suppliers providing information or goods to customers, also routed
                            through Seetech, shall be considered as a third party.</p>
                        <p style={{ textIndent: '0pt', textAlign: 'left' }}><br /></p>
                    </li>
                    <li data-list-text={4.}>
                        <p style={{ paddingLeft: '5pt', textIndent: '0pt', textAlign: 'justify' }}>Seetech deals with manufacturer(s),
                            producer(s) and supplier(s) and offers their catalogue to users as a mediator. We recommend you consult
                            with any workshop or specialist while choosing any product(s) or service(s) intended for installation in
                            your</p>
                        <p style={{ paddingTop: '3pt', paddingLeft: '5pt', textIndent: '0pt', textAlign: 'justify' }}>car. In the case of any
                            compatibility, fitment and/or quality issue(s) with the product(s) or service(s) the Company does not
                            take any responsibility for such issues and quality of the products but may, at its sole discretion,
                            assist/ help the user to resolve their issues with the mutual discussion between them and the supplier.
                        </p>
                        <p style={{ textIndent: '0pt', textAlign: 'left' }}><br /></p>
                    </li>
                    <li data-list-text={5.}>
                        <p style={{ paddingLeft: '5pt', textIndent: '0pt', lineHeight: '108%', textAlign: 'left' }}>The user is not entitled
                            to claim damages from the Company for any issues with the product(s) and service(s), their prices,
                            discounts, quality and fitment.</p>
                        <ol id="l17">
                            <li data-list-text={1.}>
                                <h1 style={{ paddingTop: '3pt', paddingLeft: '41pt', textAlign: 'left', marginTop: '30px' }}>Pricing &amp;
                                    Availability</h1>
                                <p style={{ textIndent: '0pt', textAlign: 'left' }}><br /></p>
                                <ol id="l18">
                                    <li data-list-text={1.}>
                                        <p style={{ paddingTop: '4pt', paddingLeft: '77pt', textAlign: 'justify' }}>
                                            Seetech attempts to be as accurate as possible in providing the pricing information and
                                            availability of the product(s).</p>
                                    </li>
                                    <li data-list-text={2.}>
                                        <p style={{ paddingLeft: '77pt', textAlign: 'justify' }}>All prices of the
                                            product(s) are in the Currency mentioned are exclusive of Goods and Service Tax (GST).
                                        </p>
                                    </li>
                                    <li data-list-text={3.}>
                                        <p style={{ paddingLeft: '77pt', textAlign: 'justify' }}>In the event where the
                                            product so listed at an incorrect price or with incorrect information due to any
                                            technical error, Seetech reserves the right, at its sole discretion to refuse or cancel
                                            any order(s) placed for that product, unless the product has been already delivered.</p>
                                    </li>
                                    <li data-list-text={4.}>
                                        <p style={{ paddingLeft: '77pt', textAlign: 'justify' }}>Seetech, at its sole
                                            discretion reserves the right to change the prices and availability of the product on
                                            the Seetech Website without providing prior notice to the User.</p>
                                    </li>
                                    <li data-list-text={5.}>
                                        <p style={{ paddingLeft: '77pt', textAlign: 'justify' }}>All products are
                                            subject to availability and Seetech may therefore revise and cease to make available any
                                            product at any time.</p>
                                    </li>
                                    <li data-list-text={6.}>
                                        <p style={{ paddingLeft: '77pt', textAlign: 'justify' }}>It is clarified that
                                            all responsibilities towards collection and delivery of the ordered products including
                                            the cost payable to the delivery partner, loss in transit shall be upon the User/buyer.
                                            Seetech hereby disclaims from any and all responsibilities from all the losses, damages
                                            suffered by the User owing to any act or omission of the delivery partner.</p>
                                    </li>
                                    <li data-list-text={7.}>
                                        <p style={{ paddingLeft: '77pt', textAlign: 'justify' }}>Seetech shall not be
                                            liable to pay for any damages in such an event owing to cancellation or unavailability
                                            of the order or delay in delivery.</p>
                                    </li>
                                </ol>
                            </li>
                            <li data-list-text={2.}>
                                <h1 style={{ paddingLeft: '41pt', lineHeight: '14pt', textAlign: 'justify' }}>Shipping
                                    and Delivery</h1>
                                <ol id="l19">
                                    <li data-list-text={1.}>
                                        <p style={{ paddingLeft: '77pt', textAlign: 'justify' }}>Once an order is
                                            placed by a User, Seetech system processes the order.</p>
                                    </li>
                                    <li data-list-text={2.}>
                                        <p style={{ paddingLeft: '77pt', textAlign: 'justify' }}>After the product(s)
                                            pass through all the levels of quality check, the products are then packed and handed
                                            over to the delivery partner as suggested by the User.</p>
                                    </li>
                                    <li data-list-text={3.}>
                                        <p style={{ paddingLeft: '77pt', textAlign: 'justify' }}>The User agrees and
                                            acknowledges that any and all losses occurred due to any act or omission of the delivery
                                            partner shall be borne by the User.</p>
                                    </li>
                                    <li data-list-text={4.}>
                                        <p style={{ paddingLeft: '77pt', textAlign: 'justify' }}>The User acknowledges
                                            and undertakes full responsibility to collect the package from the delivery partner at
                                            the transport warehouse as per the instructions issued by the delivery partner</p>
                                    </li>
                                    <li data-list-text={5.}>
                                        <p style={{ paddingLeft: '77pt', textAlign: 'justify' }}>It is clarified that
                                            in the event the delivery partner does not collect the package from Seetech , the User
                                            will be notified and contacted on the registered email or mobile number as provided
                                            during the registration process.</p>
                                    </li>
                                    <li data-list-text={6.}>
                                        <p style={{ paddingLeft: '77pt', textAlign: 'justify' }}>In the event, the User
                                            does not respond to Seetech within 7 days from receipt of such information provided, the
                                            said order shall be deemed to be cancelled and no refund shall be provided by Seetech
                                            for the said order.</p>
                                    </li>
                                    <li data-list-text={7.}>
                                        <p style={{ paddingLeft: '77pt', textAlign: 'justify' }}>It is clarified that
                                            Seetech may, at its sole discretion, sell or dispose of such order/product as it may
                                            deem fit.</p>
                                    </li>
                                    <li data-list-text={8.}>
                                        <p style={{ paddingLeft: '77pt', textAlign: 'justify' }}>On dispatch of the
                                            order so placed, the User will be able to track the order with the Transport
                                            bilty/courier slip, airway bill or bill of lading, as provided to the User by Seetech .
                                            .</p>
                                    </li>
                                    <li data-list-text={9.}>
                                        <p style={{ paddingLeft: '77pt', textAlign: 'justify' }}>The estimated time of
                                            delivery of the product so ordered differs from country to country.</p>
                                    </li>
                                    <li data-list-text={10.}>
                                        <p style={{ paddingTop: '3pt', paddingLeft: '77pt', textAlign: 'justify' }}>The
                                            orders so placed by the User are subject to shipping/delivery charges. It is clarified
                                            that the shipping/delivery charges are borne by the User.</p>
                                    </li>
                                    <li data-list-text={11.}>
                                        <p classname="s4" style={{ paddingLeft: '77pt', textAlign: 'justify' }}>If due any
                                            unforeseen development in the geographical location from or where the delivery of the
                                            order is supposed to take place, Seetech is unable to deliver the order, Seetech is not
                                            liable to provide any refund on the amount received against the order to the User<span style={{ color: '#011522' }}>.</span></p>
                                        <p style={{ textIndent: '0pt', textAlign: 'left' }}><br /></p>
                                    </li>
                                </ol>
                            </li>
                            <li data-list-text={3.}>
                                <h1 style={{ paddingTop: '11pt', paddingLeft: '41pt', textAlign: 'justify' }}>Mode of
                                    payment</h1>
                                <ol id="l20">
                                    <li data-list-text={1.}>
                                        <p style={{ paddingLeft: '77pt', textAlign: 'justify' }}>Payment for the
                                            products available on the Seetech Website can be made using online and offline methods.
                                        </p>
                                    </li>
                                    <li data-list-text={2.}>
                                        <p style={{ paddingLeft: '77pt', lineHeight: '14pt', textAlign: 'justify' }}>It
                                            is clarified that all the payment shall be payable upfront and in advance</p>
                                    </li>
                                    <li data-list-text={3.}>
                                        <p style={{ paddingLeft: '77pt', textAlign: 'justify' }}>The Payment will be
                                            debited from Users account before dispatch of the order and the User will be notified
                                            accordingly.</p>
                                    </li>
                                    <li data-list-text={4.}>
                                        <p style={{ paddingLeft: '77pt', textAlign: 'justify' }}>Seetech will take all
                                            reasonable care and precaution in its power to keep the details of the order and the
                                            payment secure.</p>
                                    </li>
                                    <li data-list-text={5.}>
                                        <p style={{ paddingLeft: '77pt', textAlign: 'justify' }}>The User acknowledges
                                            and accepts that in the event such payment and order details are exposed due to
                                            unenforceable event, the provider of the Seetech Website shall not be held liable for
                                            any loss that the User may suffer if any third party procures unauthorized access to any
                                            data provided by the User while accessing or ordering any product from the Seetech
                                            Website.</p>
                                        <p style={{ textIndent: '0pt', textAlign: 'left' }}><br /></p>
                                    </li>
                                </ol>
                            </li>
                            <li data-list-text={4.}>
                                <h1 style={{ paddingTop: '11pt', paddingLeft: '41pt', textAlign: 'justify' }}>Return and
                                    Cancellation of Orders</h1>
                                <ol id="l21">
                                    <li data-list-text={1.}>
                                        <p classname="s4" style={{ paddingLeft: '77pt', textAlign: 'justify' }}><a href="mailto:info@seetech.in" classname="s5" target="_blank">User may apply for partial
                                            cancellation or modification of orders which are not yet shipped by writing an email
                                            at </a>info@seetech.in, said modification or partial cancellation may be done at the
                                            discretion of company after considering the various factors like sale, inventory
                                            liquidity of those items.</p>
                                    </li>
                                    <li data-list-text={2.}>
                                        <p classname="s4" style={{ paddingLeft: '77pt', textAlign: 'justify' }}>In case of
                                            supply of products which are not as per the parts identification reference of order
                                            placed, Domestic supply customer may approach for refund of the cost of such supplies
                                            paid to Seetech. In case Customer being Overseas, refund and return of such products
                                            will be considered subject to prevailing laws, regulations and as per mutual agreement.
                                            However such products should meet other below mentioned conditions.</p>
                                    </li>
                                    <li data-list-text={3.}>
                                        <p classname="s4" style={{ paddingLeft: '77pt', textAlign: 'justify' }}>The
                                            products being returned must be unused, unopened and in the same condition in which they
                                            were dispatched. Returns of product(s) will not be accepted under the following
                                            conditions:</p>
                                        <ol id="l22">
                                            <li data-list-text={1.}>
                                                <p classname="s4" style={{ paddingLeft: '113pt', lineHeight: '14pt', textAlign: 'left' }}>
                                                    Product is damaged due to misuse/overuse</p>
                                            </li>
                                            <li data-list-text={2.}>
                                                <p classname="s4" style={{ paddingLeft: '113pt', textAlign: 'left' }}>
                                                    Returned without original packaging including price tags, labels, original
                                                    packing, freebies and if the original packing is damaged.</p>
                                            </li>
                                            <li data-list-text={3.}>
                                                <p classname="s4" style={{ paddingLeft: '113pt', lineHeight: '14pt', textAlign: 'left' }}>
                                                    Serial Number is tempered with.</p>
                                            </li>
                                            <li data-list-text={4.}>
                                                <p classname="s4" style={{ paddingLeft: '113pt', lineHeight: '14pt', textAlign: 'left' }}>
                                                    Defective products that are not covered under the warranty.</p>
                                            </li>
                                            <li data-list-text={5.}>
                                                <p classname="s4" style={{ paddingLeft: '113pt', lineHeight: '14pt', textAlign: 'left' }}>
                                                    Product is used or altered</p>
                                            </li>
                                            <li data-list-text={6.}>
                                                <p classname="s4" style={{ paddingLeft: '113pt', lineHeight: '14pt', textAlign: 'left' }}>
                                                    If request is initiated after 5 business days of delivery order.</p>
                                            </li>
                                            <li data-list-text={7.}>
                                                <p classname="s4" style={{ paddingTop: '4pt', paddingLeft: '113pt', textAlign: 'justify' }}>
                                                    The User shall ensure to contact Seetech on the abovementioned email id to
                                                    confirm which product(s) are being returned.</p>
                                            </li>
                                        </ol>
                                    </li>
                                    <li data-list-text={4.}>
                                        <p classname="s4" style={{ paddingLeft: '77pt', textAlign: 'justify' }}>Return or
                                            Refund of Defective products will be dealt as per the Warranty policy of Seetech</p>
                                    </li>
                                    <li data-list-text={5.}>
                                        <p classname="s4" style={{ paddingLeft: '77pt', textAlign: 'justify' }}>There will
                                            be no refund on the cost of returning the goods, (unless the item is faulty).</p>
                                    </li>
                                    <li data-list-text={6.}>
                                        <p classname="s4" style={{ paddingLeft: '77pt', textAlign: 'justify' }}>The User
                                            shall return any free gift associated with the product at the same time.</p>
                                    </li>
                                    <li data-list-text={7.}>
                                        <p classname="s4" style={{ paddingLeft: '77pt', textAlign: 'justify' }}>The User
                                            shall return the product to the address Plot No. 76, Sector-58, Ballabgarh, Faridabad-
                                            121004, India , along with a note of the details and the reason for return.</p>
                                    </li>
                                    <li data-list-text={8.}>
                                        <p classname="s4" style={{ paddingLeft: '77pt', textAlign: 'justify' }}>Refunds or
                                            credit notes will be issued to the original charged account within 30 days of receipt of
                                            the returned goods<span style={{ color: '#011522' }}>.</span></p>
                                    </li>
                                </ol>
                            </li>
                            <li data-list-text={5.}>
                                <h1 style={{ paddingLeft: '41pt', lineHeight: '13pt', textAlign: 'justify' }}>Order
                                    Confirmation</h1>
                                <ol id="l23">
                                    <li data-list-text={1.}>
                                        <p style={{ paddingLeft: '77pt', textAlign: 'justify' }}>User after placing an
                                            order and making the payment against the order will receive a confirmation email or
                                            message on the registered email address and mobile number as provided by the User from
                                            Seetech .</p>
                                    </li>
                                    <li data-list-text={2.}>
                                        <p style={{ paddingLeft: '77pt', textAlign: 'justify' }}>The providers of
                                            Seetech Website reserves the right to decline any order subject to the Terms of Use.</p>
                                    </li>
                                    <li data-list-text={3.}>
                                        <p style={{ paddingLeft: '77pt', textAlign: 'justify' }}>All orders accepted
                                            are subject to these Terms of Use and all such other policies as available on the
                                            Seetech Website.</p>
                                    </li>
                                </ol>
                            </li>
                        </ol>
                    </li>
                </ol>
                <h1 style={{ paddingTop: '3pt', paddingLeft: '23pt', textIndent: '0pt', textAlign: 'left' }}>Intellectual Property Rights</h1>
                <p style={{ textIndent: '0pt', textAlign: 'left' }}><br /></p>
                <p style={{ paddingTop: '4pt', paddingLeft: '41pt', textIndent: '0pt', textAlign: 'justify' }}>Users shall not acquire any
                    rights, including rights in or to any software, trademarks or components of the platform(s), by access or use of
                    the platform(s</p>
                <p style={{ textIndent: '0pt', textAlign: 'left' }}><br /></p>
                <p style={{ paddingLeft: '40pt', textIndent: '0pt', textAlign: 'justify' }}>All intellectual property rights including
                    trademarks, &amp; copyright of the Seetech Website and the products displayed on the Seetech Website shall vest
                    with the providers or the respective manufacturers.</p>
                <p style={{ textIndent: '0pt', textAlign: 'left' }}><br /></p>
                <p style={{ paddingLeft: '40pt', textIndent: '0pt', textAlign: 'justify' }}>Although the Company does not claim ownership of
                    Content that its users post, by posting Content to any public area of the platform(s), you automatically grant,
                    and you represent and warrant that you have the right to grant, to the Company an irrevocable, perpetual,
                    non-exclusive, fully paid, worldwide license to use, copy, perform, display, and distribute the said Content and
                    to prepare derivative works of, or incorporate into other works, said Content, and to grant and authorize
                    sublicenses (through multiple tiers) of the foregoing. Furthermore, by posting Content to any public area of the
                    platform(s), you automatically grant the Company all rights necessary to prohibit any subsequent aggregation,
                    display, copying, duplication, reproduction, or exploitation of the Content on the platform(s) by any party for
                    any purpose</p>
                <p style={{ textIndent: '0pt', textAlign: 'left' }}><br /></p>
                <h1 style={{ paddingLeft: '5pt', textIndent: '0pt', textAlign: 'left' }}>Posting of Content</h1>
                <p style={{ textIndent: '0pt', textAlign: 'left' }}><br /></p>
                <ol id="l24">
                    <li data-list-text={1.}>
                        <p style={{ paddingTop: '4pt', paddingLeft: '77pt', textAlign: 'justify' }}>Seetech enable their
                            User(s) to post messages, photographs and video in a various segment as may be desired by User(s)
                            depending on the area, city, state or country the Users in their respective segments will receive the
                            concerned User’s post.</p>
                    </li>
                    <li data-list-text={2.}>
                        <p style={{ paddingLeft: '77pt', textAlign: 'left' }}>All posts, pictures, photographs, data
                            and any other content (hereinafter referred to as “Content”), whether publicly posted or privately
                            transmitted, is the sole responsibility of the User and the person who originated such Content.</p>
                    </li>
                    <li data-list-text={3.}>
                        <p style={{ paddingLeft: '77pt', textAlign: 'justify' }}>The User shall be solely responsible
                            for all the Content posted from his/ her account and all activities carried on from his/ her account.
                            Seetech Application shall take all reasonable steps for the protection of Content added to its cloud
                            server by the User from the use of his/ her account.</p>
                    </li>
                    <li data-list-text={4.}>
                        <p style={{ paddingLeft: '77pt', textAlign: 'justify' }}>Seetech are under no obligation to
                            monitor or control the Content posted on the Seetech Website and, Seetech cannot take responsibility of
                            the correctness and accuracy of such Content. Any use or reliance on any Content or materials posted on
                            the Seetech Website or obtained by the User on the Seetech Website is at the User’s own risk and
                            prudence.</p>
                    </li>
                    <li data-list-text={5.}>
                        <p style={{ paddingLeft: '77pt', textAlign: 'justify' }}>Seetech do not endorse, support,
                            represent or guarantee the completeness, truthfulness, accuracy, or reliability of any Content or
                            communications posted on the Seetech Website or endorse any opinions expressed on the Seetech Website.
                        </p>
                    </li>
                    <li data-list-text={6.}>
                        <p style={{ paddingLeft: '77pt', textAlign: 'justify' }}>The User understands that by using the
                            Seetech Website, the User may be exposed to Content that might be offensive, harmful, inaccurate or
                            otherwise inappropriate, or in some cases, Content that have been mislabeled or are otherwise deceptive.
                            Under no circumstances will Seetech be liable in any way for any Content whatsoever, including, but not
                            limited to, any errors or omissions in any Content, or any loss or</p>
                        <p style={{ paddingTop: '3pt', paddingLeft: '77pt', textIndent: '0pt', textAlign: 'justify' }}>damage of any kind
                            incurred as a result of the use of any Content posted, emailed, transmitted or otherwise made available
                            on the Seetech Website.</p>
                    </li>
                    <li data-list-text={7.}>
                        <p style={{ paddingLeft: '77pt', textAlign: 'justify' }}>Seetech reserve the right to modify or
                            adapt the User’s Content in order to transmit, display or distribute it over computer networks and in
                            various media and/or make changes to the Content as are necessary to conform and adapt that Content to
                            any requirements or limitations of any networks, devices, services or media.</p>
                    </li>
                    <li data-list-text={8.}>
                        <p style={{ paddingLeft: '77pt', textAlign: 'justify' }}>The User agrees that he/she is
                            responsible for use of the Seetech Website, for any Content provided, and for any consequences thereof,
                            including the use of Content by other users and third-party associates of Seetech The User represents
                            and warrants that you have all the rights, power and authority necessary to grant the rights granted
                            herein to any Content that you submit.</p>
                    </li>
                </ol>
                <p style={{ textIndent: '0pt', textAlign: 'left' }}><br /></p>
                <h1 style={{ paddingLeft: '23pt', textIndent: '0pt', textAlign: 'left' }}>Termination/Deletion of Account</h1>
                <p style={{ textIndent: '0pt', textAlign: 'left' }}><br /></p>
                <ol id="l25">
                    <li data-list-text={1.}>
                        <p style={{ paddingTop: '4pt', paddingLeft: '77pt', textAlign: 'justify' }}><a href="mailto:info@seetech.in" classname="a" target="_blank">The User is solely responsible for properly
                            deleting his/ her account, by sending an email to </a><a href="mailto:info@seetech.in" target="_blank">info@seetech.in.</a></p>
                    </li>
                    <li data-list-text={2.}>
                        <p style={{ paddingLeft: '77pt', textAlign: 'justify' }}>All of the User’s content will
                            immediately be inaccessible from the Seetech Website upon deletion. All this content will be permanently
                            deleted from all backups and logs. This information cannot be recovered once it has been permanently
                            deleted.</p>
                    </li>
                    <li data-list-text={3.}>
                        <p style={{ paddingLeft: '77pt', textAlign: 'justify' }}>Seetech , in their sole discretion,
                            has the right to suspend or terminate the User’s account in case of prolonged inactivity or misuse of
                            the Seetech Website which is a violation of any provision(s) of these Terms of Use or violation of any
                            applicable law. Such termination will result in the deactivation or deletion of the User’s account or
                            access to the account, and the forfeiture and relinquishment of all Content in the account. Seetech
                            reserve the right to refuse service to anyone for any reason at any time.</p>
                    </li>
                </ol>
                <p style={{ textIndent: '0pt', textAlign: 'left' }}><br /></p>
                <h1 style={{ paddingLeft: '23pt', textIndent: '0pt', textAlign: 'left' }}>Right to monitor the content</h1>
                <p style={{ textIndent: '0pt', textAlign: 'left' }}><br /></p>
                <p style={{ paddingTop: '4pt', paddingLeft: '68pt', textIndent: '0pt', textAlign: 'left' }}>Seetech reserve the right to
                    monitor the Content available on the Seetech Website whether made available by itself or by any User.</p>
                <p style={{ textIndent: '0pt', textAlign: 'left' }}><br /></p>
                <h1 style={{ paddingLeft: '23pt', textIndent: '0pt', textAlign: 'left' }}>Indemnification</h1>
                <p style={{ textIndent: '0pt', textAlign: 'left' }}><br /></p>
                <p style={{ paddingTop: '4pt', paddingLeft: '68pt', textIndent: '0pt', textAlign: 'justify' }}>The User agrees to indemnify,
                    defend and hold harmless Seetech, and their affiliates and their officers, directors, employees, contractors,
                    agents, licensors, service providers, subcontractors and suppliers from and against any and all losses,
                    liabilities, expenses, damages and costs, including reasonable attorneys’ fees and court costs, arising or
                    resulting from the use of the Seetech Website and any violation of these Terms of Use or any applicable law.</p>
                <h1 style={{ paddingTop: '3pt', paddingLeft: '5pt', textIndent: '0pt', textAlign: 'left' }}>Limitation of liability</h1>
                <p style={{ textIndent: '0pt', textAlign: 'left' }}><br /></p>
                <p style={{ paddingTop: '4pt', paddingLeft: '5pt', textIndent: '0pt', textAlign: 'left' }}>To the fullest extent permitted by
                    applicable laws, in no event shall Seetech be liable for any damages resulting from any of the following:</p>
                <p style={{ textIndent: '0pt', textAlign: 'left' }}><br /></p>
                <ol id="l26">
                    <li data-list-text={1.}>
                        <p style={{ paddingLeft: '41pt', textAlign: 'left' }}>errors, mistakes, or inaccuracies of
                            content;</p>
                    </li>
                    <li data-list-text={2.}>
                        <p style={{ paddingLeft: '41pt', textAlign: 'left' }}>personal injury or property damage, of
                            any nature whatsoever, resulting from access to and use of the Services including the Seetech Website;
                        </p>
                    </li>
                    <li data-list-text={3.}>
                        <p style={{ paddingLeft: '41pt', textAlign: 'left' }}>any unauthorized access to or use of our
                            servers and/or any and all PII stored therein;</p>
                    </li>
                    <li data-list-text={4.}>
                        <p style={{ paddingLeft: '41pt', lineHeight: '14pt', textAlign: 'left' }}>any interruption or
                            cessation of transmission to or from our servers;</p>
                    </li>
                    <li data-list-text={5.}>
                        <p style={{ paddingLeft: '41pt', textAlign: 'left' }}>any bugs, viruses, Trojan horses, or the
                            like, which may be transmitted to or through the services by any third party;</p>
                    </li>
                    <li data-list-text={6.}>
                        <p style={{ paddingLeft: '41pt', lineHeight: '14pt', textAlign: 'left' }}>any loss of data or
                            content;</p>
                    </li>
                    <li data-list-text={7.}>
                        <p style={{ paddingLeft: '41pt', textAlign: 'left' }}>any errors or omissions in any content or
                            for any loss or damage of any kind incurred as a result of the Users utilizing of any content posted,
                            transmitted, or otherwise made available via the Seetech Website, whether based on warranty, contract,
                            tort, or any other legal theory;</p>
                    </li>
                    <li data-list-text={8.}>
                        <p style={{ paddingLeft: '41pt', lineHeight: '14pt', textAlign: 'left' }}>the disclosure of
                            information pursuant to these terms or our Privacy Policy;</p>
                    </li>
                    <li data-list-text={9.}>
                        <p style={{ paddingLeft: '41pt', textAlign: 'justify' }}>The User’s failure to keep the
                            password or account details secure and confidential;</p>
                    </li>
                    <li data-list-text={10.}>
                        <p style={{ paddingLeft: '41pt', textAlign: 'justify' }}>In no event shall Seetech or any
                            indirect, incidental, special, punitive, exemplary or consequential damages whatsoever, however caused
                            and under any theory of liability, including but not limited to, any loss of profit (whether incurred
                            directly or indirectly), any loss of goodwill or business reputation, any loss of data suffered, cost of
                            procurement of substitute goods or services, or other intangible loss.</p>
                        <p style={{ textIndent: '0pt', textAlign: 'left' }}><br /></p>
                        <h1 style={{ paddingTop: '10pt', paddingLeft: '5pt', textIndent: '0pt', textAlign: 'left' }}>Force Majeure</h1>
                        <p style={{ textIndent: '0pt', textAlign: 'left' }}><br /></p>
                        <p style={{ paddingTop: '4pt', paddingLeft: '40pt', textIndent: '0pt', textAlign: 'justify' }}>The term force majeure
                            shall include, but not be limited to fires, floods, acts of God or the public enemy, embargoes, wars
                            (declared or undeclared), riots, pandemic, civil commotion, interference by civil or military
                            authorities, terrorist acts, Government actions, order(s) or request (s), including (without limitation)
                            certification, clearance or other document, or any other cause or contingency beyond the control of
                            Seetech in any of the aforesaid events.</p>
                        <p style={{ textIndent: '0pt', textAlign: 'left' }}><br /></p>
                        <p style={{ paddingLeft: '40pt', textIndent: '0pt', textAlign: 'justify' }}>Seetech shall not be liable for failure
                            to perform or any delay in performance of services when and to extent that such failure or delay is due
                            to force majeure.</p>
                        <p style={{ textIndent: '0pt', textAlign: 'left' }}><br /></p>
                        <h1 style={{ paddingTop: '11pt', paddingLeft: '5pt', textIndent: '0pt', textAlign: 'left' }}>Dispute resolution</h1>
                        <p style={{ textIndent: '0pt', textAlign: 'left' }}><br /></p>
                        <ol id="l27">
                            <li data-list-text={1.}>
                                <p style={{ paddingTop: '4pt', paddingLeft: '77pt', textAlign: 'left' }}>Where any
                                    dispute or disagreement arises between Seetech and the User as to the interpretation or
                                    application of any of the terms, conditions, requirements or obligations under these Terms of
                                    Use or use of the</p>
                                <p style={{ paddingTop: '4pt', paddingLeft: '77pt', textIndent: '0pt', textAlign: 'justify' }}>Seetech
                                    Website, such dispute or disagreement shall be amicably resolved vide mutual discussions.</p>
                            </li>
                            <li data-list-text={2.}>
                                <p style={{ paddingLeft: '77pt', textAlign: 'justify' }}>In cases of failure to resolve
                                    the dispute/ disagreement resolved within 30 days of such discussions the dispute shall be
                                    referred to and finally resolved by the laws in force.</p>
                            </li>
                        </ol>
                    </li>
                </ol>
                <p style={{ textIndent: '0pt', textAlign: 'left' }}><br /></p>
                <h1 style={{ paddingTop: '11pt', paddingLeft: '59pt', textIndent: '0pt', textAlign: 'left' }}>Governing Law and Jurisdiction
                </h1>
                <p style={{ textIndent: '0pt', textAlign: 'left' }}><br /></p>
                <p style={{ paddingTop: '4pt', paddingLeft: '61pt', textIndent: '0pt', textAlign: 'justify' }}><a name="bookmark1">These Terms
                    of Use shall be governed by the laws of Republic of India and the User(s) willing to utilize the Seetech
                    website hereby consents to the exclusive jurisdiction of courts in Delhi in all disputes arising out of or
                    relating to the use of the Seetech Website. Use of the Seetech Website is unauthorized in any jurisdiction
                    that does not give effect to all provisions of these Terms of Use, including but without limitation to this
                    paragraph.</a></p>
                <p style={{ textIndent: '0pt', textAlign: 'left' }}><br /></p>
                <h1 style={{ paddingLeft: '61pt', textIndent: '0pt', textAlign: 'left' }}><a name="bookmark2">Severability</a></h1>
                <p style={{ paddingTop: '7pt', paddingLeft: '61pt', textIndent: '0pt', textAlign: 'justify' }}><a name="bookmark3">If any of
                    the provisions of this Agreement or part thereof is rendered void, illegal or unenforceable in any respect
                    under any law, the validity, legality and enforceability of the remaining provisions shall not in any way be
                    affected or impaired thereby.</a></p>
                <p style={{ textIndent: '0pt', textAlign: 'left' }}><br /></p>
                <h1 style={{ paddingLeft: '61pt', textIndent: '0pt', textAlign: 'left' }}><a name="bookmark4">Headings</a></h1>
                <p style={{ paddingTop: '7pt', paddingLeft: '61pt', textIndent: '0pt', textAlign: 'justify' }}>Headings of the sections of
                    these Terms of Use are inserted for convenience reasons only and shall not constitute a part hereof or affect in
                    any way the meaning or interpretation of the content of the respective sections.</p>
            </div>

        </>
    )
}

export default TermsCond



