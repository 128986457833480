import React, { useState, useEffect } from 'react'
import LocationOnSharpIcon from '@mui/icons-material/LocationOnSharp';
import PhoneInTalkSharpIcon from '@mui/icons-material/PhoneInTalkSharp';
import EmailSharpIcon from '@mui/icons-material/EmailSharp';
import googleMapReact from 'google-map-react';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import FeedIcon from '@mui/icons-material/Feed';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import LanguageIcon from '@mui/icons-material/Language';
import axios from 'axios';
import { useDispatch } from 'react-redux';
import validator from 'validator'
import Swal from 'sweetalert2';


const Contact = () => {
    const dispatch = useDispatch();
    const [windowidth, setwindowwidth] = useState(window.innerWidth);
    const [Email, setEmail] = useState('');
    const [comments, setcomments] = useState('');
    const [name, setname] = useState('');


    useEffect(() => {
        document.title = 'JCB Spare Parts - Earth Moving Equipment Manufacturer | Seetech Parts, Faridabad'
        dispatch({ type: 'SaveRoutes', payload: { route: "contact" } });

        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });

        const handleWindowsize = () => {
            // console.log(window.innerWidth)
            setwindowwidth(window.innerWidth);
        }

        window.addEventListener('resize', handleWindowsize);
        return () => {
            window.removeEventListener('resize', handleWindowsize)
        }

    }, [])

    const location = {
        address: '5L, 4, Block A, New Industrial Twp 5, New Industrial Township, Faridabad, Haryana 121001',
        lat: 28.404684252303873,
        lng: 77.30510302302544,
    }

    const handleSubmit = () => {
        // console.log(name, Email, comments)

        // let arr = [
        //     {
        //         name: name,
        //         email: Email
        //     }
        // ];
        if (validator.isEmail(Email)) {
            if (name != "") {
                if (comments != "") {
                    // console.log(Email,name,comments);

                    let fd = new FormData();
                    fd.append("EmailID", Email);
                    fd.append("Name", name);
                    fd.append("Comment", comments);


                    console.log(fd);

                    axios({
                        mode: 'cors',
                        method: "POST",
                        // headers: { "Content-Type": "application/json" },
                        headers: { "Content-Type": "multipart/form-data" },
                        url: `https://seetechparts.com/Contactus.php`,
                        data: fd,
                    })
                        .then((res) => {
                            console.log(res);
                            Swal.fire({
                                position: 'center',
                                icon: 'success',
                                title: `Email Sent Successfully`,
                                showConfirmButton: false,
                                timer: 1500

                            }).then(()=>{
                                setname('');
                                setEmail('');
                                setcomments('');
                            })
                        });
                }
                else {
                    Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Please write some comments!',
                    })
                }
            }
            else {
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'Please fill name!',
                })
            }
        }  else{
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Please enter valid email address',
            })
        }
    }

    return (
        <>
            <div style={{ width: '90%', height: 'auto', margin: 'auto' }}>
                <div style={{ width: '100%', marginTop: '20px' }}>
                    <h3 style={{ borderBottom: '2px solid #f9b101' }}>Help & Support</h3>
                </div>
                <div style={{ width: '100%', height: 'auto', backgroundColor: '#e3e3e3' }}>
                    <div className='contactform2'>
                        <div className='form1'>
                            <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                                <div>
                                    <span ><LocationOnSharpIcon style={{ height: '30px', width: '40px' }} /></span>
                                </div>
                                <div>
                                    <h6 style={{ marginBottom: '0' }}>Locations:</h6>
                                    <span style={{ fontSize: '0.9rem' }}>Plot No. 76, Sector 58, Faridabad Haryana, India – 121004</span>
                                </div>
                            </div>
                            <div style={{ display: 'flex', alignItems: 'center', gap: '10px', marginTop: '15px' }}>
                                <div>
                                    <span ><PhoneInTalkSharpIcon style={{ height: '30px', width: '40px' }} /></span>
                                </div>
                                <div>
                                    <h6 style={{ marginBottom: '0' }}>Phone:</h6>
                                    <span style={{ fontSize: '0.9rem' }}>+91-9810502640</span>
                                </div>
                            </div>
                            <div style={{ display: 'flex', alignItems: 'center', gap: '10px', marginTop: '15px' }}>
                                <div>
                                    <span ><EmailSharpIcon style={{ height: '30px', width: '40px' }} /></span>
                                </div>
                                <div>
                                    <h6 style={{ marginBottom: '0' }}>Email:</h6>
                                    <span style={{ fontSize: '0.9rem' }}>ankush@seetech.in</span>
                                </div>
                            </div>
                        </div>

                        <div className='form2'>
                            <div style={{ width: '90%', margin: 'auto', padding: '10px' }}>
                                <div style={{ marginBottom: '10px' }}>
                                    <input className='contactinput' type="text" placeholder='Email' value={Email} onChange={(e) => { setEmail(e.target.value) }} ></input>
                                </div>
                                <div style={{ marginBottom: '10px' }}>

                                    <input className='contactinput' type="text" placeholder='Name' value={name} onChange={(e) => { setname(e.target.value) }} ></input>
                                </div>
                                <div style={{ marginBottom: '10px' }}>
                                    <textarea className='contactinput' style={{ minHeight: '100px', maxHeight: '100px', maxWidth: '100%', minWidth: '100%' }} type="text" placeholder='Comment'
                                        value={comments} onChange={(e) => { setcomments(e.target.value) }}  ></textarea>
                                </div>
                                <div style={{ display: 'flex', justifyContent: 'center' }}>
                                    <span className='submitbtn' onClick={() => { handleSubmit() }}><b>Submit</b></span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Contact