import React, { Component } from 'react'
import Table from 'react-bootstrap/Table'
import './TableStyle.css'
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import Tooltip from '@mui/material/Tooltip';
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import PaginationBar from './PaginationBar'
import TimeFormatter from '../newTimeformatter/TimeFormatter';
import SyncIcon from '@mui/icons-material/Sync';
import { Modal, Spinner } from 'react-bootstrap';
import moment from 'moment';
import Hexapi from '../HexAPI/Hexapi';
import Swal from 'sweetalert2';
// import DoneOutlineIcon from '@mui/icons-material/DoneOutline';
import DoneIcon from '@mui/icons-material/Done';
// import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { autocompleteClasses } from '@mui/material';
import { Input } from "../HexInput/InputBox";
import EditIcon from '@mui/icons-material/Edit';

var oldData = []

export default class Datatable extends Component {
    constructor() {
        super()
        this.audioRef = React.createRef()
        this.state = {
            rowData: [], isDataFound: false, currentPaginationPage: 0, showPaginationRows: 0,
            isShowSearchBox: false, searchValue: '', Status: false, oldData: '',
            rowuserid: '', joinus: '', origin: '', origindata: [], joindata: [], membership: false,
            windowwidth: window.innerWidth, OpenAddform: false, schemeid: '', schemename: '', oempackingpercentage: '',
            oemservicecharge: '', oemspecialdiscount: '', aftermarketpackingcharge: '', aftermarketservicecharge: '',
            aftermarketspecialdiscount: '', EditUser: false,
        }
    }

    componentDidMount() {



        const updatedData = this.props.data.map((item) => (
            { Edit: item.edit, ...item }
        ))
        oldData = updatedData
        this.setState({ rowData: updatedData, isDataFound: true })
        // this.setState({ rowData: this.props.data, isDataFound: true })
        // oldData = this.props.data
        this.props.pagination && this.setState({ showPaginationRows: this.props.pagination.rowsPerPage })

        let obj = {
            'query': `[dbo].[Seetech_Web_Proc_ApprovalForm_GetData]`,
            'queryArr': []
        }
        Hexapi(obj).then((res) => {
            console.log(res)
            this.setState({ joindata: res['usertype'], origindata: res["origin"] });
        })

        window.addEventListener('resize', this.handleWindowSize);
    }


    handleWindowSize = () => {
        this.setState({ windowwidth: window.innerWidth });
    }


    componentWillUnmount() {
        window.removeEventListener('resize', this.handleWindowSize);
    }



    getHeader(data) {
        const header = Object.keys(data[0])
        return header.map((s) => {
            // console.log(heading)
            {



                // if (s !== "recid") {
                if (s == "registeredcontactnumber") {
                    return <th>Registered Contact Number</th>
                }

                return (
                    <th>{s}</th>


                )
            }
        }
            // }
        )
    }
    clearSearch(e) {
        this.setState({ rowData: oldData, isDataFound: true, isShowSearchBox: false, searchValue: '' })
    }
    setDate(date) {
        if (date != undefined) {
            // return date.split(' ')[0]
            // console.log(date)
            var transformdate = date.split(".")[0];
            // console.log(transformdate)
            //   console.log(date);
            var exitdate = TimeFormatter(transformdate);
            // console.log(exitdate);
            var visitorinfo = this.state.visitorinfo
            //   console.log(visitorinfo)
            // console.log(this.state.visitorinfo)
            var newReverseDate =
                exitdate.ShiftTime +
                "," +
                exitdate.userSplitedDate[2] +
                "-" +
                exitdate.ShiftMonth +
                "-" +
                exitdate.CurYear.toString().slice(-2);
            // console.log(newReverseDate);

            return newReverseDate;
        }
    }


    // refreshtable = () => {
    //     this.props.callback(true);
    // }




    getRowsData(data) {
        // console.log(data)
        var newTableData = [].concat(data)
        let newData = newTableData
        if (this.props.pagination) {
            newData = newTableData.filter((row, index) => {
                let rowPerPage = this.state.showPaginationRows < 0 ? newTableData.length : this.state.showPaginationRows
                let start = this.state.currentPaginationPage * rowPerPage
                let end = (this.state.currentPaginationPage + 1) * rowPerPage
                if (index >= start && index < end) return true
            })
        }
        return newData.map((row, i) => {
            return (
                <tr key={i} onClick={() => {
                    // this.setState({membership:true});
                    this.props.onRowClick(row)
                }} >

                    {
                        Object.keys(row).map((heading) => {
                            if (row[heading] == null) {
                                row[heading] = ''
                            }

                            if (typeof row[heading] != 'object' && heading != "status" && heading != "Edit") {
                                return <td key={row} dangerouslySetInnerHTML={{ __html: row[heading] }} />
                            }
                            else if (heading == "Edit") {
                                return <td key={row} >
                                    <span onClick={() => { this.editschemedata(row); }}>
                                        <EditIcon style={{color:'rgb(66 137 165)'}}/>
                                    </span>
                                </td>
                            }
                            else if (heading == "status") {
                                if (row["status"] == 0) {
                                    return (
                                        <td key={row}>
                                            <span onClick={() => { this.Approvedrequest(row["recid"]); }}> <DoneIcon /> </span>
                                            <span onClick={() => { this.Rejectrequest(row["recid"]) }}> <CloseIcon />  </span>
                                        </td>
                                    )
                                }
                                else if (row["status"] == 1) {
                                    return <td key={row} >Approved</td>
                                }
                            }
                            else {
                                return <td key={row} dangerouslySetInnerHTML={{ __html: this.setDate(row[heading].date) }} />
                            }
                        }
                        )
                    }
                </tr>
            )
        })
    }

    editschemedata = (data) => {
        console.log(data);
        this.setState({
            schemeid: data.recid, schemename: data.schemename, oempackingpercentage: data.oempackingpercentage, oemservicecharge: data.oemservicecharge,
            oemspecialdiscount: data.oemspecialdiscount, aftermarketpackingcharge: data.aftermarketpackingcharge, aftermarketservicecharge: data.aftermarketservicecharge,
            aftermarketspecialdiscount: data.aftermarketspecialdiscount
        })
        this.setState({ EditUser: true })
    }

    EditScheme = () => {

        // console.log(this.state.schemename , this.state.oempackingpercentage, this.state.oemservicecharge , this.state.oemspecialdiscount, this.state.aftermarketpackingcharge,
        // this.state.aftermarketservicecharge, this.state.aftermarketspecialdiscount )

        if (this.state.schemename != "" && this.state.oempackingpercentage != "" && this.state.oemservicecharge != "" && this.state.oemspecialdiscount != "" && this.state.aftermarketpackingcharge != "" &&
            this.state.aftermarketservicecharge != "" && this.state.aftermarketspecialdiscount != "") {
            // console.warn(typeof this.state.oempackingpercentage)
            let obj = {
                'query': `[dbo].[Seetech_Web_Proc_Scheme_Edit]  @SchemeID='{0}',
                @SchemeName='{1}', @OEMPackingPercentage='{2}', @OEMServiceCharge='{3}', @OEMSpecialDiscount='{4}', @AfterMarketPackingCharge='{5}',
                 @AfterMarketServiceCharge='{6}', @AfterMarketSpecialDiscount='{7}'`,
                'queryArr': [`${this.state.schemeid}`, `${this.state.schemename}`, `${this.state.oempackingpercentage}`, `${this.state.oemservicecharge}`, `${this.state.oemspecialdiscount}`, `${this.state.aftermarketpackingcharge}`
                    , `${this.state.aftermarketservicecharge}`, `${this.state.aftermarketspecialdiscount}`]
            }
            Hexapi(obj).then((res) => {
                console.log(res)
                this.props.callback(true)
            });

        } else {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Please fill all details',
            })
        }
    }


    AddData = (userid) => {
        if (this.state.schemename != "" && this.state.oempackingpercentage != "" && this.state.oemservicecharge != "" && this.state.oemspecialdiscount != "" && this.state.aftermarketpackingcharge != "" &&
            this.state.aftermarketservicecharge != "" && this.state.aftermarketspecialdiscount != "") {
            console.warn(typeof this.state.oempackingpercentage)
            let obj = {
                'query': `[dbo].[Seetech_Web_Proc_Scheme_Add]
                @SchemeName='{0}', @OEMPackingPercentage='{1}', @OEMServiceCharge='{2}', @OEMSpecialDiscount='{3}', @AfterMarketPackingCharge='{4}',
                 @AfterMarketServiceCharge='{5}', @AfterMarketSpecialDiscount='{6}'`,
                'queryArr': [`${this.state.schemename}`, `${this.state.oempackingpercentage}`, `${this.state.oemservicecharge}`, `${this.state.oemspecialdiscount}`, `${this.state.aftermarketpackingcharge}`
                    , `${this.state.aftermarketservicecharge}`, `${this.state.aftermarketspecialdiscount}`]
            }
            Hexapi(obj).then((res) => {
                console.log(res)
                this.props.callback(true)
            });

        } else {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Please fill all details',
            })
        }

        // Swal.fire({
        //     text: " Are youm sure to Approve User Order!",
        //     icon: 'warning',
        //     showCancelButton: true,
        //     confirmButtonColor: '#3085d6',
        //     cancelButtonColor: '#d33',
        //     confirmButtonText: 'Yes',
        //     customClass: {
        //         container: 'custom-swal-container', // Add a custom class to the container
        //     },
        // }).then((result) => {
        //     if (result.isConfirmed) {
        //         let obj = {
        //             // 'query': `[dbo].[Seetech_Web_Proc_Order_GetData] @OrderID='{0}'`,
        //             'queryArr': [`${userid}`]
        //         }
        //         Hexapi(obj).then((res) => {
        //             console.log(res)
        //             this.props.callback(true)
        //         });
        //     }
        // });
    }

    Rejectrequest = (userid) => {
        Swal.fire({
            text: " Are you want to Reject User!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes'
        }).then((result) => {
            if (result.isConfirmed) {
                let obj = {
                    // 'query': `[dbo].[Seetech_Web_Proc_Order_GetData]  @UserID='{0}'`,
                    'queryArr': [`${userid}`]
                }
                Hexapi(obj).then((res) => {
                    console.log(res)
                    this.props.callback(true)
                });
            }
        })
    }


    storeSearch(e) {
        // console.log(oldData)
        let value = e.target.value
        this.setState({ searchValue: value })
        let applyFilter = Object.keys(oldData[0])

        // console.log(value)
        // console.log(applyFilter)
        if (value == '') {
            if (oldData.length > 0) {
                console.log(oldData)
                this.setState({ rowData: oldData, isDataFound: true })
                // console.log(rowData)
            } else {
                this.setState({ isDataFound: false })
            }
        } else {
            if ((oldData.filter((row) => {
                if (Array.isArray(applyFilter)) {
                    let StrArr = []
                    for (let i = 0; i < applyFilter.length; i++) {
                        StrArr.push(row[`${applyFilter[i]}`])
                        // console.log(StrArr.join(' ').toString().toLowerCase().includes(value.toLowerCase()))
                        // console.log(StrArr)
                    }
                    return StrArr.join(' ').toString().toLowerCase().includes(value.toLowerCase())
                } else {
                    return (oldData[`${applyFilter[0]}`]).toString().toLowerCase().includes(value.toLowerCase())
                }
            })).length > 0) {
                this.setState({
                    rowData: (oldData.filter((row) => {
                        if (Array.isArray(applyFilter)) {
                            let StrArr = []
                            for (let i = 0; i < applyFilter.length; i++) {
                                StrArr.push(row[`${applyFilter[i]}`])
                                // console.log(StrArr)
                            }

                            return StrArr.join(' ').toString().toLowerCase().includes(value.toLowerCase())
                            // console.log(StrArr)
                        } else {
                            return (oldData[`${applyFilter[0]}`]).toString().toLowerCase().includes(value.toLowerCase())()
                            // console.log(StrArr)
                        }
                    }))
                    , isDataFound: true
                }, () => {
                    this.setState({ currentPaginationPage: 0 })
                })


            } else {
                this.setState({ isDataFound: false })
            }
        }
    }




    render() {
        console.log(this.state.windowwidth)
        const boxstyle = this.state.windowwidth < 482 ?
            {
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: '75%',
                bgcolor: 'background.paper',
                // border: '2px solid #000',
                borderRadius: '10px',
                boxShadow: 24,
                p: 4,
                margin: 'auto',
            } : {
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: 400,
                bgcolor: 'background.paper',
                // border: '2px solid #000',
                borderRadius: '10px',
                boxShadow: 24,
                p: 4,
            };
        // console.log(this.props.pagination)
        return (
            <>
                {/* dangerouslySetInnerHTML={__html: this.state.html} */}
                <div style={{ height: 'calc(78vh - 5px)' }}>


                    <div style={{ position: 'relative', height: "inherit" }}>
                        {
                            this.props.isNavbar &&
                            <div className="_tablenavbar">
                                <div className="_left">
                                    <span>{this.props.heading}</span>
                                </div>
                                <div className="_right">
                                    <Tooltip title="Refresh">
                                        <IconButton style={{ color: '#fff' }} onClick={() => { this.setState({ isDataFound: false }); this.props.callback(true); }} >
                                            <SyncIcon />
                                        </IconButton>
                                    </Tooltip>
                                    {/* <SyncIcon onClick={() => { this.props.callback(true); this.setState({ isDataFound: false }) }} /> */}

                                    <input type="text" id="searchBarFocus" autoFocus={true} value={this.state.searchValue} style={{ width: this.state.isShowSearchBox ? "250px" : '0px', padding: this.state.isShowSearchBox ? "6px 12px" : "6px 0px", marginLeft: this.state.isShowSearchBox ? "10px" : '0px' }} className="editable" placeholder='Search...' onChange={(e) => this.storeSearch(e)} />
                                    {
                                        this.state.isShowSearchBox ?
                                            <Tooltip title="Close Search">
                                                <IconButton style={{ color: '#fff' }} onClick={() => this.clearSearch()}>
                                                    <CloseIcon />
                                                </IconButton>
                                            </Tooltip>

                                            :
                                            <Tooltip title="Open Search">
                                                <IconButton style={{ color: '#fff' }} onClick={() => this.setState({ isShowSearchBox: true }, () => {
                                                    if (document.getElementById('searchBarFocus')) {
                                                        document.getElementById('searchBarFocus').focus()
                                                    }
                                                })}>
                                                    <SearchIcon />
                                                </IconButton>
                                            </Tooltip>

                                    }
                                    <button style={{ background: ' #d0840c', border: 'none', borderRadius: '5px', color: 'white', padding:'3px 5px' }} onClick={(e) => { this.setState({ OpenAddform: true }) }}>Add+</button>
                                    {/* <div> */}
                                    {/* </div> */}
                                    {/* <Tooltip title={'Filter'}>
                                    <IconButton style={{ color: '#fff' }}>
                                        <FilterAltIcon />
                                    </IconButton>
                                </Tooltip> */}
                                </div>
                            </div>
                        }
                        <Table id='_tableGrid' className={'table-hover'} responsive style={{ width: '100%' }} >
                            <thead style={this.props.headStyle} >
                                {/* style={this.props.headStyle}} */}
                                <tr>
                                    {this.state.isDataFound &&
                                        this.state.rowData.length > 0 && this.getHeader(this.state.rowData)}
                                </tr>
                            </thead>
                            {

                                this.state.isDataFound ?
                                    this.state.rowData.length > 0
                                        ?
                                        <tbody>
                                            {
                                                this.getRowsData(this.state.rowData)
                                            }
                                        </tbody>
                                        :
                                        <tbody>
                                            <div style={{ marginTop: '10%' }}>
                                                <h3>No Data Found!</h3>
                                            </div>
                                        </tbody>
                                    :
                                    <tbody>
                                        <div style={{ marginTop: '10%' }}>
                                            <h3>
                                                <Spinner animation='border' style={{color:'#f9b101'}}/>
                                            </h3>
                                        </div>
                                    </tbody>

                            }
                        </Table>
                        {
                            this.props.pagination &&
                            this.state.isDataFound &&
                            <div className="_tablefooter">

                                <PaginationBar
                                    rowsPerPage={this.props.pagination.rowsPerPage}
                                    count={this.state.rowData.length}
                                    rowsPerPageOptions={this.props.pagination.rowsPerPageOptions}
                                    onPageChange={(currentPage) => this.setState({ currentPaginationPage: currentPage })}
                                    onRowsChange={(rows) => this.setState({ showPaginationRows: rows, currentPaginationPage: 0 })}
                                />
                            </div>
                        }

                    </div>
                </div>


                <Modal id="documentmodal" centered animation={true} fullscreen={true} show={this.state.OpenAddform} onHide={() => this.setState({ OpenAddform: false })} style={{ backgroundColor: 'rgba(0, 0, 0, 0.9)' }}>
                    <Modal.Header closeButton>
                        <Modal.Title>Add Scheme </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div class="row" style={{ width: '95%', margin: 'auto' }}>

                            <div class='row'>
                                {/* <div class='col-md-6'>
                                    <Input
                                        label="SchemeID "
                                        placeholder=" Enter SchemeID "
                                        required={true}
                                        // disabled={disablebuttons}
                                        onChange={(e) => { this.setState({ schemeid : e.target.value}); }}
                                        labelStyle={{ fontWeight: "600" }}
                                        // wordboundation={{ limit: 10, id: "contactnumberedit" }}
                                        value={this.state.schemeid}
                                    />
                                </div> */}
                                <div class='col-md-6' style={{ marginTop: '20px' }}>
                                    <label style={{ fontSize: '0.8rem', fontWeight: '500' }}>SchemeName<span style={{ color: 'red' }}> *</span></label>
                                    <input className='schemefields'  placeholder='Enter scheme name' value={this.state.schemename}
                                        onChange={(e) => { this.setState({ schemename: e.target.value }) }}></input>

                                </div>
                                <div class='col-md-6' style={{ marginTop: '20px' }}>
                                    <label style={{ fontSize: '0.8rem', fontWeight: '500' }}>OEM Packing Percentage<span style={{ color: 'red' }}> *</span></label>
                                    <input className='schemefields'  placeholder='Enter oem packing percentage ' value={this.state.oempackingpercentage}
                                        onChange={(e) => { this.setState({ oempackingpercentage: e.target.value }) }}></input>

                                </div>
                            </div>

                            <div class='row'>
                                <div class='col-md-6' style={{ marginTop: '20px' }}>
                                    <label style={{ fontSize: '0.8rem', fontWeight: '500' }}>OEM Service Charge <span style={{ color: 'red' }}> *</span></label>
                                    <input className='schemefields'  placeholder='Enter oem service charge ' value={this.state.oemservicecharge}
                                        onChange={(e) => { this.setState({ oemservicecharge: e.target.value }) }}></input>

                                </div>
                                <div class='col-md-6' style={{ marginTop: '20px' }}>
                                    <label style={{ fontSize: '0.8rem', fontWeight: '500' }}>OEM Special Discount <span style={{ color: 'red' }}> *</span></label>
                                    <input className='schemefields'  placeholder='Enter oem special discount' value={this.state.oemspecialdiscount}
                                        onChange={(e) => { this.setState({ oemspecialdiscount: e.target.value }) }}></input>

                                </div>
                            </div>
                            <div class='row'>
                                <div class='col-md-6' style={{ marginTop: '20px' }}>
                                    <label style={{ fontSize: '0.8rem', fontWeight: '500' }}>After Market Packing Charge<span style={{ color: 'red' }}> *</span></label>
                                    <input className='schemefields'  placeholder='Enter after market packing charge' value={this.state.aftermarketpackingcharge}
                                        onChange={(e) => { this.setState({ aftermarketpackingcharge: e.target.value }) }}></input>

                                </div>
                                <div class='col-md-6' style={{ marginTop: '20px' }}>
                                    <label style={{ fontSize: '0.8rem', fontWeight: '500' }}>After Market Service Charge  <span style={{ color: 'red' }}> *</span></label>
                                    <input className='schemefields'  placeholder='Enter after market service charge' value={this.state.aftermarketservicecharge}
                                        onChange={(e) => { this.setState({ aftermarketservicecharge: e.target.value }) }}></input>

                                </div>
                            </div>
                            <div class='col-md-6' style={{ marginTop: '20px' }}>
                                <label style={{ fontSize: '0.8rem', fontWeight: '500' }}>After Market Special Discount <span style={{ color: 'red' }}> *</span></label>
                                <input className='schemefields'  placeholder='Enter after market special discount' value={this.state.aftermarketspecialdiscount}
                                    onChange={(e) => { this.setState({ aftermarketspecialdiscount: e.target.value }) }}></input>

                            </div>
                            <div class="row" id="Submitbtn" style={{ marginTop: '20px' }}>
                                <div style={{ display: 'flex', justifyContent: 'center' }}>
                                    <button style={{ color: 'white', margin: '8px 0px', padding: '10px 30px', backgroundColor: 'black', borderRadius: '10px' }} onClick={() => { this.AddData() }}>Submit</button>
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>


                {/* EditScheme */}
                <Modal id="documentmodal" centered animation={true} fullscreen={true} show={this.state.EditUser} onHide={() => this.setState({ EditUser: false })} style={{ backgroundColor: 'rgba(0, 0, 0, 0.9)' }}>
                    <Modal.Header closeButton>
                        <Modal.Title>Edit Scheme </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div class="row" style={{ width: '95%', margin: 'auto' }}>

                            <div class='row'>
                                <div class='col-md-6' style={{ marginTop: '20px' }}>
                                    <label style={{ fontSize: '0.8rem', fontWeight: '500' }}>SchemeID<span style={{ color: 'red' }}> *</span></label>
                                    <input className='schemefields' type='number' placeholder=' Enter SchemeID' value={this.state.schemeid}
                                        onChange={(e) => { this.setState({ schemeid: e.target.value }) }}></input>

                                    {/* <Input
                                        label="SchemeID "
                                        placeholder=" Enter SchemeID "
                                        required={true}
                                        onChange={(e) => { this.setState({ schemeid: e.target.value }); }}
                                        labelStyle={{ fontWeight: "600" }}
                                        value={this.state.schemeid}
                                    /> */}
                                </div>
                                <div class='col-md-6' style={{ marginTop: '20px' }}>
                                    <label style={{ fontSize: '0.8rem', fontWeight: '500' }}>SchemeName<span style={{ color: 'red' }}> *</span></label>
                                    <input className='schemefields' type='text'  placeholder='Enter scheme name' value={this.state.schemename}
                                        onChange={(e) => { this.setState({ schemename: e.target.value }) }}></input>

                                    {/* <Input
                                    type={"text"}
                                    label="SchemeName"
                                    placeholder=" Enter scheme name"
                                    required={true}
                                    labelStyle={{ fontWeight: "600" }}
                                    value={this.state.schemename}
                                    onChange={(e) => { this.setState({ schemename: e.target.value }) }}
                                    /> */}
                                </div>
                            </div>
                            <div class='row'>
                                <div class='col-md-6' style={{ marginTop: '20px' }}>
                                    <label style={{ fontSize: '0.8rem', fontWeight: '500' }}>OEM Packing Percentage<span style={{ color: 'red' }}> *</span></label>
                                    <input className='schemefields'  type='number' placeholder='Enter oem packing percentage ' value={this.state.oempackingpercentage}
                                        onChange={(e) => { this.setState({ oempackingpercentage:e.target.value }) }}></input>

                                    {/* <Input
                                        type={"number"}
                                        label="OEM Packing Percentage  "
                                        placeholder=" Enter oem packing percentage  "
                                        required={true}
                                        // disabled={disablebuttons}
                                        onChange={(e) => { this.setState({ oempackingpercentage: Number(e.target.value) }); }}
                                        labelStyle={{ fontWeight: "600" }}
                                        // wordboundation={{ limit: 10, id: "contactnumberedit" }}
                                        value={this.state.oempackingpercentage}
                                    /> */}
                                </div>
                                <div class='col-md-6' style={{ marginTop: '20px' }}>
                                    <label style={{ fontSize: '0.8rem', fontWeight: '500' }}>OEM Service Charge <span style={{ color: 'red' }}> *</span></label>
                                    <input className='schemefields'  type='number' placeholder='Enter oem service charge ' value={this.state.oemservicecharge}
                                        onChange={(e) => { this.setState({ oemservicecharge: e.target.value }) }}></input>

                                    {/* <Input
                                        type={"number"}
                                        label="OEM Service Charge "
                                        placeholder=" Enter oem service charge "
                                        required={true}
                                        labelStyle={{ fontWeight: "600" }}
                                        value={this.state.oemservicecharge}
                                        // disabled={disablebuttons}
                                        onChange={(e) => { this.setState({ oemservicecharge: Number(e.target.value) }) }}
                                    /> */}
                                </div>
                            </div>
                            <div class='row'>
                                <div class='col-md-6' style={{ marginTop: '20px' }}>
                                    <label style={{ fontSize: '0.8rem', fontWeight: '500' }}>OEM Special Discount <span style={{ color: 'red' }}> *</span></label>
                                    <input className='schemefields'  type='number' placeholder='Enter oem special discount' value={this.state.oemspecialdiscount}
                                        onChange={(e) => { this.setState({ oemspecialdiscount: e.target.value }) }}></input>

                                    {/* <Input
                                        type={"number"}
                                        label="OEM Special Discount  "
                                        placeholder=" Enter oem special discount  "
                                        required={true}
                                        // disabled={disablebuttons}
                                        onChange={(e) => { this.setState({ oemspecialdiscount: Number(e.target.value) }); }}
                                        labelStyle={{ fontWeight: "600" }}
                                        // wordboundation={{ limit: 10, id: "contactnumberedit" }}
                                        value={this.state.oemspecialdiscount}
                                    /> */}
                                </div>
                                <div class='col-md-6' style={{ marginTop: '20px' }}>
                                    <label style={{ fontSize: '0.8rem', fontWeight: '500' }}>After Market Packing Charge<span style={{ color: 'red' }}> *</span></label>
                                    <input className='schemefields'  type='number' placeholder='Enter after market packing charge' value={this.state.aftermarketpackingcharge}
                                        onChange={(e) => { this.setState({ aftermarketpackingcharge: e.target.value }) }}></input>

                                    {/* <Input
                                        type={"number"}
                                        label="After Market Packing Charge "
                                        placeholder=" Enter after market packing charge "
                                        required={true}
                                        labelStyle={{ fontWeight: "600" }}
                                        value={this.state.aftermarketpackingcharge}
                                        // disabled={disablebuttons}
                                        onChange={(e) => { this.setState({ aftermarketpackingcharge: Number(e.target.value) }) }}
                                    /> */}
                                </div>
                            </div>
                            <div class='row'>
                                <div class='col-md-6' style={{ marginTop: '20px' }}>
                                    <label style={{ fontSize: '0.8rem', fontWeight: '500' }}>After Market Service Charge  <span style={{ color: 'red' }}> *</span></label>
                                    <input className='schemefields'  type='number' placeholder='Enter after market service charge' value={this.state.aftermarketservicecharge}
                                        onChange={(e) => { this.setState({ aftermarketservicecharge: e.target.value }) }}></input>

                                    {/* <Input
                                        type={"number"}
                                        label="After Market Service Charge  "
                                        placeholder=" Enter after market service charge  "
                                        required={true}
                                        // disabled={disablebuttons}
                                        onChange={(e) => { this.setState({ aftermarketservicecharge: Number(e.target.value) }); }}
                                        labelStyle={{ fontWeight: "600" }}
                                        // wordboundation={{ limit: 10, id: "contactnumberedit" }}
                                        value={this.state.aftermarketservicecharge}
                                    /> */}
                                </div>
                                <div class='col-md-6' style={{ marginTop: '20px' }}>
                                    <label style={{ fontSize: '0.8rem', fontWeight: '500' }}>After Market Special Discount <span style={{ color: 'red' }}> *</span></label>
                                    <input className='schemefields'  type='number' placeholder='Enter after market special discount' value={this.state.aftermarketspecialdiscount}
                                        onChange={(e) => { this.setState({ aftermarketspecialdiscount: e.target.value }) }}></input>


                                    {/* <Input
                                        type={"number"}
                                        label="After Market Special Discount  "
                                        placeholder=" Enter after market special discount  "
                                        required={true}
                                        // disabled={disablebuttons}
                                        onChange={(e) => { this.setState({ aftermarketspecialdiscount: Number(e.target.value) }); }}
                                        labelStyle={{ fontWeight: "600" }}
                                        // wordboundation={{ limit: 10, id: "contactnumberedit" }}
                                        value={this.state.aftermarketspecialdiscount}
                                    /> */}
                                </div>
                            </div>
                            <div class="row" id="Submitbtn" style={{ marginTop: '20px' }}>
                                <div style={{ display: 'flex', justifyContent: 'center' }}>
                                    <button style={{ color: 'white', margin: '8px 0px', padding: '10px 30px', backgroundColor: 'black', borderRadius: '10px' }} onClick={() => { this.EditScheme() }}>Submit</button>
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal >
            </>

        )
    }
}
