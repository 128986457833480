import { combineReducers } from 'redux';

const initialState = {
  Filterdata: "",
  ModalFilterdata:"",
  ModalFilterObject:{},
  SearchData:"",
  UpperFilterCheckbox:{},
  ModalCheckbox:{},
  UpperModalCheckbox:false,
  Filtertext:[],
  PageNo:1,
  PrevRoute:"",
  CartItems:0,
};

const counterReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SaveNormalFilter':
      return { ...state, Filterdata: action.payload.filtervalue };
    case 'SaveModalFilter':
      return { ...state, ModalFilterdata: action.payload.Modalfiltervalue };
    case 'SaveSearch':
      return {  ...state, SearchData: action.payload.SearchVal};
    case 'UpCheckbox':
      return {  ...state, UpperFilterCheckbox: action.payload.filtercheckbox};
    case 'ModalCheckbox':
      return {  ...state, ModalCheckbox: action.payload.modalcheckbox};
    case 'UpperModalCheckbox':
      return {  ...state, UpperModalCheckbox: action.payload.uppercheckbox};
    case 'Filtertext':
      return {  ...state, Filtertext: action.payload.filtertext};
    case 'SavePageNo':
      return {  ...state, PageNo: action.payload.pageno};
    case 'SaveRoutes':
        if(action.payload.route!="shop" && action.payload.route!="Product" && action.payload.route!="cart"){
          return {  ...state, PrevRoute: action.payload.route,PageNo: 1};
        }else{
          return {  ...state, PrevRoute: action.payload.route};
        }
    case 'SaveFilterObject':
      return {  ...state, ModalFilterObject: action.payload.FilterOj};
    case 'CartItems':
      return {  ...state, CartItems: action.payload.CartItems};
    default:
      return state;
  }
};

const rootReducer = combineReducers({
  counter: counterReducer,
});

export default rootReducer;