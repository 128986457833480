import axios from 'axios';

const whatsappnotification = (templateParams, campaign,firstname,phoneno) => {
    console.log(templateParams,"templateparams")
    console.log(campaign,"campaign")
    console.log(firstname,"firstname")
    console.log(phoneno,"phoneno")
    const json = JSON.stringify({
        "apiKey": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjY1OTNhYTg5ZGZiOWNjN2FiODcyYmVhNCIsIm5hbWUiOiJTZWV0ZWNoIFBhcnRzIiwiYXBwTmFtZSI6IkFpU2Vuc3kiLCJjbGllbnRJZCI6IjY1OTNhYTg5ZGZiOWNjN2FiODcyYmU5ZiIsImFjdGl2ZVBsYW4iOiJCQVNJQ19NT05USExZIiwiaWF0IjoxNzA0MTc2MjY1fQ.xsH0Pq97JwcuPgrYahyrgGs8NzZGOIX-wydm9XxLNW8",
        "campaignName": campaign,
        "destination": phoneno,
        "userName": firstname,
        "templateParams": templateParams
    })
    console.log(json,"json")
    axios({
        mode: 'cors',
        method: "POST",
        headers: { "Content-Type": "application/json" },
        url: `https://backend.aisensy.com/campaign/t1/api/v2`,
        data:json
    })
    .then((res) => {
        console.log('Notification sent successfully:', res.data);
    })
    .catch((error) => {
        if (error.response) {
            console.error('error response data:', error.response.data);
            console.error('error response status:', error.response.status);
            console.error('error response headers:', error.response.headers);
        } else if (error.request) {
            console.error('error request data:', error.request);
        } else {
            console.error('error message:', error.message);
        }
        console.error('error config:', error.config);
    });
}
export default whatsappnotification;


