import React, { useState, useEffect, useRef } from 'react'
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import Hexapi from '../usefulcomps/HexAPI/Hexapi';
import { Carousel } from 'react-responsive-carousel';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { Modal, Spinner } from 'react-bootstrap';
import CloseIcon from '@mui/icons-material/Close';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import FavoriteIcon from '@mui/icons-material/Favorite';
import Swal from 'sweetalert2';
import Pagination from '@mui/material/Pagination';
import { ToastContainer, toast } from 'react-toastify';
import Tooltip from '@mui/material/Tooltip';
import "./Shop.css";
import { Link, useParams, } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

const Shop = (props) => {
    const [windowidth, setwindowwidth] = useState(window.innerWidth);
    const [sidefilter, setsidefilter] = useState([]);
    const [modaldata, setmodaldata] = useState({});
    const [modalexpand, setmodalexpand] = useState(false);
    const [expandindex, setexpandindex] = useState('-1');
    const [expandfilters, setfilters] = useState(false)
    const [productsdata, setproducts] = useState([]);
    const [checkedCheckboxes, setCheckedCheckboxes] = useState({});
    const [category, setcategoryfilter] = useState({});
    const [checkmodal, setcheckmodal] = useState({});
    const [modalcategory, setmodalcategory] = useState([]);
    const [modeldata, setmodeldata] = useState(false);
    const [isLoading, setisLoading] = useState(false);
    const [isCheckedvalue, setisCheckedvalue] = useState(null);
    const [Totalpages, setTotalpages] = useState('')
    const [CurrentPage, setCurrentPage] = useState(1)
    const [DataFound, setDataFound] = useState(false);
    const [OverseasINR, setOverseasINR] = useState(false);
    const [Userorigin, setUserorigin] = useState('');
    const [usertype, setusertype] = useState('');
    const [isFilterappliedTrue, setisFilterappliedTrue] = useState(true);
    const [Filterdatavalue, setFilterdatavalue] = useState([]);
    const [Totalvalue, setTotalvalue] = useState(0);
    const [Cartquantity, setCartquantity] = useState([]);
    const [WishlistItem, setWishlistItem] = useState([]);
    const [CartRecID, setCartRecID] = useState([]);
    const dispatch = useDispatch();
    const Reduxfilter = useSelector(state => state.counter.Filterdata);
    const ModalReduxFilter = useSelector(state => state.counter.ModalFilterdata);
    const ReduxSearchData = useSelector(state => state.counter.SearchData);
    const ReduxUpperFilterCheckbox = useSelector(state => state.counter.UpperFilterCheckbox);
    const ReduxModalCheckbox = useSelector(state => state.counter.ModalCheckbox);
    const ReduxUpperModalCheckbox = useSelector(state => state.counter.UpperModalCheckbox);
    const ReduxFiltertext = useSelector(state => state.counter.Filtertext);
    const ReduxPageNo = useSelector(state => state.counter.PageNo);
    const ReduxFilterObj = useSelector(state => state.counter.ModalFilterObject);
    const CartItems = useSelector(state => state.counter.CartItems);
    useEffect(() => {
        document.title = 'JCB Spare Parts - Earth Moving Equipment Manufacturer | Seetech Parts, Faridabad'
    }, [])
    useEffect(() => {
        if (isCheckedvalue) {
            applyfilter();
        } else if (isCheckedvalue == false) {
            clearfilter();
        }
    }, [isCheckedvalue]);
    useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
        let obj1 = {
            'query': `[dbo].[Seetech_Web_Proc_User_Details]@UserID='{0}'`,
            'queryArr': [`${sessionStorage.getItem('userid')}`]
        }
        Hexapi(obj1).then((res) => {
            if (res[""][0]) {
                setUserorigin(res[""][0].origin);
                setusertype(res[""][0].usertype)
            }
        });
        const handleWindowsize = () => {
            setwindowwidth(window.innerWidth);
        }
        window.addEventListener('resize', handleWindowsize);
        return () => {
            window.removeEventListener('resize', handleWindowsize)
        }
    }, [])
    useEffect(() => {
        
    }, [])
    const openitem = (index) => {
        if (index == expandindex) {
            setexpandindex('-1');
        } else {
            setexpandindex(index);
        }
    }
    useEffect(() => {
        let obj = "";
        let obj2 = "";
        if (sessionStorage.getItem('userid') != "" && sessionStorage.getItem('userid') != undefined) {
            obj = {
                'query': `[dbo].[Seetech_Web_Proc_filter_GetData]@UserID='{0}'`,
                'queryArr': [`${sessionStorage.getItem('userid')}`]
            }
        } else {
            obj = {
                'query': `[dbo].[Seetech_Web_Proc_filter_GetData]`,
                'queryArr': []
            }
        }
        Hexapi(obj).then((res) => {
            let dataArray = res["category"];
            let mergedCategories = {};
            dataArray.forEach(item => {
                if (item.category !== '') {
                    if (!mergedCategories[item.category]) {
                        mergedCategories[item.category] = [item.subcategory];
                    } else {
                        mergedCategories[item.category].push(item.subcategory);
                    }
                }
            });
            setsidefilter(mergedCategories)
            Object.keys(sidefilter).map((data) => {
                sidefilter[data] &&
                    sidefilter[data].map((s, index) => {
                    })
            })
            const modalfilterdata = res["model"];
            const mfdata = [];
            Object.keys(modalfilterdata).filter(col => modalfilterdata[col].model != "").map((data) => {
                mfdata.push(modalfilterdata[data].model)
            })
            setmodaldata(mfdata);
            console.log(ReduxFilterObj,'ReduxFilterObj')
            console.log(ReduxFiltertext,'ReduxFiltertext')
            console.log(ReduxModalCheckbox,'ReduxModalCheckbox')
            console.log(ReduxUpperFilterCheckbox,'ReduxUpperFilterCheckbox')
            console.log(ReduxUpperModalCheckbox,'ReduxUpperModalCheckbox')
            console.log(Reduxfilter,'Reduxfilter')
            console.log(ModalReduxFilter,typeof(ModalReduxFilter) ,'ModalReduxFilter')
        });
        setisLoading(true)
        setCurrentPage(ReduxPageNo)
        if (sessionStorage.getItem('userid') != "" && sessionStorage.getItem('userid') != undefined) {
            let obj1 = {
                'query': `[dbo].[Seetech_Web_Proc_User_Details]@UserID='{0}'`,
                'queryArr': [`${sessionStorage.getItem('userid')}`]
            }
            Hexapi(obj1).then((res) => {
                if (res[""][0]) {
                    if (res[""][0].currency == "inr" || res[""][0].currency == "INR") {
                        setOverseasINR(true);
                    } else {
                        setOverseasINR(false);
                    }
                }
            });
        }
        if ((Reduxfilter != "" || ModalReduxFilter != "" || ReduxSearchData != "" || ReduxPageNo != "1")) {
            setFilterdatavalue(ReduxFiltertext);
            setCheckedCheckboxes(ReduxUpperFilterCheckbox);
            setmodeldata(ReduxUpperModalCheckbox)
            setcheckmodal(ReduxModalCheckbox)
            let modelfiterdata = "-1"
            if (ModalReduxFilter != "") {
                modelfiterdata = ModalReduxFilter;
            }
            let filterval = -1;
            if (Reduxfilter != "") {
                setcategoryfilter(ReduxFilterObj);
                filterval = Reduxfilter;
            }
            let searchtext = -1;
            if (ReduxSearchData != "") {
                searchtext = ReduxSearchData;
            }
            dispatch({ type: 'SaveRoutes', payload: { route: "shop" } });
            if (sessionStorage.getItem('userid') != "" && sessionStorage.getItem('userid') != undefined) {
                obj2 = {
                    'query': `[dbo].[Seetech_Web_Proc_Product_GetData]@UserID='{0}',@Filter='{1}', @Model='{2}', @Search='{3}',@Page='{4}'`,
                    'queryArr': [`${sessionStorage.getItem('userid')}`, `${filterval}`, `${modelfiterdata}`, `${searchtext}`, `${ReduxPageNo}`]
                }
            } else {
                obj2 = {
                    'query': `[dbo].[Seetech_Web_Proc_Product_GetData]@Filter='{0}', @Model='{1}', @Search='{2}',@Page='{3}'`,
                    'queryArr': [`${filterval}`, `${modelfiterdata}`, `${searchtext}`, `${ReduxPageNo}`]
                }
            }
            Hexapi(obj2).then((res) => {
                setTotalvalue(res["totalcount"][0].totalcount)
                const pages = Math.ceil(res["totalcount"][0].totalcount / 20);
                setTotalpages(pages);
                if (res[""].length > 0) {
                    let data = res[""];
                    setproducts(data)
                    setDataFound(false);
                } else {
                    setDataFound(true)
                }
                setisLoading(false)
                setisCheckedvalue(null)
            })
        } else {
            if (sessionStorage.getItem('userid') != "" && sessionStorage.getItem('userid') != undefined) {
                obj = {
                    'query': `[dbo].[Seetech_Web_Proc_Product_GetData] @UserID='{0}'`,
                    'queryArr': [`${sessionStorage.getItem('userid')}`]
                }
            } else {
                obj = {
                    'query': `[dbo].[Seetech_Web_Proc_Product_GetData]`,
                    'queryArr': []
                }
            }
            Hexapi(obj).then((res) => {
                if (res["totalcount"]) {
                    setTotalvalue(res["totalcount"][0].totalcount)
                }
                const pages = Math.ceil(res["totalcount"][0].totalcount / 20);
                setTotalpages(pages);
                let itemqty = [];
                let favourite = [];
                let cartId = [];
                res[""].map((item, i) => {
                    if (item.qty > 0) {
                        if (item.moq > 1) {
                            itemqty[i] = item.qty / item.moq;
                        } else {
                            itemqty[i] = item.qty;
                        }
                        cartId.push(item.recid);
                    }
                    if (item.wishlist > 0) {
                        favourite[i] = item.recid;
                    }
                })
                setCartRecID(cartId)
                setWishlistItem(favourite)
                setCartquantity(itemqty);
                if (res[""].length > 0) {
                    let data = res[""];
                    setproducts(data)
                    setDataFound(false);
                } else {
                    setDataFound(true)
                }
                setisLoading(false)
                setisCheckedvalue(null)
            })
        }
    }, [ReduxSearchData,Reduxfilter,ModalReduxFilter,ReduxPageNo])
    const clearupperfilter = (filteritem) => {
        setisLoading(true);
        const filterstop = Object.keys(category).filter((item) => item != filteritem);
        console.log(filteritem)
        const checkboxes = checkedCheckboxes
        checkboxes[filteritem] = false
        console.log(sidefilter[filteritem])
        for(var i=0;i<sidefilter[filteritem].length;i++){
            console.log(sidefilter[filteritem][i])
            console.log(checkboxes[sidefilter[filteritem][i]])
            if(checkboxes[sidefilter[filteritem][i]]){
                checkboxes[sidefilter[filteritem][i]] = false
            }
        }
        console.log(checkboxes)
        setCheckedCheckboxes((prev) => ({
            ...prev,
            [filteritem]: false,
            ...(sidefilter[filteritem] &&
                sidefilter[filteritem].reduce(
                    (acc, s) => ({
                        ...acc,
                        [s]: false,
                    }),
                    {}
                )),
        }));
        console.log(sidefilter)
        setcategoryfilter((prev) => {
            const updatedCategoryFilter = { ...prev };
            delete updatedCategoryFilter[filteritem];
            return updatedCategoryFilter;
        })
        const updatedCategoryFilter = { ...category };
        delete updatedCategoryFilter[filteritem];
        let filterdata = "";
        Object.keys(updatedCategoryFilter).map((data) => {
            updatedCategoryFilter[data].map((s) => {
                filterdata += `${data}$#12$$${s}` + ","
            })
        })
        filterdata = filterdata.slice(0, filterdata.length - 1);
        let modelfilterdata = "";
        if (modalcategory.length > 0) {
            modalcategory.map((data) => {
                modelfilterdata += data + ","
            })
        }
        modelfilterdata = modelfilterdata.slice(0, modelfilterdata.length - 1);
        if (modelfilterdata == "") {
            modelfilterdata = -1;
        }
        if (filterdata == "") {
            filterdata = -1;
        }
            dispatch({ type: 'Filtertext', payload: { filtertext: filterstop} });
            dispatch({ type: 'UpCheckbox', payload: { filtercheckbox: checkboxes } });
            dispatch({ type: 'SaveFilterObject', payload: { FilterOj: updatedCategoryFilter } });
            dispatch({ type: 'SaveNormalFilter', payload: { filtervalue: filterdata } });
            dispatch({ type: 'SaveModalFilter', payload: { Modalfiltervalue: modelfilterdata } });
        setisLoading(false)
    }
    const applyfilter = () => {
        dispatch({ type: 'SaveSearch', payload: { SearchVal: "" } });
        setisLoading(true);
        setfilters(false)
        let filterdata = "";
        dispatch({ type: 'SaveFilterObject', payload: { FilterOj: category } });
        dispatch({ type: 'Filtertext', payload: { filtertext: Object.keys(category) } });
        Object.keys(category).map((data) => {
            category[data].map((s) => {
                filterdata += `${data}$#12$$${s}` + ","
            })
        })
        filterdata = filterdata.slice(0, filterdata.length - 1);
        setFilterdatavalue(Object.keys(category));
        dispatch({ type: 'UpCheckbox', payload: { filtercheckbox: checkedCheckboxes } });
        dispatch({ type: 'SaveNormalFilter', payload: { filtervalue: filterdata } });
        let modelfilterdata = "";
        if (modalcategory.length > 0) {
            modalcategory.map((data) => {
                modelfilterdata += data + ","
            })
        }
        modelfilterdata = modelfilterdata.slice(0, modelfilterdata.length - 1);
        dispatch({ type: 'SaveModalFilter', payload: { Modalfiltervalue: modelfilterdata } });
        setisFilterappliedTrue(true);
    }
    const clearfilter = () => {
        setisLoading(true)
        if (Object.keys(category).length == 0) {
            setisFilterappliedTrue(false);
        }
        let filterdata = "";
        Object.keys(category).map((data) => {
            category[data].map((s) => {
                filterdata += `${data}$#12$$${s}` + ","
            })
        })
        setFilterdatavalue(Object.keys(category));
        dispatch({ type: 'Filtertext', payload: { filtertext: Object.keys(category) } });
        dispatch({ type: 'UpCheckbox', payload: { filtercheckbox: checkedCheckboxes } });
        filterdata = filterdata.slice(0, filterdata.length - 1);
        dispatch({ type: 'SaveNormalFilter', payload: { filtervalue: filterdata } });
        let modelfilterdata = "";
        if (modalcategory.length > 0) {
            modalcategory.map((data) => {
                modelfilterdata += data + ","
            })
        }
        modelfilterdata = modelfilterdata.slice(0, modelfilterdata.length - 1);
        dispatch({ type: 'UpCheckbox', payload: { filtercheckbox: checkedCheckboxes } });
        dispatch({ type: 'SaveModalFilter', payload: { Modalfiltervalue: modelfilterdata } });
    }
    const favnotify = (text) =>
        toast.success(text, {
            position: toast.POSITION.TOP_CENTER, autoClose: 500
        });
    const Removefromwishlist = (id) => {
        let obj = {
            'query': `[dbo].[Seetech_Web_Proc_Wishlist_Remove]@UserID='{0}',  @ProductID='{1}'`,
            'queryArr': [`${sessionStorage.getItem('userid')}`, `${id}`]
        }
        Hexapi(obj).then((res) => {
            favnotify("Item removed from wishlist !")
        });
        let wishitems = [...WishlistItem];
        wishitems = wishitems.filter(item => item != id)
        setWishlistItem(wishitems);
    }
    const Addtowishlist = (id) => {
        let obj = {
            'query': `[dbo].[Seetech_Web_Proc_Wishlist_Add]@UserID='{0}',  @ProductID='{1}'`,
            'queryArr': [`${sessionStorage.getItem('userid')}`, `${id}`]
        }
        Hexapi(obj).then((res) => {
            favnotify("Item added to wishlist !")
        });
        let wishitems = [...WishlistItem];
        wishitems.push(id);
        setWishlistItem(wishitems);
    }
    const clearAllfilter = () => {
        console.log('ClearFilter')
        setisLoading(true)
        setFilterdatavalue([]);
        Object.keys(checkedCheckboxes).map((data) => {
            checkedCheckboxes[data] = false;
        })
        Object.keys(checkmodal).map((data) => {
            checkmodal[data] = false;
        })
        setcategoryfilter({});
        setfilters(false)
        setmodalcategory([]);
        setcheckmodal({});
        setmodeldata(false);
        dispatch({ type: 'SaveFilterObject', payload: { FilterOj: {} } });
        dispatch({ type: 'Filtertext', payload: { filtertext: [] } });
        dispatch({ type: 'UpCheckbox', payload: { filtercheckbox: {} } });
        dispatch({ type: 'SaveNormalFilter', payload: { filtervalue: "" } });
        dispatch({ type: 'SaveModalFilter', payload: { Modalfiltervalue: "" } });
        dispatch({ type: 'ModalCheckbox', payload: { modalcheckbox: "" } });
        setisLoading(false)
    }
    const handleuppercheckbox = (isChecked, data) => {
        console.log(data)
        setCheckedCheckboxes((prev) => ({
            ...prev,
            [data]: isChecked,
            ...(sidefilter[data] &&
                sidefilter[data].reduce(
                    (acc, s) => ({
                        ...acc,
                        [s]: isChecked,
                    }),
                    {}
                )),
        }));
        const subcategoriesArray = sidefilter[data] ? sidefilter[data] : [];
        if (isChecked) {
            setcategoryfilter((prev) => ({
                ...prev,
                [data]: subcategoriesArray,
            }))
        } else {
            setcategoryfilter((prev) => {
                const updatedCategoryFilter = { ...prev };
                delete updatedCategoryFilter[data];
                return updatedCategoryFilter;
            })
        }
        let filterdata = "";
        Object.keys(category).map((data) => {
            category[data].map((s) => {
                filterdata += `${data}$#12$$${s}` + ","
            })
        })
        filterdata = filterdata.slice(0, filterdata.length - 1);
        console.log(category)
        console.log(sidefilter)
        console.log(checkedCheckboxes)
        dispatch({ type: 'UpCheckbox', payload: { filtercheckbox: checkedCheckboxes } });
        dispatch({ type: 'SaveNormalFilter', payload: { filtervalue: filterdata } });
    }
    const handleinnercheckbox = (isChecked, s, data) => {
        setCheckedCheckboxes((prev) => ({
            ...prev,
            [s]: isChecked,
        }));
        if (isChecked) {
            setcategoryfilter((prev) => ({
                ...prev,
                [data]: [...(prev[data] || []), s],
            }))
        } else {
            setcategoryfilter((prev) => ({
                ...prev,
                [data]: (prev[data] || []).filter((item) => item !== s),
            }))
        }
        setCheckedCheckboxes((prev) => {
            const updatedPrev = { ...prev };
            const areAllChildrenChecked = sidefilter[data].every(child => prev[child]);
            if (areAllChildrenChecked) {
                updatedPrev[data] = true;
            } else {
                updatedPrev[data] = false;
            }
            return updatedPrev;
        });
    }
    const cartnotify = () =>
        toast.success('Item updated to successfully', {
            position: toast.POSITION.TOP_CENTER, autoClose: 500
        });
    const AddtoCart = (Id, Quantity, mrp, index, Qty) => {
        if ((Cartquantity[index] != undefined && Cartquantity[index] != 0) || Qty != 0) {
            if (CartRecID.some((it) => it == Id) == false) {
                dispatch({ type: 'CartItems', payload: { CartItems: CartItems + 1 } });
            }
            let qty = Quantity == "" ? Cartquantity[index] : Cartquantity[index] * Quantity;
            if (Cartquantity[index] == undefined) {
                qty = Qty;
            }
            const productprice = mrp.slice(1);
            const price = Number(productprice).toFixed(2);
            if (qty > 0) {
                let Cartid = [...CartRecID];
                Cartid.push(Id)
                setCartRecID(Cartid)
                let obj = {
                    'query': `[dbo].[Seetech_Web_Proc_Cart_Add] @UserID='{0}',@ProductID='{1}',@Qty='{2}',@Price='{3}'`,
                    'queryArr': [`${sessionStorage.getItem('userid')}`, `${Id}`, `${qty}`, `${price}`]
                }
                Hexapi(obj).then((res) => {
                    cartnotify();
                })
            }
            else {
                Swal.fire({
                    icon: 'info',
                    text: 'Quantity cannot be negative',
                    confirmButtonColor: '#f9b101',
                })
            }
        }
        else {
            CartError();
        }
    }
    const CartError = () =>
        toast.error('Please add Quantity !', {
            position: toast.POSITION.TOP_CENTER, autoClose: 500
        });
    return (
        <>
            {
                <div style={{ width: '95%', height: 'auto', margin: 'auto' }}>
                    <div style={{ width: '100%', margin: '0 0 50px 0' }}>
                        <div className='Shopheaderstyle'>
                            <h3 style={{ margin: '0' }}>Service Parts</h3>
                            {
                                    windowidth < 1067 ?
                                        <span onClick={() => { setfilters(!expandfilters) }} >
                                            <FilterAltIcon style={{ color: "#f9b101" }} />
                                        </span>
                                        :
                                        null
                                }
                        </div>
                        {
                            isLoading ?
                                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: '10%', color: 'rgb(235 197 137)', minHeight: '320px' }}>
                                    <h3>
                                        <Spinner animation='border' style={{ color: 'rgb(235 197 137)' }} />
                                    </h3>
                                </div>
                                :
                                <>
                                    <div style={{ width: '100%', display: 'flex', height: 'auto', marginTop: '20px', gap: '40px' }}>
                                        {
                                            windowidth > 1067 ?
                                                <div style={windowidth < 1250 ? { width: '25%', height: 'auto' } : { width: '20%', height: 'auto' }}>
                                                    <div style={{ marginBottom: '10px' }}>
                                                        <span style={{ fontSize: '0.8rem', fontWeight: '500' }}>Showing  {(CurrentPage - 1) * 20} - {(CurrentPage - 1) * 20 + 20 < Totalvalue ? (CurrentPage - 1) * 20 + 20 : Totalvalue} of {Totalvalue} results</span>
                                                    </div>
                                                    <h5>Filters</h5>
                                                    <h6 style={{ marginTop: '20px' }}>Categories</h6>
                                                    <div style={{ display: 'flex', justifyContent: 'flex-end', margin: '5px' }}>
                                                        <span className='apply_filterstyle' style={{ backgroundColor: '#f9b101', cursor: 'pointer', color: 'black', fontWeight: '600', padding: '2px 5px' }} onClick={() => { clearAllfilter() }}>Clear Filter</span>
                                                    </div>
                                                    <div style={{ cursor: 'pointer', padding: '0 10px 10px 10px' }}>
                                                        {
                                                            Object.keys(sidefilter).map((data, i) => {
                                                                return (
                                                                    <>
                                                                        <div key={i} style={(i == expandindex) ? { marginTop: '10px', fontWeight: '600', borderRadius: '5px', backgroundColor: 'rgb(235 197 137)' } : { marginTop: '10px', fontWeight: '600' }} >
                                                                            <div style={(i == expandindex) ? { borderBottom: '1px solid #f9b101', padding: '2px 4px 1px 4px', fontWeight: 'bold', margin: 'auto' } : {}} className='filterstyle' >
                                                                                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                                                                    <div style={{ display: 'flex' }}>
                                                                                        <div>
                                                                                            <input type='checkbox' value={data}
                                                                                                checked={checkedCheckboxes[data]}
                                                                                                className="custom-checkbox"
                                                                                                onChange={(e) => { handleuppercheckbox(e.target.checked, data) }}
                                                                                                onClick={(e) => { if (e.target.checked == true) { setisCheckedvalue(true); } else { setisCheckedvalue(false) }; setisLoading(true) }}
                                                                                            ></input>
                                                                                        </div>
                                                                                        <span onClick={() => { openitem(i) }} style={{ marginLeft: '10px', fontSize: '0.8rem', cursor: 'pointer', }} >{data}</span>
                                                                                    </div>
                                                                                    <div onClick={() => { openitem(i) }}>
                                                                                        <span style={{ cursor: 'pointer' }}> {i == expandindex ? <RemoveIcon style={{ fontSize: '1.2rem' }} /> : <AddIcon style={{ fontSize: '1.2rem' }} />} </span>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            {
                                                                                sidefilter[data] && (i == expandindex) &&
                                                                                sidefilter[data].map((s, index) => {
                                                                                    return (
                                                                                        <div key={i} style={(i == expandindex) ? { paddingTop: '2px', paddingBottom: '2px', paddingLeft: '15px', color: 'white', display: 'flex' } : { backgroundColor: '#d4ede8', display: 'none', paddingTop: '6px', display: 'flex' }} >
                                                                                            <div>
                                                                                                <input type='checkbox' value={s}
                                                                                                    checked={checkedCheckboxes[s]}
                                                                                                    className="custom-checkbox"
                                                                                                    onChange={(e) => {
                                                                                                        handleinnercheckbox(e.target.checked, s, data)
                                                                                                    }}
                                                                                                    onClick={(e) => { if (e.target.checked == true) { setisCheckedvalue(true) } else { setisCheckedvalue(false) }; setisLoading(true) }}
                                                                                                ></input>
                                                                                            </div>
                                                                                            <span style={{ fontSize: '0.8rem', marginLeft: '10px', cursor: 'pointer' }} key={i} className='filteritems'>{s}
                                                                                            </span>
                                                                                        </div>
                                                                                    )
                                                                                })
                                                                            }
                                                                        </div>
                                                                    </>
                                                                )
                                                            })
                                                        }
                                                    </div >
                                                    <div className="modalfilterstyle" style={{ fontSize: '0.9rem' }}>
                                                        <div style={modalexpand ? { display: 'flex', justifyContent: 'space-between', alignItems: 'center', paddingBottom: '5px', borderBottom: '1px solid rgb(191 188 183)' } : { display: 'flex', justifyContent: 'space-between', alignItems: 'center', paddingBottom: '5px', }}>
                                                            <div >
                                                                <input type='checkbox'
                                                                    checked={modeldata}
                                                                    className="custom-checkbox"
                                                                    onChange={(e) => {
                                                                        const isChecked = e.target.checked;
                                                                        dispatch({ type: 'UpperModalCheckbox', payload: { uppercheckbox: isChecked } });

                                                                        if (isChecked) {
                                                                            setmodalcategory(modaldata);
                                                                        } else {
                                                                            setmodalcategory([]);
                                                                        }
                                                                        setmodeldata(isChecked);
                                                                        const updatedCheckModal = {};
                                                                        modaldata.forEach((mdata) => {
                                                                            updatedCheckModal[mdata] = isChecked;
                                                                        });
                                                                        dispatch({ type: 'ModalCheckbox', payload: { modalcheckbox: updatedCheckModal } });
                                                                        setcheckmodal(updatedCheckModal);
                                                                    }}
                                                                    onClick={(e) => { if (e.target.checked == true) { setisCheckedvalue(true) } else { setisCheckedvalue(false) }; setisLoading(true) }}
                                                                ></input>
                                                                <span style={{ marginLeft: '10px', cursor: 'pointer' }} onClick={() => { setmodalexpand(!modalexpand) }}>Models</span>
                                                            </div>
                                                            <div>
                                                                <span onClick={() => { setmodalexpand(!modalexpand) }}>{modalexpand ? <RemoveIcon style={{ fontSize: '1.2rem', cursor: 'pointer' }} /> : <AddIcon style={{ fontSize: '1.2rem', cursor: 'pointer' }} />}  </span>
                                                            </div>
                                                        </div>
                                                        {
                                                            modalexpand && modaldata.map((mdata, index) => {
                                                                return (
                                                                    <>
                                                                        <div key={index} style={{ marginLeft: '15px', marginTop: '10px' }}>
                                                                            <input type='checkbox'
                                                                                checked={checkmodal[mdata]}
                                                                                className="custom-checkbox"
                                                                                value={mdata}
                                                                                onChange={(e) => {
                                                                                    const isChecked = e.target.checked;
                                                                                    setcheckmodal((prev) => ({
                                                                                        ...prev,
                                                                                        [mdata]: isChecked,
                                                                                    }));
                                                                                    setmodalcategory((prev) => {
                                                                                        if (isChecked) {
                                                                                            return [...prev, mdata];
                                                                                        } else {
                                                                                            return prev.filter((col) => col !== mdata);
                                                                                        }
                                                                                    });
                                                                                    const updatedCheckmodal = { ...checkmodal, [mdata]: isChecked };
                                                                                    dispatch({ type: 'ModalCheckbox', payload: { modalcheckbox: updatedCheckmodal } });
                                                                                    const areAllModelsChecked = modaldata.every((m) => updatedCheckmodal[m]);
                                                                                    dispatch({ type: 'UpperModalCheckbox', payload: { uppercheckbox: areAllModelsChecked } });
                                                                                    setmodeldata(areAllModelsChecked);
                                                                                }}
                                                                                onClick={(e) => { if (e.target.checked == true) { setisCheckedvalue(true) } else { setisCheckedvalue(false) }; setisLoading(true) }}
                                                                            ></input>
                                                                            <span style={{ marginLeft: '10px', cursor: 'pointer' }} >{mdata}</span>
                                                                        </div >
                                                                    </>
                                                                )
                                                            })
                                                        }
                                                    </div>
                                                </div>
                                                :
                                                null
                                        }
                                        {
                                            expandfilters && windowidth <= 1067 &&
                                            <div style={{ position: 'absolute', zIndex: '10', width: '95%', backgroundColor: 'black', marginTop: '-20px', color: 'white' }}>
                                                <div style={{ cursor: 'pointer', padding: '10px', paddingTop: '0' }}>
                                                    {
                                                        Object.keys(sidefilter).map((data, i) => {
                                                            return (
                                                                <>
                                                                    <div key={i} style={(i == expandindex) ? { marginTop: '10px', fontWeight: '600', borderBottom: '1px solid #f9b101', backgroundColor: '#e9b766', borderRadius: '5px' } : { marginTop: '10px', fontWeight: '600' }}  >
                                                                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                                                            <div>
                                                                                <span style={(i == expandindex) ? { color: 'black', fontWeight: 'bold' } : {}}>
                                                                                    <input type='checkbox' value={data}
                                                                                        checked={checkedCheckboxes[data]}
                                                                                        className="custom-checkbox"
                                                                                        onChange={(e) => {
                                                                                            handleuppercheckbox(e.target.checked, data)
                                                                                        }}
                                                                                        onClick={(e) => { if (e.target.checked == true) { setisCheckedvalue(true) } else { setisCheckedvalue(false) }; setisLoading(true) }}
                                                                                    ></input>
                                                                                    <span style={{ marginLeft: '10px' }} onClick={() => { openitem(i) }}>{data}</span>
                                                                                </span>
                                                                            </div>
                                                                            <div>
                                                                                <span onClick={() => { openitem(i) }}>{i == expandindex ? <RemoveIcon style={{ fontSize: '1.2rem', }} /> : <AddIcon style={{ fontSize: '1.2rem' }} />}  </span>
                                                                            </div>
                                                                        </div>
                                                                        {
                                                                            sidefilter[data] && (i == expandindex) &&
                                                                            sidefilter[data].map((s, index) => {
                                                                                return (
                                                                                    <div key={i} style={{ marginLeft: '15px' }}>
                                                                                        <input type='checkbox' value={s}
                                                                                            checked={checkedCheckboxes[s]}
                                                                                            className="custom-checkbox"
                                                                                            onChange={(e) => {
                                                                                                handleinnercheckbox(e.target.checked, s, data)
                                                                                            }}
                                                                                            onClick={(e) => { if (e.target.checked == true) { setisCheckedvalue(true) } else { setisCheckedvalue(false) }; setisLoading(true) }}
                                                                                        ></input>
                                                                                        <span style={{ fontSize: '0.75rem', marginLeft: '10px' }} key={index} className='filteritems'>{s}</span>
                                                                                    </div>
                                                                                )
                                                                            })
                                                                        }
                                                                    </div>
                                                                </>
                                                            )
                                                        })
                                                    }
                                                </div >
                                                <div className="modalfilterstyle" style={{ backgroundColor: 'rgb(30 30 30)', marginTop: '5px' }}>
                                                    <div style={modalexpand ? { display: 'flex', justifyContent: 'space-between', alignItems: 'center', paddingBottom: '5px', borderBottom: '1px solid rgb(191 188 183)' } : { display: 'flex', justifyContent: 'space-between', alignItems: 'center', paddingBottom: '5px', }}>
                                                        <div >
                                                            <input type='checkbox'
                                                                checked={modeldata}
                                                                onChange={(e) => {
                                                                    const isChecked = e.target.checked;
                                                                    dispatch({ type: 'UpperModalCheckbox', payload: { uppercheckbox: isChecked } });

                                                                    if (isChecked) {
                                                                        setmodalcategory(modaldata);
                                                                    } else {
                                                                        setmodalcategory([]);
                                                                    }
                                                                    setmodeldata(isChecked);
                                                                    const updatedCheckModal = {};
                                                                    modaldata.forEach((mdata) => {
                                                                        updatedCheckModal[mdata] = isChecked;
                                                                    });
                                                                    dispatch({ type: 'ModalCheckbox', payload: { modalcheckbox: updatedCheckModal } });
                                                                    setcheckmodal(updatedCheckModal);
                                                                }}
                                                                onClick={(e) => { if (e.target.checked == true) { setisCheckedvalue(true) } else { setisCheckedvalue(false) }; setisLoading(true) }}
                                                            ></input>
                                                            <span style={{ marginLeft: '10px' }} onClick={() => { setmodalexpand(!modalexpand) }}>MODELS</span>
                                                        </div>
                                                        <div onClick={() => { setmodalexpand(!modalexpand) }}>
                                                            <span>{modalexpand ? <RemoveIcon style={{ fontSize: '1.2rem' }} /> : <AddIcon style={{ fontSize: '1.2rem' }} />}  </span>
                                                        </div>
                                                    </div>
                                                    {
                                                        modalexpand && modaldata.map((mdata, index) => {
                                                            return (
                                                                <>
                                                                    <div key={index} style={{ marginLeft: '15px', marginTop: '10px' }} >
                                                                        <input type='checkbox'
                                                                            checked={checkmodal[mdata]}
                                                                            value={mdata}
                                                                            onChange={(e) => {
                                                                                const isChecked = e.target.checked;
                                                                                setcheckmodal((prev) => ({
                                                                                    ...prev,
                                                                                    [mdata]: isChecked,
                                                                                }));
                                                                                setmodalcategory((prev) => {
                                                                                    if (isChecked) {
                                                                                        return [...prev, mdata];
                                                                                    } else {
                                                                                        return prev.filter((col) => col !== mdata);
                                                                                    }
                                                                                });
                                                                                const updatedCheckmodal = { ...checkmodal, [mdata]: isChecked };
                                                                                dispatch({ type: 'ModalCheckbox', payload: { modalcheckbox: updatedCheckmodal } });
                                                                                const areAllModelsChecked = modaldata.every((m) => updatedCheckmodal[m]);
                                                                                dispatch({ type: 'UpperModalCheckbox', payload: { uppercheckbox: areAllModelsChecked } });
                                                                                setmodeldata(areAllModelsChecked);
                                                                            }}
                                                                            onClick={(e) => { if (e.target.checked == true) { setisCheckedvalue(true) } else { setisCheckedvalue(false) }; setisLoading(true) }}
                                                                        ></input>
                                                                        <span style={{ marginLeft: '10px' }} >{mdata}</span>
                                                                    </div >
                                                                </>
                                                            )
                                                        })
                                                    }
                                                </div>
                                            </div>
                                        }
                                        {
                                            DataFound ?
                                                <div style={{ margin: 'auto', color: '#f9b101', height: '300px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                                    <h3>No Data Found</h3>
                                                </div>
                                                :
                                                <div style={{ width: '100%' }}>
                                                    {
                                                        isFilterappliedTrue ?
                                                            <>
                                                                <div style={{ width: '98%', margin: 'auto', display: 'flex', flexFlow: 'wrap', gap: '10px', marginBottom: '20px' }}>
                                                                    {
                                                                        Filterdatavalue.map((data, index) => {
                                                                            return (
                                                                                <>
                                                                                    <span key={index} style={{ padding: ' 5px', color: 'white', display: 'flex', gap: '5px', backgroundColor: 'rgb(208, 132, 12)', borderRadius: '10px', alignItems: 'center' }}>
                                                                                        {data}
                                                                                        <CloseIcon style={{ fontSize: '0.95rem', cursor: 'pointer' }} onClick={() => {
                                                                                            clearupperfilter(data);
                                                                                        }}
                                                                                        />
                                                                                    </span>
                                                                                </>

                                                                            )
                                                                        })
                                                                    }
                                                                </div>
                                                            </>
                                                            :
                                                            null
                                                    }
                                                    <div style={windowidth < 500 ? { display: 'flex', flexFlow: 'row wrap', width: '100%', justifyContent: 'center' } : { display: 'flex', flexFlow: 'row wrap', width: '100%' }}>
                                                        {
                                                            productsdata.map((data, index) => {
                                                                let images = data.images;
                                                                images = images.split(',');
                                                                if (usertype == undefined || usertype == null || usertype == ""|| usertype.toLowerCase() == "visitor") {
                                                                    if (data.make == "Original" || data.make == "original") {
                                                                        let price = null;
                                                                        if (Userorigin.toLowerCase() === "domestic") {
                                                                            if (usertype.toLowerCase() === "visitor") {
                                                                            } else if (usertype.toLowerCase() === "retailer") {
                                                                                price = `₹${Number(data.mrp).toFixed(2)}`;
                                                                            } else {
                                                                                price = `₹${Number(data.wholesaleprice).toFixed(2)}`;
                                                                            }
                                                                        } else {
                                                                            if (OverseasINR === true) {
                                                                                price = `₹ ${Number(data.overseasprice * data.conversionrate).toFixed(2)}`;
                                                                            } else {
                                                                                price = `$${Number(data.overseasprice).toFixed(2)}`;
                                                                            }
                                                                        }
                                                                        return (
                                                                            <>
                                                                                <div className='shopcards' key={index}>
                                                                                    <Link to={`/Product/${data.recid}/${data.suitableforpartreference.replaceAll('/', '-')}`} style={{ textDecoration: 'none', color: 'white' }}>
                                                                                        <Carousel
                                                                                            rewindWithAnimation={true} showArrows={false} infiniteLoop={true} showStatus={false} showIndicators={true} transitionTime={1000} showThumbs={false} >

                                                                                            <div className='firstcarousel' style={{ width: '100%', height: '100%' }} >
                                                                                                {/* <img
                                                                                                    src={data.images != "" ? `${images[0].replace('wp-content/',"")}`: `${sessionStorage.getItem('Apipathurl')}uploads/2023/11/dummy.png`}
                                                                                                    alt="Product Images"  ></img> */}
                                                                                                    <img src='https://seetechparts.com/uploads/2023/11/dummy.png' className='img-fluid'/>
                                                                                            </div>
                                                                                        </Carousel>
                                                                                        <span style={{ fontWeight: '500', fontWeight: '700', fontSize: windowidth < 426 ? '0.9rem' : '1rem' }}>{data.name}</span>
                                                                                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', fontSize: windowidth < 426 ? '0.8rem' : '1rem' }}>
                                                                                            <span style={{ margin: '0 10px' }}>
                                                                                                {
                                                                                                    usertype == undefined || usertype == null || usertype == "" ?
                                                                                                        null
                                                                                                        :
                                                                                                        price
                                                                                                }
                                                                                            </span>
                                                                                            <span style={{ textDecoration: 'line-through' }}>
                                                                                                {
                                                                                                    usertype == undefined || usertype == null || usertype == "" ?
                                                                                                        null
                                                                                                        :
                                                                                                        Userorigin.toLowerCase() === "domestic" ? (
                                                                                                            Userorigin.toLowerCase() == "wholesaler" &&
                                                                                                            (
                                                                                                                `₹${Number(data.mrp).toFixed(2)}`
                                                                                                            )
                                                                                                        ) : null
                                                                                                }
                                                                                            </span>
                                                                                        </div>
                                                                                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', fontSize: windowidth < 426 ? '0.65rem' : '0.75rem' }}>
                                                                                            <span style={{ color: 'black' }}>Brand :
                                                                                                <span style={{ color: 'black', margin: '0 5px', fontWeight: '700', }}>{data.brand}</span>
                                                                                            </span>
                                                                                        </div>
                                                                                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', fontSize: windowidth < 426 ? '0.65rem' : '0.75rem', width: '90%', margin: 'auto' }}>
                                                                                            <span style={{ color: 'black' }}>Suitable for Model ref:<span style={{ color: 'black', margin: '0 5px', fontWeight: '700', }}>{data.suitableformodel}</span></span>
                                                                                        </div>
                                                                                        <div style={{ textAlign: 'center', fontSize: windowidth < 426 ? '0.65rem' : '0.75rem', width: '90%', margin: 'auto' }}>
                                                                                                <span style={{ color: 'black' }}>Suitable for Part ref:<span style={{ color: 'black', margin: '0 5px', fontWeight: '700', }}>{data.suitableforpartreference}</span></span>
                                                                                        </div>
                                                                                    </Link>
                                                                                </div>
                                                                            </>
                                                                        )
                                                                    } else {
                                                                        let images = data.images;
                                                                        images = images.split(',');
                                                                        return (
                                                                            <>
                                                                                <div className='shopcards'>
                                                                                    <Link to={`/Product/${data.recid}/${data.suitableforpartreference.replaceAll('/', '-')}`} style={{ textDecoration: 'none', color: 'white' }}>
                                                                                        <Carousel
                                                                                            rewindWithAnimation={true} showArrows={false} infiniteLoop={true} showStatus={false} showIndicators={true} transitionTime={1000} showThumbs={false} >
                                                                                            <div className='firstcarousel' style={{ width: '100%', height: '100%' }} >
                                                                                                {/* <img
                                                                                                    src={data.images != "" ? `${images[0].replace('wp-content/',"")}`: `${sessionStorage.getItem('Apipathurl')}uploads/2023/11/dummy.png`}

                                                                                                    alt="Product Images" ></img> */}
                                                                                                    <img src='https://seetechparts.com/uploads/2023/11/dummy.png' className='img-fluid'/>
                                                                                            </div>
                                                                                        </Carousel>
                                                                                        <span style={{ fontWeight: '500', fontWeight: '700', fontSize: windowidth < 426 ? '0.9rem' : '1rem' }}>{data.name}</span>
                                                                                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', fontSize: windowidth < 426 ? '0.8rem' : '1rem' }}>
                                                                                            <span style={{ margin: '0 10px' }}>
                                                                                                {
                                                                                                    usertype == undefined || usertype == null || usertype == "" ?
                                                                                                        null
                                                                                                        :
                                                                                                        Userorigin.toLowerCase() === "domestic" ?
                                                                                                            usertype.toLowerCase() == "visitor" ?
                                                                                                                null
                                                                                                                :
                                                                                                                usertype == "retailer" ?
                                                                                                                    (`₹${Number(data.mrp).toFixed(2)}`)
                                                                                                                    :
                                                                                                                    (`₹${Number(data.wholesaleprice).toFixed(2)}`)

                                                                                                            :
                                                                                                            OverseasINR == true ?
                                                                                                                `₹ ${Number(data.overseasprice * data.conversionrate).toFixed(2)}`
                                                                                                                :
                                                                                                                (
                                                                                                                    `$${Number(data.overseasprice).toFixed(2)}`
                                                                                                                )
                                                                                                }
                                                                                            </span>
                                                                                            <span style={{ textDecoration: 'line-through' }}>
                                                                                                {
                                                                                                    usertype == undefined || usertype == null || usertype == "" ?
                                                                                                        null
                                                                                                        :
                                                                                                        Userorigin.toLowerCase() === "domestic" ? (
                                                                                                            Userorigin.toLowerCase() == "wholesaler" &&
                                                                                                            (
                                                                                                                `₹${Number(data.mrp).toFixed(2)}`
                                                                                                            )
                                                                                                        ) : null
                                                                                                }
                                                                                            </span>
                                                                                        </div>
                                                                                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', fontSize: windowidth < 426 ? '0.65rem' : '0.75rem' }}>
                                                                                            <span style={{ color: 'black' }}>Brand :
                                                                                                <span style={{ color: 'black', margin: '0 5px', fontWeight: '700', }}>{data.brand}</span>
                                                                                            </span>
                                                                                        </div>
                                                                                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', fontSize: windowidth < 426 ? '0.65rem' : '0.75rem', width: '90%', margin: 'auto' }}>
                                                                                            <span style={{ color: 'black' }}>Suitable for Model ref:
                                                                                                <span style={{ color: 'black', margin: '0 5px', fontWeight: '700' }}>{data.suitableformodel}</span>
                                                                                            </span>
                                                                                        </div>
                                                                                        <div style={{ textAlign: 'center', fontSize: windowidth < 426 ? '0.65rem' : '0.75rem', width: '90%', margin: 'auto' }}>
                                                                                                <span style={{ color: 'black' }}>Suitable for Part ref:<span style={{ color: 'black', margin: '0 5px', fontWeight: '700', }}>{data.suitableforpartreference}</span></span>
                                                                                        </div>
                                                                                    </Link>
                                                                                </div>
                                                                            </>
                                                                        )
                                                                    }
                                                                }
                                                                else {
                                                                    if (data.companycode == "" || data.companycode == '') {
                                                                        let images = data.images;
                                                                        images = images.split(',');
                                                                        let price = null;
                                                                        if (Userorigin.toLowerCase() === "domestic") {
                                                                            if (usertype.toLowerCase() === "visitor") {
                                                                            } else if (usertype.toLowerCase() === "retailer") {
                                                                                price = `₹${Number(data.mrp).toFixed(2)}`;
                                                                            } else {
                                                                                price = `₹${Number(data.wholesaleprice).toFixed(2)}`;
                                                                            }
                                                                        } else {
                                                                            if (OverseasINR === true) {
                                                                                price = `₹ ${Number(data.overseasprice * data.conversionrate).toFixed(2)}`;
                                                                            } else {
                                                                                price = `$${Number(data.overseasprice).toFixed(2)}`;
                                                                            }
                                                                        }
                                                                        return (
                                                                            <>
                                                                                <div className='shopcards'>
                                                                                    <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                                                                        {
                                                                                            WishlistItem.some((rid) => rid == data.recid) ?
                                                                                                <span onClick={() => {
                                                                                                    Removefromwishlist(data.recid);
                                                                                                }}> <FavoriteIcon style={{ color: '#f9b101' }} /> </span>
                                                                                                :
                                                                                                <span onClick={() => {
                                                                                                    Addtowishlist(data.recid);
                                                                                                }} > <FavoriteBorderIcon /> </span>
                                                                                        }
                                                                                    </div>
                                                                                    <Link to={`/Product/${data.recid}/OEM`} style={{ textDecoration: 'none', color: 'white' }}>
                                                                                        <Carousel
                                                                                            rewindWithAnimation={true} showArrows={false} infiniteLoop={true} showStatus={false} showIndicators={true} transitionTime={1000} showThumbs={false}>
                                                                                            <div className='firstcarousel' style={{ width: '100%', height: '100%' }}
                                                                                            >
                                                                                                {/* <img src={data.images != "" ? `${images[0].replace('wp-content/',"")}`: `${sessionStorage.getItem('Apipathurl')}uploads/2023/11/dummy.png`} alt="Product Image" ></img> */}
                                                                                                <img src='https://seetechparts.com/uploads/2023/11/dummy.png' className='img-fluid'/>
                                                                                            </div>
                                                                                        </Carousel>
                                                                                        <span style={{ fontWeight: '500', color: 'black', fontWeight: '700', fontSize: windowidth < 426 ? '0.9rem' : '1rem' }}>{data.name}</span>
                                                                                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', fontSize: windowidth < 426 ? '0.8rem' : '1rem' }}>
                                                                                            <span style={{ margin: '0 10px', color: 'black' }}>
                                                                                                {
                                                                                                    usertype == undefined || usertype == null || usertype == "" ?
                                                                                                        null
                                                                                                        :
                                                                                                        price
                                                                                                }
                                                                                            </span>
                                                                                            <span style={{ textDecoration: 'line-through', color: 'black' }}>
                                                                                                {
                                                                                                    usertype == undefined || usertype == null || usertype == "" ?
                                                                                                        null
                                                                                                        :
                                                                                                        Userorigin.toLowerCase() === "domestic" ? (
                                                                                                            usertype.toLowerCase() == "wholesaler" &&
                                                                                                            (
                                                                                                                `₹${Number(data.mrp).toFixed(2)}`
                                                                                                            )
                                                                                                        ) : null
                                                                                                }
                                                                                            </span>
                                                                                        </div>
                                                                                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', fontSize: windowidth < 426 ? '0.65rem' : '0.75rem' }}>
                                                                                            <span style={{ color: 'black' }}>Brand :
                                                                                                <span style={{ color: '#ffbd27', margin: '0 5px', fontWeight: '700', }}>{data.brand}</span>
                                                                                            </span>
                                                                                        </div>
                                                                                        <div style={{ textAlign: 'center', fontSize: windowidth < 426 ? '0.65rem' : '0.75rem', width: '90%', margin: 'auto' }}>
                                                                                                <span style={{ color: 'black' }}>Suitable for Part ref:<span style={{ color: 'black', margin: '0 5px', fontWeight: '700', }}>{data.suitableforpartreference}</span></span>
                                                                                        </div>
                                                                                        {
                                                                                            data.moq > 1 &&
                                                                                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', fontWeight: 'bold', fontSize: windowidth < 426 ? '0.65rem' : '0.75rem' }}>
                                                                                                <span style={{ color: '#ca0000' }}>Multiple Order Quantity: </span>
                                                                                                <span style={{ color: 'black', margin: '0 5px' }}>{data.moq}</span>
                                                                                            </div>
                                                                                        }
                                                                                    </Link>
                                                                                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-around', paddingTop: '10px' }}>
                                                                                        {
                                                                                            usertype.toLowerCase() == "visitor" ?
                                                                                                null
                                                                                                :
                                                                                                <input style={{ width: windowidth < 433 ? '30px' : '50px', borderRadius: '10px', fontSize: windowidth < 433 ? '0.8rem' : '0.9rem', height: windowidth < 380 ? '25px' : '27px', paddingLeft: windowidth < 380 ? '2px' : '5px' }} type='number'
                                                                                                    value={data.qty != 0 && Cartquantity[index] == undefined ? data.qty : Cartquantity[index]}
                                                                                                    onChange={(e) => {
                                                                                                        const updatedCartQuantity = [...Cartquantity];
                                                                                                        updatedCartQuantity[index] = e.target.value;
                                                                                                        setCartquantity(updatedCartQuantity);
                                                                                                    }} placeholder='Qty..' />
                                                                                        }
                                                                                        {
                                                                                            usertype == undefined || usertype == null || usertype == "" ?
                                                                                                <button className='productsbtnstyle' style={{ backgroundColor: 'rgb(208, 132, 12)', padding: '0', fontSize: '0.9rem', borderRadius: '15px', minHeight: '30px' }}>Login for details</button>
                                                                                                :
                                                                                                usertype.toLowerCase() == "visitor" ?
                                                                                                    null
                                                                                                    :
                                                                                                    CartRecID.some((it) => it == data.recid) ?
                                                                                                        <button className='productsbtnstyle' style={{ backgroundColor: 'black', padding: '0', borderRadius: '15px', fontSize: windowidth < 460 ? '0.7rem' : '1rem', fontWeight: '500', color: '#f9b101', minHeight: '30px', minWidth: windowidth < 460 ? '80px' : null }}
                                                                                                            onClick={(e) => {
                                                                                                                e.stopPropagation(); AddtoCart(data.recid, data.moq, price, index, data.qty, data.wholesaleprice);
                                                                                                            }}>Modify Cart</button>
                                                                                                        :
                                                                                                        <button className='productsbtnstyle' style={{ backgroundColor: '#f9b101', padding: '0', borderRadius: '15px', fontSize: windowidth < 460 ? '0.7rem' : '1rem', fontWeight: '500', color: 'black', minHeight: '30px', minWidth: windowidth < 460 ? '80px' : null }}
                                                                                                            onClick={(e) => {
                                                                                                                e.stopPropagation(); AddtoCart(data.recid, data.moq, price, index, data.qty, data.wholesaleprice);
                                                                                                            }}>Add to cart</button>
                                                                                        }
                                                                                    </div>
                                                                                </div>
                                                                            </>
                                                                        )
                                                                    } else {
                                                                        if (Userorigin.toLowerCase() == "domestic" && data.visibilitydomestic != 0) {
                                                                            let images = data.images;
                                                                            images = images.split(',');
                                                                            const productimage = images[0].split('wp-content/')
                                                                            let price = null;
                                                                            if (Userorigin.toLowerCase() === "domestic") {
                                                                                if (usertype.toLowerCase() === "visitor") {
                                                                                } else if (usertype.toLowerCase() === "retailer") {
                                                                                    price = `₹${Number(data.mrp).toFixed(2)}`;
                                                                                } else {
                                                                                    price = `₹${Number(data.wholesaleprice).toFixed(2)}`;
                                                                                }
                                                                            } else {
                                                                                if (OverseasINR === true) {
                                                                                    price = `₹ ${Number(data.overseasprice * data.conversionrate).toFixed(2)}`;
                                                                                } else {
                                                                                    price = `$${Number(data.overseasprice).toFixed(2)}`;
                                                                                }
                                                                            }
                                                                            return (
                                                                                <>
                                                                                    <div className='shopcards'>
                                                                                        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                                                                            {
                                                                                                WishlistItem.some((rid) => rid == data.recid) ?
                                                                                                    <span onClick={() => {
                                                                                                        Removefromwishlist(data.recid);
                                                                                                    }}> <FavoriteIcon style={{ color: '#f9b101' }} /> </span>
                                                                                                    :
                                                                                                    <span onClick={() => {
                                                                                                        Addtowishlist(data.recid);
                                                                                                    }} > <FavoriteBorderIcon /> </span>
                                                                                            }
                                                                                        </div>
                                                                                        <Link to={`/Product/${data.recid}/${data.suitableforpartreference.replaceAll('/', '-')}`} style={{ textDecoration: 'none', color: 'white' }}>
                                                                                            <Carousel
                                                                                                rewindWithAnimation={true} showArrows={false} infiniteLoop={true} showStatus={false} showIndicators={true} transitionTime={1000} showThumbs={false} >
                                                                                                <div className='firstcarousel' style={{ width: '100%', height: '100%' }}>
                                                                                                    {/* <img src={data.images != "" ? `${images[0].replace('wp-content/',"")}`: `${sessionStorage.getItem('Apipathurl')}uploads/2023/11/dummy.png`} alt="Product Image" ></img> */}
                                                                                                    <img src='https://seetechparts.com/uploads/2023/11/dummy.png' className='img-fluid'/>
                                                                                                </div>
                                                                                            </Carousel>
                                                                                            <span style={{ fontWeight: '500', color: 'black', fontWeight: '700', fontSize: windowidth < 426 ? '0.9rem' : '1rem' }}>{data.name}</span>

                                                                                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', fontSize: windowidth < 426 ? '0.8rem' : '1rem' }}>
                                                                                                <span style={{ margin: '0 10px', color: 'black' }}>
                                                                                                    {
                                                                                                        usertype == undefined || usertype == null || usertype == "" ?
                                                                                                            null
                                                                                                            :
                                                                                                            price
                                                                                                    }
                                                                                                </span>
                                                                                                <span style={{ textDecoration: 'line-through', fontSize: '0.9rem', color: 'black' }}>
                                                                                                    {
                                                                                                        usertype == undefined || usertype == null || usertype == "" ?
                                                                                                            null
                                                                                                            :
                                                                                                            Userorigin.toLowerCase() === "domestic" ? (
                                                                                                                usertype.toLowerCase() == "wholesaler" ?
                                                                                                                    (
                                                                                                                        ` ₹${Number(data.mrp).toFixed(2)}`
                                                                                                                    )
                                                                                                                    :
                                                                                                                    null
                                                                                                            ) : null
                                                                                                    }
                                                                                                </span>
                                                                                            </div>
                                                                                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', fontSize: windowidth < 426 ? '0.65rem' : '0.75rem' }}>
                                                                                                <span style={{ color: 'black' }}>Brand :
                                                                                                    <span style={{ color: 'black', margin: '0 5px', fontWeight: '700', }}>{data.brand}</span>
                                                                                                </span>
                                                                                            </div>
                                                                                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', fontSize: windowidth < 426 ? '0.65rem' : '0.75rem', width: '90%', margin: 'auto' }}>
                                                                                                <span style={{ color: 'black' }}>Suitable for Model ref:
                                                                                                    <span style={{ color: 'black', margin: '0 5px', fontWeight: '700', }}>{data.suitableformodel}</span>
                                                                                                </span>
                                                                                            </div>
                                                                                            <div style={{ textAlign: 'center', fontSize: windowidth < 426 ? '0.65rem' : '0.75rem', width: '90%', margin: 'auto' }}>
                                                                                                <span style={{ color: 'black' }}>Suitable for Part ref:<span style={{ color: 'black', margin: '0 5px', fontWeight: '700', }}>{data.suitableforpartreference}</span></span>
                                                                                            </div>
                                                                                            {
                                                                                                data.moq > 1 &&
                                                                                                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', fontWeight: 'bold', fontSize: '0.75rem' }}>
                                                                                                    <span style={{ color: '#ca0000' }}>Multiple Order Quantity: </span>
                                                                                                    <span style={{ color: 'black', margin: '0 5px' }}>{data.moq}</span>
                                                                                                </div>
                                                                                            }
                                                                                        </Link>
                                                                                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-around', paddingTop: '10px' }}>

                                                                                            {
                                                                                                usertype.toLowerCase() == "visitor" ?
                                                                                                    null
                                                                                                    :
                                                                                                    <input style={{ width: windowidth < 433 ? '30px' : '50px', borderRadius: '10px', fontSize: windowidth < 433 ? '0.8rem' : '0.9rem', height: windowidth < 380 ? '25px' : '27px', paddingLeft: windowidth < 380 ? '2px' : '5px' }} type='number'
                                                                                                        value={data.qty != 0 && Cartquantity[index] == undefined ? data.qty / 2 : Cartquantity[index]}
                                                                                                        onChange={(e) => {
                                                                                                            const updatedCartQuantity = [...Cartquantity];
                                                                                                            updatedCartQuantity[index] = e.target.value;
                                                                                                            setCartquantity(updatedCartQuantity);
                                                                                                        }} placeholder='Qty..' />
                                                                                            }
                                                                                            {
                                                                                                usertype == undefined || usertype == null || usertype == "" ?
                                                                                                    <button className='productsbtnstyle' style={{ backgroundColor: 'rgb(208, 132, 12)', padding: '0', fontSize: '1rem', borderRadius: '15px', minHeight: '30px' }}>Login for details</button>
                                                                                                    :
                                                                                                    usertype.toLowerCase() == "visitor" ?
                                                                                                        null
                                                                                                        :
                                                                                                        CartRecID.some((item) => item == data.recid) ?
                                                                                                            <button className='productsbtnstyle' style={{ backgroundColor: 'black', padding: '0', borderRadius: '15px', fontSize: windowidth < 460 ? '0.7rem' : '1rem', fontWeight: '500', color: '#f9b101', minHeight: '30px', minWidth: windowidth < 460 ? '80px' : null }}
                                                                                                                onClick={(e) => {
                                                                                                                    e.stopPropagation(); AddtoCart(data.recid, data.moq, price, index, data.qty);
                                                                                                                }}>Modify Cart</button>
                                                                                                            :
                                                                                                            <button className='productsbtnstyle' style={{ backgroundColor: '#f9b101', padding: '0', borderRadius: '15px', fontSize: windowidth < 460 ? '0.7rem' : '1rem', fontWeight: '500', color: 'black', minHeight: '30px', minWidth: windowidth < 460 ? '80px' : null }}
                                                                                                                onClick={(e) => {
                                                                                                                    e.stopPropagation(); AddtoCart(data.recid, data.moq, price, index, data.qty);
                                                                                                                }}>Add to cart</button>
                                                                                            }
                                                                                        </div>
                                                                                    </div>
                                                                                </>
                                                                            )
                                                                        } else if (Userorigin.toLowerCase() == "overseas" && data.visibilityoverseas != 0) {
                                                                            let images = data.images;
                                                                            images = images.split(',');
                                                                            let price = null;
                                                                            if (Userorigin.toLowerCase() === "domestic") {
                                                                                if (usertype.toLowerCase() === "visitor") {
                                                                                } else if (usertype.toLowerCase() === "retailer") {
                                                                                    price = `₹${Number(data.mrp).toFixed(2)}`;
                                                                                } else {
                                                                                    price = `₹${Number(data.wholesaleprice).toFixed(2)}`;
                                                                                }
                                                                            } else {
                                                                                if (OverseasINR === true) {
                                                                                    price = `₹ ${Number(data.overseasprice * data.conversionrate).toFixed(2)}`;
                                                                                } else {
                                                                                    price = `$${Number(data.overseasprice).toFixed(2)}`;
                                                                                }
                                                                            }
                                                                            return (
                                                                                <>
                                                                                    <div className='shopcards'
                                                                                    >
                                                                                        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                                                                            {
                                                                                                WishlistItem.some((rid) => rid == data.recid) ?
                                                                                                    <span onClick={() => {
                                                                                                        Removefromwishlist(data.recid);
                                                                                                    }}> <FavoriteIcon style={{ color: '#f9b101' }} /> </span>
                                                                                                    :
                                                                                                    <span onClick={() => {
                                                                                                        Addtowishlist(data.recid);
                                                                                                    }} > <FavoriteBorderIcon /> </span>
                                                                                            }
                                                                                        </div>
                                                                                        <Link to={`/Product/${data.recid}/${data.suitableforpartreference.replaceAll('/', '-')}`} style={{ textDecoration: 'none', color: 'white' }}>
                                                                                            <Carousel
                                                                                                rewindWithAnimation={true} showArrows={false} infiniteLoop={true} showStatus={false} showIndicators={true} transitionTime={1000} showThumbs={false}>
                                                                                                <div className='firstcarousel' style={{ width: '100%', height: '100%' }}>
                                                                                                    {/* <img src={data.images != "" ? `${images[0].replace('wp-content/',"")}`: `${sessionStorage.getItem('Apipathurl')}uploads/2023/11/dummy.png`} alt="Product Image" ></img> */}
                                                                                                    <img src='https://seetechparts.com/uploads/2023/11/dummy.png' className='img-fluid'/>
                                                                                                </div>
                                                                                            </Carousel>
                                                                                            <span style={{ fontWeight: '700', color: 'black', fontSize: windowidth < 426 ? '0.9rem' : '1rem' }}>{data.name}</span>
                                                                                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', fontSize: windowidth < 426 ? '0.8rem' : '1rem' }}>
                                                                                                <span style={{ margin: '0 10px', color: 'black' }}>
                                                                                                    {
                                                                                                        usertype == undefined || usertype == null || usertype == "" ?
                                                                                                            null
                                                                                                            :
                                                                                                            price
                                                                                                    }
                                                                                                </span>
                                                                                                <span style={{ textDecoration: 'line-through', color: 'black' }}>
                                                                                                    {
                                                                                                        usertype == undefined || usertype == null || usertype == "" ?
                                                                                                            null
                                                                                                            :
                                                                                                            Userorigin.toLowerCase() === "domestic" ? (
                                                                                                                usertype.toLowerCase() == "wholesaler" ?
                                                                                                                    (
                                                                                                                        `₹${Number(data.mrp).toFixed(2)}`
                                                                                                                    )
                                                                                                                    :
                                                                                                                    null
                                                                                                            ) : null
                                                                                                    }

                                                                                                </span>
                                                                                                {/* <span className='discountstyle'>-26%</span> */}
                                                                                            </div>

                                                                                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', fontSize: windowidth < 426 ? '0.65rem' : '0.75rem' }}>
                                                                                                <span style={{ color: 'black' }}>Brand :
                                                                                                    <span style={{ color: 'black', margin: '0 5px', fontWeight: '700', }}>{data.brand}</span>
                                                                                                </span>
                                                                                                {/* <span style={{ color: 'black', margin: '0 5px', fontWeight: '700', }}>{data.brand}</span> */}
                                                                                            </div>

                                                                                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', fontSize: windowidth < 426 ? '0.65rem' : '0.75rem', width: '90%', margin: 'auto' }}>
                                                                                                <span style={{ color: 'black' }}>Suitable for Model ref:
                                                                                                    <span style={{ color: 'black', margin: '0 5px', fontWeight: '700', }}>{data.suitableformodel}</span>
                                                                                                </span>
                                                                                                {/* <span style={{ color: 'black', margin: '0 5px', fontWeight: '700', }}>{data.suitableformodel}</span> */}
                                                                                            </div>
                                                                                            <div style={{ textAlign: 'center', fontSize: windowidth < 426 ? '0.65rem' : '0.75rem', width: '90%', margin: 'auto' }}>
                                                                                                <span style={{ color: 'black' }}>Suitable for Part ref:<span style={{ color: 'black', margin: '0 5px', fontWeight: '700', }}>{data.suitableforpartreference}</span></span>
                                                                                            </div>
                                                                                            {
                                                                                                data.moq > 1 &&
                                                                                                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', fontWeight: 'bold', fontSize: windowidth < 426 ? '0.65rem' : '0.75rem' }}>
                                                                                                    <span style={{ color: '#ca0000' }}>Multiple Order Quantity: </span>
                                                                                                    <span style={{ color: 'black', margin: '0 5px' }}>{data.moq}</span>
                                                                                                </div>
                                                                                            }

                                                                                        </Link>
                                                                                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-around', paddingTop: '10px' }}>
                                                                                            {
                                                                                                usertype.toLowerCase() == "visitor" ?
                                                                                                    null
                                                                                                    :
                                                                                                    <input style={{ width: windowidth < 433 ? '30px' : '50px', borderRadius: '10px', fontSize: windowidth < 433 ? '0.8rem' : '0.9rem', height: windowidth < 380 ? '25px' : '27px', paddingLeft: windowidth < 380 ? '2px' : '5px' }} type='number'
                                                                                                        value={data.qty != 0 && Cartquantity[index] == undefined ? data.qty / 2 : Cartquantity[index]} placeholder='Qty..' onChange={(e) => {
                                                                                                            e.stopPropagation()
                                                                                                            const updatedCartQuantity = [...Cartquantity];
                                                                                                            updatedCartQuantity[index] = e.target.value;
                                                                                                            setCartquantity(updatedCartQuantity);
                                                                                                        }} />
                                                                                            }
                                                                                            {
                                                                                                usertype == undefined || usertype == null || usertype == "" ?
                                                                                                    <button className='productsbtnstyle' style={{ backgroundColor: 'rgb(208, 132, 12)', padding: '5px', fontSize: '1rem', borderRadius: '15px', minHeight: '30px' }}>Login for details</button>
                                                                                                    :
                                                                                                    usertype.toLowerCase() == "visitor" ?
                                                                                                        null
                                                                                                        :
                                                                                                        CartRecID.some((item) => item == data.recid) ?
                                                                                                            <button className='productsbtnstyle' style={{ backgroundColor: 'black', padding: '0', borderRadius: '15px', fontSize: windowidth < 460 ? '0.7rem' : '1rem', fontWeight: '500', color: '#f9b101', minHeight: '30px', minWidth: windowidth < 460 ? '80px' : null }}
                                                                                                                onClick={(e) => {
                                                                                                                    e.stopPropagation(); AddtoCart(data.recid, data.moq, price, index, data.qty);
                                                                                                                }}>Modify Cart</button>
                                                                                                            :

                                                                                                            <button className='productsbtnstyle' style={{ backgroundColor: '#f9b101', padding: '0', borderRadius: '15px', fontSize: windowidth < 460 ? '0.7rem' : '1rem', fontWeight: '500', color: 'black', minHeight: '30px', minWidth: windowidth < 460 ? '80px' : null }}
                                                                                                                onClick={(e) => {
                                                                                                                    e.stopPropagation(); AddtoCart(data.recid, data.moq, price, index, data.qty);
                                                                                                                    // let Cartid = [...CartRecID];
                                                                                                                    // Cartid.push(data.recid)
                                                                                                                    // setCartRecID(Cartid)
                                                                                                                }}>Add to cart</button>
                                                                                            }
                                                                                        </div>
                                                                                    </div>
                                                                                </>
                                                                            )
                                                                        }
                                                                    }
                                                                }
                                                            })
                                                        }
                                                    </div>

                                                </div>
                                        }
                                    </div>
                                    <div className='MainPaination' style={{ cursor: 'pointer', width: '35%', margin: 'auto', marginTop: '50px' }}>
                                        {
                                            DataFound ?
                                                null :
                                                <Pagination count={Totalpages} page={CurrentPage} onChange={(event, page) => {dispatch({ type: 'SavePageNo', payload: { pageno: page } });}}/>
                                        }
                                    </div>
                                </>
                        }
                    </div >
                </div >
            }
            <ToastContainer/>
        </>
    )
}
export default Shop