import React, { useEffect, useState } from 'react'
import Hexapi from '../usefulcomps/HexAPI/Hexapi';
import SchemeDatatable from '../usefulcomps/table/SchemeDatatable';
import { useDispatch } from 'react-redux';

const SchemePanel = () => {
    const dispatch = useDispatch();
    const [isDataFound, setisDataFound] = useState(true)
    const [tabledata, settabledata] = useState([]);
    const [windowidth, setwindowwidth] = useState(window.innerWidth);


    useEffect(() => {
        dispatch({ type: 'SaveRoutes', payload: { route: "schemepanel" } });
        OpenAdminpanel();
        const handleWindowsize = () => {
            // console.log(window.innerWidth)
            setwindowwidth(window.innerWidth);
          }
      
          window.addEventListener('resize', handleWindowsize);
          return () => {
            window.removeEventListener('resize', handleWindowsize)
          }
    }, [])

    const getrowdata = () => {

    }

    const OpenAdminpanel = () => {
        setisDataFound(false)

        let obj = {
            'query': `[dbo].[Seetech_Web_Proc_Scheme_GetData] @UserID='{0}'`,
            'queryArr': [`${sessionStorage.getItem('userid')}`]
        }
        Hexapi(obj).then((res) => {
            console.log(res);
            setisDataFound(true)
            if (res[""]) {
                settabledata(res[""])
            }
        });
    }

    const callback = (value) => {
        console.log(value);
        settabledata([])
        setisDataFound(false)
        OpenAdminpanel();
    }


    return (
        <>
            <div style={{ width: '100%', height: '600px' }}>
                {

                    isDataFound ?
                        <SchemeDatatable
                            data={tabledata}
                            headStyle={{ textTransform: "Capitalize" }}
                            heading={"Scheme Panel"}
                            isNavbar={true}
                            pagination={{
                                rowsPerPage: 10,
                                rowsPerPageOptions: [10, 50, 100, { label: 'All', value: -1 }]
                            }}
                            onRowClick={(e) => getrowdata(e)}
                            callback={(e) => callback(e)}
                        />
                        :
                        null
                }
            </div>
        </>
    )
}

export default SchemePanel