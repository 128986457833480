import React, { useState } from 'react'
import { RxCross2 } from "react-icons/rx";

const Additionlisttypedata = ({listdata,itemdata,setdata}) => {
  const[ otherdata,setotherdata]=useState('')
  const[showInput,setshowInput]=useState(false)
  // console.log(listdata,"lisdata")
// console.log(itemdata,"itemdata")

 const handleotherdatalist=(e)=>{
        const updateddata=e.target.value
        // console.log(updateddata,"updateddata")
        setotherdata(updateddata)
        setdata(updateddata)
  }

  const callbackdata = (event) => {
    const value = event.target.value;
    // console.log()
    if (value === "other") {
      setshowInput(true)
    } else {
      setdata(value)
      setshowInput(false)
    }
}

const backtolist=()=>{
setotherdata("")
setdata("")
setshowInput(false)
  }
  return (
    <div>

                       {showInput ? <div className='d-flex align-items-center position-relative'> <input
                     type="text"

                     onChange={handleotherdatalist}
                     className="signupinput"
                      style={{ marginTop: '5px', height: '40px' }}
                     value={otherdata}
                     placeholder="Enter other "

                 /><span onClick={backtolist} className='btn-n'> <RxCross2 /></span></div> :  <select
                 className="signupinput"
                 onChange={callbackdata}
                 style={{ height: '45px' }}
                 value={itemdata}
             >
                 <option value="">---Select----</option>
                 {listdata.filter(s => s[""]!= null && s[""]!== "").map((s, index) => (
                     <option value={s[""]} key={index}>
                         {s[""]}
                     </option>
                 ))}
                 <option value="other">other</option>
             </select> }

    </div>
  )
}

export default Additionlisttypedata