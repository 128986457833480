// import Home from './Home';
import About2 from './Comp/About2';
import Cart from './Comp/Cart';
import Contact from './Comp/Contact';
import Shop from './Comp/Shop';
import Wishlist from './Comp/Wishlist';
import Loadable from 'react-loadable';
import Layout from './Layout';
import { Route, Routes, BrowserRouter } from 'react-router-dom';
import OrderPanel from './Comp/OrderPanel';
import OrderHistory from './Comp/OrderHistory';
import SchemePanel from './Comp/SchemePanel';
import ProductPanel from './Comp/ProductPanel';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-toastify/dist/ReactToastify.css';
import './Homescreen.css'
import SignUp from './Comp/SignUp';
import ApprovalPanel from './Comp/ApprovalPanel';
import { lazy, Suspense, useEffect, useState } from 'react';
import Hexapi from './usefulcomps/HexAPI/Hexapi';
import Products from './Comp/Products';
import TermsCond from './Comp/TermsCond';
import Privacypolicy from './Comp/Privacypolicy';
import Disclaimerpolicy from './Comp/Disclaimerpolicy';
import Warranty from './Comp/Warranty';
import { Provider } from 'react-redux';
import store from './Store';
import ErrorPage from './Comp/ErrorPage';
import { Helmet } from 'react-helmet';
import '../node_modules/bootstrap/dist/css/bootstrap.min.css'
import Home from './Comp/Home';
import Blogpreview from './Comp/Blogpreview';
import Blogmerge from './Comp/Blogmerge';
import { HelmetProvider } from 'react-helmet-async';

// sessionStorage.setItem('userid','1')
sessionStorage.setItem('Apipathurl', 'https://seetechparts.com/')


function App() {
  const [Userorigin, setUserorigin] = useState('');
  const [usertype, setusertype] = useState('');
  const [currency, setcurrency] = useState('');
  useEffect(() => {
    if (sessionStorage.getItem('Apipathurl') === undefined || sessionStorage.getItem('Apipathurl') == null || sessionStorage.getItem('Apipathurl') === "") {
      window.location.reload();
    }
    if (sessionStorage.getItem('userid') !== undefined && sessionStorage.getItem('userid') != null && sessionStorage.getItem('userid') !== "") {
      let obj1 = {
        'query': `[dbo].[Seetech_Web_Proc_User_Details]@UserID='{0}'`,
        'queryArr': [`${sessionStorage.getItem('userid')}`]
      }
      Hexapi(obj1).then((res) => {
        console.log(res[''][0])
        if (res[''][0]) {
          // dispatch({ type: 'CartItems', payload: { CartItems: res[''][0]?.totalcartitem } });
          setUserorigin(res[""][0].origin);
          setcurrency(res[''][0].currency)
          setusertype(res[""][0].usertype)
        }
      });

    }
  }, [])

  const Home = Loadable({
    loader: () => import('./Comp/Home'),
    loading: () => <div>Loading...</div>,
  });

  const About = Loadable({
    loader: () => import('./Comp/About2'),
    loading: () => <div>Loading...</div>,
  });
  const Blogmerge = Loadable({
    loader: () => import('./Comp/Blogmerge'),
    loading: () => <div>Loading...</div>,
  });

  const Shop = Loadable({
    loader: () => import('./Comp/Shop'),
    loading: () => <div>Loading...</div>,
  });
  const Products = Loadable({
    loader: () => import('./Comp/Products'),
    loading: () => <div>Loading...</div>,
  });
  const ProductPanel = Loadable({
    loader: () => import('./Comp/ProductPanel'),
    loading: () => <div>Loading...</div>,
  });
  const Contact = Loadable({
    loader: () => import('./Comp/Contact'),
    loading: () => <div>Loading...</div>,
  });
  const Wishlist = Loadable({
    loader: () => import('./Comp/Wishlist'),
    loading: () => <div>Loading...</div>,
  });
  const Cart = Loadable({
    loader: () => import('./Comp/Cart'),
    loading: () => <div>Loading...</div>,
  });
  const TermsCond = Loadable({
    loader: () => import('./Comp/TermsCond'),
    loading: () => <div>Loading...</div>,
  });
  const SignUp = Loadable({
    loader: () => import('./Comp/SignUp'),
    loading: () => <div>Loading...</div>,
  });
  const OrderHistory = Loadable({
    loader: () => import('./Comp/OrderHistory'),
    loading: () => <div>Loading...</div>,
  });
  const OrderPanel = Loadable({
    loader: () => import('./Comp/OrderPanel'),
    loading: () => <div>Loading...</div>,
  });
  const ApprovalPanel = Loadable({
    loader: () => import('./Comp/ApprovalPanel'),
    loading: () => <div>Loading...</div>,
  });
  const ErrorPage = Loadable({
    loader: () => import('./Comp/ErrorPage'),
    loading: () => <div>Loading...</div>,
  });
  const Warranty = Loadable({
    loader: () => import('./Comp/Warranty'),
    loading: () => <div>Loading...</div>,
  });
  const Disclaimerpolicy = Loadable({
    loader: () => import('./Comp/Disclaimerpolicy'),
    loading: () => <div>Loading...</div>,
  });
  const Privacypolicy = Loadable({
    loader: () => import('./Comp/Privacypolicy'),
    loading: () => <div>Loading...</div>,
  });
  const SchemePanel = Loadable({
    loader: () => import('./Comp/SchemePanel'),
    loading: () => <div>Loading...</div>,
  });
  return (
    <>
      <Provider store={store}>
        <HelmetProvider>
        <BrowserRouter >
          <Routes>
            <Route element={<Layout />}>
              <Route path="/" element={<Home/>}/>
              <Route path="/about" element={<About/>}/>
              <Route path="/Blog" element={<Blogmerge />}></Route>
              <Route path="/Blog/:BlogID/:Title" element={<Blogpreview />}></Route>
              <Route path="/Shop/" element={<Shop />}></Route>
              {/* <Route path="/Shop/:FilterValue" element={<Shop />}></Route> */}
              <Route path="/Shop/:ProductId/" element={<Shop />}></Route>
              <Route path="/Product/:RecID/:suitableforpartreference/" element={<Products />}></Route>
              <Route path="/Cart" element={<Cart UOrigin={Userorigin} Utype={usertype} currency={currency} />}></Route>
              <Route path="/Wishlist" element={<Wishlist UOrigin={Userorigin} Utype={usertype} currency={currency} />}></Route>
              <Route path="/contact" element={<Contact />}></Route>
              <Route path="/Productpanel" element={<ProductPanel />}></Route>
              <Route path="/approvalpanel" element={<ApprovalPanel />}></Route>
              <Route path="/orderpanel" element={<OrderPanel />}></Route>
              <Route path="/orderhistory" element={<OrderHistory />}></Route>
              <Route path="/schemepanel" element={<SchemePanel />}></Route>
              <Route path="/login" element={<SignUp />}></Route>
              <Route path="/termsandconditions" element={<TermsCond />}></Route>
              <Route path="/privacypolicy" element={<Privacypolicy />}></Route>
              <Route path="/disclaimerpolicy" element={<Disclaimerpolicy />}></Route>
              <Route path="/warranty" element={<Warranty />}></Route>
              <Route path="*" element={<ErrorPage />}></Route>
            </Route>
          </Routes>
        </BrowserRouter>
        </HelmetProvider>
      </Provider>
    </>
  );
}

export default App;




