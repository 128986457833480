import React, { useState, useEffect } from 'react'
import { Modal, Spinner } from 'react-bootstrap';
import Countrydata from './Countrydata';
import Swal from 'sweetalert2';
import Hexapi from '../usefulcomps/HexAPI/Hexapi';
import validator from 'validator'


const UserProfile = (props) => {

    const [firstname, setfirstname] = useState('');
    const [lastname, setlastname] = useState('');
    const [email, setemail] = useState('');
    const [phoneno, setphoneno] = useState('');
    const [Password, setpassword] = useState('');
    const [countrycode, setcountrycode] = useState('');
    const [companyname, setcompanyname] = useState('');
    const [Username, setUsername] = useState('');
    const [showPassword, setshowPassword] = useState(false);


    const setphonenumber = (e) => {
        const newValue = e.target.value;

        if (newValue.length <= 10) {
            setphoneno(newValue);
        }
    }

    useEffect(() => {
        document.title = 'JCB Spare Parts - Earth Moving Equipment Manufacturer | Seetech Parts, Faridabad'
        let obj1 = {
            'query': `[dbo].[Seetech_Web_Proc_User_Details]@UserID='{0}'`,
            'queryArr': [`${sessionStorage.getItem('userid')}`]
        }
        Hexapi(obj1).then((res) => {
            // console.warn(res);
            setfirstname(res[""][0].firstname)
            setlastname(res[""][0].lastname)
            setphoneno(res[""][0].contactnumber)
            setcountrycode(res[""][0].countrycode)
            setpassword(res[""][0].password)
            setcompanyname(res[""][0].companyname)
            setUsername(res[""][0].username)
            setemail(res[""][0].emailaddress)
        });
    }, [])


    const SubmitEditform = () => {
        if (validator.isEmail(email)) {
            if (phoneno.length == 10) {
                if (countrycode != '') {
                    if (Password != "") {
                        if (Username != "") {
                            if (firstname != "") {
                                if (lastname != -1) {

                                    Swal.fire({
                                        text: " Are you want to Edit Details",
                                        icon: 'warning',
                                        showCancelButton: true,
                                        confirmButtonColor: '#3085d6',
                                        cancelButtonColor: '#d33',
                                        confirmButtonText: 'Yes',
                                        customClass: {
                                            container: 'custom-swal-container', // Add a custom class to the container
                                        },
                                    }).then((result) => {
                                        if (result.isConfirmed) {
                                            let obj = {
                                                'query': `[dbo].[Seetech_Web_Proc_Edit_SignUpForm_Submit]
                                                       @Userid='{0}',@FirstName='{1}',@LastName='{2}',@ContactNumber='{3}',@EmailAddress='{4}',
                                                        @Username='{5}', @Password='{6}', @CountryCode='{7}',@CompanyName='{8}'`,
                                                'queryArr': [`${sessionStorage.getItem('userid')}`, `${firstname}`, `${lastname}`, `${phoneno}`, `${email}`,
                                                `${Username}`, `${Password}`, `${countrycode}`, `${companyname}`]
                                            }
                                            Hexapi(obj).then((res) => {
                                                console.log(res)
                                                // this.props.callback(true)
                                                Swal.fire({
                                                    position: 'center',
                                                    icon: 'success',
                                                    title: `Details Updated Successfully`,
                                                    showConfirmButton: false,
                                                    timer: 1500

                                                })
                                            });
                                        }
                                    });
                                }
                                else {
                                    Swal.fire({
                                        icon: 'error',
                                        title: 'Oops...',
                                        text: 'Please fill lastname!',
                                        customClass: {
                                            container: 'custom-swal-container', // Add a custom class to the container
                                        },
                                    })
                                }
                            }
                            else {
                                Swal.fire({
                                    icon: 'error',
                                    title: 'Oops...',
                                    text: 'Please fill firstname!',
                                    customClass: {
                                        container: 'custom-swal-container', // Add a custom class to the container
                                    },
                                })
                            }
                        }
                        else {
                            Swal.fire({
                                icon: 'error',
                                title: 'Oops...',
                                text: 'Please fill Username!',
                                customClass: {
                                    container: 'custom-swal-container', // Add a custom class to the container
                                },
                            })

                        }
                    } else {
                        Swal.fire({
                            icon: 'error',
                            title: 'Oops...',
                            text: 'Please fill Password!',
                        })
                    }
                }
                else {
                    Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Please select country code!',
                    })
                }
            }
            else {
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'Mobile number is invalid!',
                })
            }
        }
        else {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Email ID is invalid!',
            })
        }
    }

    return (
        <>
            {/* <Modal id="documentmodal" centered animation={true} fullscreen={true} show={props.showProfile} onHide={() => props.callback()} style={{ backgroundColor: 'rgba(0, 0, 0, 0.9)' }}>
                <Modal.Header closeButton>
                    <Modal.Title>User Profile</Modal.Title>
                </Modal.Header>
                <Modal.Body> */}
            <div class="row" style={{ width: '95%', margin: 'auto', marginBottom: '50px' }}>
                <div style={{ width: '100%', marginTop: '20px' }}>
                    <h3 style={{ borderBottom: '2px solid #f9b101' }}>User Profile</h3>
                </div>
                <div className='Fieldstyle' style={{ marginTop: '20px' }} >
                    <div style={{ width: '100%' }}>
                        <label ><b>Name</b><span style={{ color: 'red' }}> *</span></label>
                        <input className="signupinput" style={{ marginTop: '5px', height: '40px' }} value={firstname}
                            onChange={(e) => { setfirstname(e.target.value) }}></input>
                    </div>
                    <div style={{ width: '100%' }}>
                        <label ><b>Last Name</b><span style={{ color: 'red' }}> *</span></label>
                        <input className="signupinput" style={{ marginTop: '5px', height: '40px' }} value={lastname}
                            onChange={(e) => { setlastname(e.target.value) }}></input>
                    </div>
                </div>
                <div className='Fieldstyle' >
                    <div style={{ width: '100%' }}>
                        <label ><b>Mobile Number</b><span style={{ color: 'red' }}> *</span></label>
                        <input className="signupinput" style={{ marginTop: '5px', height: '40px' }} value={phoneno}
                            onChange={(e) => setphonenumber(e)} ></input>
                    </div>

                    <div style={{ width: '100%' }}>
                        <label ><b>Email Id</b><span style={{ color: 'red' }}> *</span></label>
                        <input className="signupinput" style={{ marginTop: '5px', height: '40px' }} value={email}
                            onChange={(e) => { setemail(e.target.value) }}></input>
                    </div>
                </div>
                <div className='Fieldstyle' >
                    <div style={{ width: '100%' }}>
                        <label ><b>Company Name</b><span style={{ color: 'red' }}> *</span></label>
                        <input className="signupinput" style={{ marginTop: '5px', height: '40px' }} value={companyname}
                            onChange={(e) => { setcompanyname(e.target.value) }}></input>
                    </div>

                    <div style={{ width: '100%' }}>
                        <div>
                            <label ><b>Country Code</b><span style={{ color: 'red' }}> *</span></label>
                        </div>
                        <select className="signupinput" onChange={(e) => { setcountrycode(e.target.value) }}
                            style={{ height: '45px' }} value={countrycode} >
                            <option value="">---Select----</option>
                            {Countrydata.map((s, index) => {
                                return (
                                    <option value={s.dial_code}>
                                        {s.name}:{s.dial_code}
                                    </option>
                                );
                            })}
                        </select>
                        {/* <input className="signupinput" style={{ marginTop: '5px', height: '40px' }} value={this.state.countrycode} onChange={(e) => { this.setState({ countrycode: e.target.value }) }}></input> */}
                    </div>
                </div>

                <div className='Fieldstyle' >
                    <div style={{ width: '100%' }}>
                        <label ><b>Username</b><span style={{ color: 'red' }}> *</span></label>
                        <input className="signupinput" style={{ marginTop: '5px', height: '40px' }} value={Username}
                            onChange={(e) => { setUsername(e.target.value) }}></input>
                    </div>
                    <div style={{ width: '100%' }}>
                        <label ><b>Password</b><span style={{ color: 'red' }}> *</span></label>
                        <div style={{ display: 'flex', position: 'relative' }}>
                            <input className="signupinput" type={showPassword ? "text" : "password"} style={{ marginTop: '5px', height: '40px' }} value={Password}
                                onChange={(e) => { setpassword(e.target.value) }}></input>
                            <span style={{ position: 'absolute', right: '3%', marginTop: '10px' }} onClick={() => { setshowPassword(!showPassword) }}>
                                {
                                    showPassword ?
                                        <i class="fa fa-eye"></i>
                                        :
                                        <i class="fa fa-eye-slash"></i>
                                }
                            </span>
                        </div>
                    </div>
                </div>

                <div class="row" id="Submitbtn" style={{ marginTop: '20px' }}>
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                        <button style={{ color: 'white', margin: '8px 0px', padding: '10px 30px', backgroundColor: 'black', borderRadius: '10px' }} onClick={() => { SubmitEditform() }}>Submit</button>
                    </div>
                </div>
            </div>

            {/* </Modal.Body>
            </Modal> */}
        </>
    )
}

export default UserProfile