import React, { useEffect, useState } from 'react';
import './blogpreview.scss';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Hexapi from '../usefulcomps/HexAPI/Hexapi';
import { useNavigate, useParams } from 'react-router-dom';
import { Modal, Spinner } from 'react-bootstrap';

const Blogpreview = () => {
    const { BlogID } = useParams();
    const navigate = useNavigate();
    const [singledata, setsingledata] = useState([]);
    const [showLoading, setshowLoading] = useState(true);
    const fetchblogsingledata = async () => {
        const obj={
            'query':`[dbo].[Seetech_Web_Proc_Blog_GetData]
            @BlogID ='{0}'`,
            'queryArr':[`${BlogID}`]
         }

        try {
            const singleblog = await Hexapi(obj);
            setshowLoading(false)
            setsingledata(singleblog[""]);
            document.title = singleblog[""][0].title;
        } catch (error) {
            console.log(error, "error");
            setshowLoading(false);
        }
    };

    useEffect(() => {
        fetchblogsingledata();
    }, [BlogID]);

    const handleBackClick = () => {
        navigate("/blog");
    };

    return (
        <>
            {showLoading ?
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: '10%', color: 'rgb(235 197 137)', minHeight: '400px' }}>
              <h3>
                <Spinner animation='border' style={{ color: '#f9b101' }} />
              </h3>
            </div> : (
                <div className="blogpreview-root">
                    <div className="container">
                        <button className='blogpreview-back-btn  align-items-center' onClick={handleBackClick}>
                            <ArrowBackIcon style={{ cursor: 'pointer' }} /> Back
                        </button>

                        <div className='blogpreview-card col-lg-12'>
                            <div className="blogpreview-body">
                                <h5>{singledata[0]?.title}</h5>
                                <div className="blogpreview-img-div">
                                    <img src={`${sessionStorage.getItem('Apipathurl')}${singledata[0]?.imagepath}`} alt="" />
                                </div>
                                <div  className=' setli' dangerouslySetInnerHTML={{ __html: singledata[0]?.body }} />
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default Blogpreview;
